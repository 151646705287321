import React, { useEffect, useState } from 'react';
import localImages from '../Constant/localImagesNew';
import '../Assets/Css/home.css';
import Faq from '../Component/faq';
import Footer from '../Component/footer';
import { useNavigate } from 'react-router-dom';
import HeaderHome from '../Component/headerHome';
import Sidebar from '../Module/sidebar';
import { useSelector } from 'react-redux';
import { SidebarDataFreeUser, SidebarDataPaidUser } from '../Module/sidebarData';
import BookSession from '../Module/bookSession';
import StudentSayCarousel from '../Component/studentSayCarousel';
import AcademicSubjectCard from '../Module/academicSubjectCard';
import TextLeftImageWithStrip from '../Module/textLeftImageWithStrip';
import TextRightImageWithStrip from '../Module/textRightImageWithStrip';
import BannerImage from '../Module/bannerImage';
import dataConstant from '../Constant/dataConstant';
import DropdownNew from '../Component/dropdownNew';
import SubscriptionPlan from '../Module/subscriptionPlan';
import { getPackageList } from '../Redux/Actions/examActions';
import { useAppDispatch } from '../hooks';
import { getCountry, getCourseTypelist, getSubjectList, gradeLevelList } from '../Redux/Actions/AuthAction';


export default function AdvanceAcademics() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const homebannerdiv = React.useRef<HTMLDivElement>(null);

    const [gradeList, setGradeList] = useState([]);
const [packageData, setpackageData] = useState("");
  const [grade, setGrade] = useState<any>("");
  const [course, setCourse] = useState<any>("");
  const [subjectList, setSubjectList] = useState([]);
  const [subject, setSubject] = useState("");

      const {ACCESS_TOKEN,hasActivePaidPlan,userCountryCallingCode} = useSelector((state:any) => ({
        ACCESS_TOKEN: state?.AuthReducer?.ACCESS_TOKEN,
        hasActivePaidPlan: state?.AuthReducer?.hasActivePaidPlan,
        userCountryCallingCode:state?.AuthReducer?.userCountryCallingCode
      }));
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(gradeLevelList(
            (data: any) => {
              setGradeList(data)
              setGrade(data[0]?._id)
            },
            () => { })
          );
    }, []);
    useEffect(() => {
        dispatch(getCountry(
            (data: any) => {
                const filterCountry=data?.filter((item:any)=>item?.phonecode==userCountryCallingCode?.substring(1)) 
                const postData = {
                    countryId: filterCountry[0]?._id,
                  };
    
                  dispatch(getCourseTypelist(
                    postData,
                    (res: any) => {                       
                      const filterCourse=res?.data?.filter((item:any)=>item?.name==="ADVANCED ACADEMICS");
                      setCourse(filterCourse[0]?._id)
                       
                    }, 
                    () => { }) )          
            },
            () => { })
        );
        
    }, [userCountryCallingCode]);
    const contactUS = (val:string) => {
        const element = document.getElementById(val);
        if (element) { 
          element.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        }
      };

    const subjectData=[
        {
            imgPath:localImages?.science,
            title:"Science",
            desc:"Dive into the fascinating world of Science with Lurnigo. Our comprehensive approach blends engaging content, hands-on exploration, and personalized guidance to cultivate a deep understanding of scientific principles. From biology to physics, embark on a journey of discovery and mastery."
        },
        {
            imgPath:localImages?.maths,
            title:"Maths",
            desc:"Unlock the power of numbers with Lurnigo. Tailored for success, our academics in Math combines conceptual clarity, real-world application, and individualized support to elevate mathematical proficiency. Whether tackling algebra, geometry, or calculus, build a solid foundation for mathematical excellence."
        },
        {
            imgPath:localImages?.english,
            title:"English",
            desc:"Master the art of language and literature with Lurnigo. Our academics in English focuses on honing reading, writing, and communication skills. Through literary exploration, grammar refinement, and personalized guidance, embark on a transformative journey towards linguistic proficiency and academic success."
        },
        {
            imgPath:localImages?.coding,
            title:"Coding",
            desc:"Elevate your tech skills with Lurnigo's coding program. Enhance problem-solving, programming, and analytical abilities through practical exercises and personalized mentorship. Start your journey to coding mastery and professional growth in the world of software development and computer science."
        }
    ]
    const featData=[
        {
            imgPath:localImages?.classwork,
            title:"Classwork Help",
            desc:"Navigate the complexities of class assignments with ease using Lurnigo's Classwork Help feature. Our dedicated team of educators is ready to assist you, ensuring that you not only understand the material but also excel in your classwork."
        },
        {
            imgPath:localImages?.advLearn,
            title:"Advanced Learning",
            desc:"Elevate your learning experience with Lurnigo's Advanced Learning feature. Dive deeper into subjects with challenging yet engaging content designed to stimulate critical thinking and foster a love for learning."
        },
        {
            imgPath:localImages?.awardWinning,
            title:"Award Winning Curriculum",
            desc:"Experience education at its finest with Lurnigo's Award-Winning Curriculum. Our carefully crafted curriculum, recognized for its innovation and effectiveness, ensures that you receive a world-class education."
        }
    ]
    const trainingFeatData=[
        {
            imgPath:localImages?.HandpickedTeachers,
            title:"Handpicked Teachers",
            desc:"At Lurnigo, we understand the power of a great teacher. That's why we take pride in our Handpicked Teachers feature. Our educators aren't just experts in their fields; they're passionate about teaching and dedicated to your success. Experience the difference of learning from the best as our handpicked teachers guide you through your academic journey with expertise, enthusiasm, and a personalized touch."
        },
        {
            imgPath:localImages?.PersonalisedLearning,
            title:"Personalised Learning",
            desc:"Tailored to fit your unique learning style, Lurnigo's Personalized Learning feature empowers you to take control of your education. Whether you're an auditory learner, a visual learner, or somewhere in between, our platform adapts to your preferences. With personalized learning paths, you can maximize your understanding, retention, and overall academic performance, ensuring that education feels truly made for you."
        },
        {
            imgPath:localImages?.oneto1LIVETutoring,
            title:"1-to-1 LIVE Tutoring",
            desc:"Experience the pinnacle of academic support with Lurnigo's 1-to-1 Live Tutoring. Connect with a dedicated tutor in real-time for personalized guidance and immediate answers to your questions. Whether you're tackling a challenging concept, preparing for exams, or seeking additional clarification, our live tutoring sessions provide the individualized attention you need to excel. Elevate your learning with the power of one-on-one interaction."
        }
    ]
    function goToLink(val:string): void {
        if(val==="BookSession"){
            navigate('/'+val,{ state: { mobNo: "",coutryCodeNo:"",isOTPVerify:ACCESS_TOKEN?true:false } });  
        }
        else{
            navigate('/'+val);
        }
      }

      const getGrade=(val:any)=>{
        setGrade(val)
        setSubjectList([])
      }
      const onChangeSubject=(val:any)=>{
        setpackageData("")
        setSubject(val)        
      }

      useEffect(() => {
        const postData:any={
          coursetypeId:course,
          subjectId:subject,
          gradeId:grade
      }
      
      dispatch(
        getPackageList(
          postData,
          (res: any) => {
            if(res?.data.length>0){
            setpackageData(res?.data[0]);
            }
          },
          () => {}
        )
      ) 
      }, [subject]);

      useEffect(() => {
        const postData:any={
          coursetypeId:course,
          gradeId:grade
      }
      
        dispatch(getSubjectList(
          postData,
            (res: any) => {
              if(res?.data?.length>0){
                setSubjectList(res?.data)
               setSubject(res?.data[0]?._id)
              }
            },
            () => { })
        )  
      }, [grade,course]);
    return (
        <div>
            <HeaderHome isHidden={true} />
            <div className="d-block d-md-none">
            <Sidebar data={hasActivePaidPlan===true?SidebarDataPaidUser:SidebarDataFreeUser}/> 
            </div>
            {/*************Banner************* */}
            <div className='position-relative homeBannerContainer' id='homebanner' ref={homebannerdiv}>
                <div className='row mx-0 '>
                    <div className="col-md-6 homeBannerLeftSide">
                    <div className="homeBannerLeftSide">
                    <div className='bannersubtext mb-3 colorblue'>K8 - K10 - K12</div>
                        <h1 className='homeBannerHeading'>We take care of your </h1>
                        <h1 className='homeBannerHeading'>academics needs</h1>
                        
                        <div className='bannersubtext mt-3'>We Believe in Fostering Growth and Building Trust</div>
                        <div className='mt-5'>
                        <button className='signBtn px-4 font16' onClick={()=>goToLink('BookSession')}>Join Lurnigo Today</button>
                        </div>
                        <div className='bannersubtext mt-3'><img src={localImages?.studentEnroll} alt="studentEnroll" className='me-2'/>10,000+ Students Enrolled</div>
                    </div>
                    </div>
                    <div className="col-md-6 position-relative pe-md-0">
                        <BannerImage src={localImages.academicBanner}/>
                    </div>
                </div>
            </div>
            <div className='commonPad pb-0'>
                <div className='row learnSolTab flex-wrap'>
                    {
                        subjectData?.map((item, index) => (
                            <div className='col-12 col-sm-6 col-md-4 col-lg-3 mb-3' key={index}>
                            <AcademicSubjectCard data={item} index={index}/>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div>
                {trainingFeatData?.map((item,index)=>(
                    index % 2 === 0 ?
                        <TextLeftImageWithStrip data={item} index={index}/>
                        :<TextRightImageWithStrip data={item} index={index}/>
                    
                ))}
            </div>

            <div className="mt-4 p-4 p-md-5 shadow-sm ">
                <h2 className="fw-bold my-2 text-center">Our pricing & subscription plans</h2>
                <div className="text-center mx-auto subscriptionSubHeading mt-3">Elevate your learning experience with Lurnigo's Advanced subjects with challenging engaging content designed to stimulate critical thinking and foster a love for learning.</div>

                <div className="d-flex row justify-content-center mt-4">
                 <div className="col-md-3 mb-2 mb-md-0">
                        <DropdownNew
                            placeholder="Select Grade"
                            options={gradeList}
                            value={grade}
                            setInputText={(value: any) => getGrade(value)}
                        />
                    </div> 
                    <div className="col-md-3 mb-2 mb-md-0">
                        <DropdownNew
                            placeholder="Select Subject"
                            options={subjectList}
                            value={subject}
                            setInputText={(value: any) => onChangeSubject(value)}
                        />
                    </div>

                </div>
                <div className="featureDiv border rounded p-4 mt-5">
                    <SubscriptionPlan priceData={packageData} />
                </div>
            </div>

            <div className='commonPad py-0'>
                <div className='row learnSolTab flex-wrap'>
                    {
                        featData?.map((item, index) => (
                            <div className='col-12 col-sm-6 col-md-4 col-lg-4 mb-3' key={index}>
                            <AcademicSubjectCard data={item} index={index}/>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className='commonPad bgLightGrey'>
                <div className='width800 mx-auto my-5'>
                    <h1 className='headingFont text-center'>Don't just take our word for it</h1>
                    <div className='bannersubtext mb-3 text-center'>Hear what our students have to say</div>
                </div>
                <div>
                    <StudentSayCarousel Data={dataConstant?.studentSayData} id='carouselStudent' />
                </div>
                <div>
                </div>

            </div>
           
            <div className='commonPad '>
                <div className='row mx-0'>
                    <div className='col-md-3'>
                        <h1 className='headingFont mb-4'>FAQs</h1>
                        <div className='mb-4'>
                            <button className='contactUSbtn px-5' onClick={()=>contactUS('Footer')}>Contact Us</button>
                        </div>
                    </div>
                    <div className='col-md-9'>
                        <Faq faqData={dataConstant?.faqData} id={"HomeFAQ"} />
                    </div>
                </div>
            </div>

            <div className='commonPad '>
                <BookSession  clickEvent={()=>goToLink('BookSession')}/>
            </div>
                    {/***************Footer*************** */}
                    <Footer id='Footer'/>
            </div>
            )
}
