import { useState } from 'react';
import deleteimg from '../Assets/Images/courses/Delete.png';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-bootstrap';
import { useDispatch } from 'react-redux'; 
import downarrow from '../Assets/Images/courses/downarrow.png'; 
import { getSessionData } from '../Redux/Actions/examActions';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useSelector } from 'react-redux';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
function CartCard(props:any) {
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [show, setShow] = useState(false);

  const {userCountryCurrency} = useSelector((state:any) => ({
    userCountryCurrency: state?.AuthReducer?.userCountryCurrency,
  }));
    
  const handleBatchTiming = (id:any) => {
    props?.setPackageID(id)
    dispatch(
      getSessionData(
        id,
        (res: any) => {
          if(res && res?.length!=0){
            props.setsessionData(res);
            props?.setupdatesession(true)
            props.setopenSession(true)
          }
        },
        () => { }
      )
    );
  };
  function gotoCourseDetail(id:any,packageOptionId:any){
    navigate('/CourseDetails/'+id+"/"+packageOptionId);
  }
  return (     
      <div className="border shadow-sm font12 p-3 bgwhite px-0 px-sm-4 rounded mb-3 bgwhite" key={props?.data?._id}>
      <div className='row'>
        <div className='col-sm-4 d-flex align-items-center'>
          <img className="w-100" src={props?.data?.bannerImage} alt="Package Banner" />
        </div>
        <div className='col-sm-8 py-2 py-sm-0'>
          <div className="d-flex justify-content-between mb-2">
            <span className="fw-bold font16">{props?.data?.packageId?.name}</span>

            <span className="colorblue d-flex">
              <div
                className="justify-content-between "
              >
                <a href={props?.data?.packageId?.packagePDF} target="_blank" rel="noreferrer">
                  <PictureAsPdfIcon className='colorblue' />
                </a>
              </div>
            </span>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <span className="fw-bold font16">{props?.data?.levelId?.name}</span>
          </div>

          <div className="mb-1">
            <span className='fw-bold'>Lecture :</span>
            <span className="ms-2 colorgrey fw-bold">{props?.data?.levelId?.offerLectureCount}</span>
          </div>

          <div className="mb-1 d-flex">
            <span>Course Price :</span>
            <span className="ms-2">
              <span className="fw-bold">
                <div><span className="colorblue">{getSymbolFromCurrency(userCountryCurrency)}{parseFloat(props?.data?.packageOptionId?.offerPrice).toFixed(2)}</span>
                  <span> <s className='ms-1'>{getSymbolFromCurrency(userCountryCurrency)}{props?.data?.packageOptionId.sellingPrice}</s></span></div>
              </span>
            </span>
          </div>

          {props?.data?.sessionId?
         <div className="mb-2">
           <span>Batch Time :</span>
           <span className="ms-2">
          {props?.data?.sessionId?.name} | {props?.data?.sessionId?.time}
             <img
               className="ms-2 cursor"
               src={downarrow}
               alt="Dropdown"
               style={{ width: '10px' }}
               onClick={()=>handleBatchTiming(props?.data?.packageId?._id)}
             />
           </span>
         </div>:null}

          <div className="d-flex justify-content-between mt-2">
         <button
           className="btn btn-secondary px-4 cursor"
           style={{ fontSize: '12px' }} onClick={()=>gotoCourseDetail(props?.data?.packageId?._id,props?.data?.packageOptionId?._id)}
            >
           View Details
         </button>
         <div
           className="redtxt px-3 rounded d-flex justify-content-center align-items-center font12 cursor"
            onClick={()=>props?.removeCart(props?.data?._id)}>
          <img src={deleteimg} alt="Delete" className="me-2 cursor" />
           <NavLink>Remove</NavLink>
         </div>
       </div>
        </div>
      </div>
      
       
    
       
       
      
     </div>
  );
}

export default CartCard;
