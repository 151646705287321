import React, { useEffect, useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LocalImages from '../Constant/localImages';
import { getQuestionDescription, getStatsFromQuestionData, secondsMinute, timeConvert } from '../Utils/utils';
import { Tabs, Tab } from 'react-bootstrap';
import ClearIcon from '@mui/icons-material/Clear';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { getQuestionType } from '../Utils/utils';
import ReactHtmlParser from 'react-html-parser';
import DoneIcon from "@mui/icons-material/Done";
function SectionReport(props: any) {
 
    const [key, setKey] = useState('one');
    const [updatedQuestionsData, setUpdatedQuestionsData] = useState(
      props?.questionsData.map(
        (item: { questionNumber: any }, index: number) =>
          (item.questionNumber = index + 1)
      )
      );
    
    let { correctAnswers, wrongAnswers, skippedAnswers } =
    getStatsFromQuestionData( props?.questionsData);
    
    const getAnswerDescription = (question:any) => {
 
      const ansDescription = question?.examAnsDesc;
      return (
        <div className="text-start">
          {ansDescription?.image === '' &&
          ansDescription?.text === '' &&
          ansDescription?.video === '' ? null : (
            <>
              {ansDescription?.image ? (
                <div>
                  <img
                    className="img-fluid qusimgdesc"
                    src={ansDescription?.image}
                    alt={'Anwer'}
                  />
                </div>
              ) : null}
              {ansDescription?.text ? ReactHtmlParser(ansDescription?.text) : null}
              {ansDescription?.video ? (
                <>
                  <video className="videotag m-auto" width="320" height="240" controls>
                    <source src={ansDescription?.video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </>
              ) : null}
            </>
          )}
        </div>
      );
    };

    // console.log("updatedQuestionsData",updatedQuestionsData)
    const DotListItem = ({ backgroundColor, text, value }) => (
        <li className="mb-3">
          <div className="d-flex">
            <span className="dot me-3" style={{ backgroundColor }}></span>
            <div className="text-holder">
              {text}
              <span>{value}</span>
            </div>
          </div>
        </li>
      );
      console.log("correctAnswers",correctAnswers)
  return (
     
 
    <div className="result-analysis px-2">
           {/* <div className="exam-name-holder ms-2">
          {props?.examData ?.examName}
          {props?.examData ?.createdAt && (
            <span className="d-block">{`Attempted on ${props?.examsStats?.examData?.createdAt.format('DD MMMM YYYY | HH:mm A')}`}</span>
          )}
        </div> */}
    <div className="detailsResult">
      <div className="mt-4 py-2 tabcontent">
        <Tabs
          id="tinyTab"
          className="tabcontent"
          activeKey={key}
          onSelect={(e:any) => setKey(e)}
          fill
          variant="pills">
          <Tab
            eventKey="one"
            title={`${
              correctAnswers?.length < 10
                ? `0${correctAnswers?.length}`
                : `${correctAnswers?.length}`
            } Correct Answer`}>
            {correctAnswers?.map((question:any, faqindex:any) => {
              return (
                <div key={question?._id} className="border rounded mb-2 p-2 mt-3">
                  <div className="row">
                    <div className="col-2 col-sm-1 px-1">
                      <span className="queno">
                        {question.questionNumber}
                        {faqindex.questionNumber}
                      </span>
                      <span className="questype d-block rounded text-center mt-2">
                        {getQuestionType(question?.questionType)}  
                      </span>
                    </div>
                    <div className="col-10 col-sm-11">
                     {getQuestionDescription(question)}  
                    </div>
                  </div>
                  {question?.questionType !== 3 ? (
                    <div className="row mt-2">
                      <div className="col-2 col-sm-1 colorblue font12 px-1">Options:</div>
                      <div className="col-10 col-sm-11">
                        <table>
                          {question?.options?.map((option:any, index:any) => {
                            return (
                              <tr key={option?._id}>
                                <td
                                  className={
                                    option.isCorrect ? 'colorgreen text-break' : ' text-break'
                                  }>
                                {option.isCorrect ? 
                                  <DoneIcon /> : null}  
                                </td>
                                <td
                                  className={
                                    option.isCorrect
                                      ? 'colorgreen text-break d-flex align-item-center'
                                      : ' text-break d-flex align-item-center'
                                  }>
                                  <span className="mx-2">{index + 1}.</span>{' '}
                                  {option?.descType === 'img' ? (
                                    <img
                                      className="qusimg"
                                      src={option?.description}
                                      alt="Description"
                                    />
                                  ) : (
                                     
                                      ReactHtmlParser(option.description)
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                      </div>
                    </div>
                  ) : (
                    <div className="row mt-2">
                      <div className="col-1 colorblue font12">Answer:</div>
                      <div className="col-11">
                        <table>
                          <tr>
                            <td className="colorgreen">
                                <DoneIcon />  
                            </td>
                            <td className="colorgreen">
                              {question?.answer?.length ? question?.answer[0] : 0}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  )}
                  <div className="row mt-2">
                    <div className="col-1 errorcolor font12 pe-0">Time Taken:</div>
                    <div className="col-11">
                       <span>
                        {question?.timeSpent ? secondsMinute(question.timeSpent) : 0}</span>  
                    </div>
                  </div>
                  <div className="mt-2">
                    {question?.answer?.text === '' &&
                    question?.answer?.image === '' &&
                    question?.answer?.video === '' ? null : (
                      <div className="accordion" id={'accordionExample' + question?._id}>
                        <div className="accordion-item" key={question?._id}>
                          {/* <h2 className="accordion-header"  id={'heading' + question?._id}>
                            <button
                            className={`accordion-button ${question?._id ? '' : 'collapsed colorgreen'}`}
                              // className="accordion-button collapsed colorgreen"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapseOne-${question?._id}`}
                              aria-expanded="false"
                              aria-controls="flush-collapseOne">
                              <span className="colorgreen">Answer Description</span>
                            </button>
                          </h2> */}
                             <h2 className="accordion-header" id={'heading' + faqindex}>
                          <button
                            className={`accordion-button ${faqindex === 0 ? '' : 'collapsed'}`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={'#collapse' + faqindex}
                            aria-expanded={faqindex=== 0 ? 'true' : 'false'}
                            aria-controls={'collapse' + faqindex}>
                          <span className="colorgreen">Answer Description</span>
                          </button>
                        </h2>

                          {/* <div
                            id={`flush-collapseOne-${question?._id}`}
                            // className="accordion-collapse collapse"
                            className={`accordion-collapse collapse ${question?._id? 'show' : ''}`}
                            // aria-labelledby="flush-headingOne"
                            aria-labelledby={'flush-headingOne' + question?._id}
                            data-bs-parent="#accordionCorrect1">
                            <div className="accordion-body">
                                {getAnswerDescription(question)}  
                              </div>
                          </div> */}
                              <div
                          id={'collapse' + faqindex}
                          className={`accordion-collapse collapse ${faqindex === 0 ? 'show' : ''}`}
                          aria-labelledby={'heading' + faqindex}
                          data-bs-parent={'#accordionExample' + faqindex}>
                          <div className="accordion-body editorTableDesign">
                          {getAnswerDescription(question)}  
                          </div>
                        </div>
                        </div>
                      </div>
                    )}
                  </div> 
                  <div className="my-3" key={question?._id}>
                    {/* {question.questionStatus ? (
                      <button type="button" className="btn submitted-btn">
                        Submitted
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn issue-report-btn"
                        onClick={() => getQuestionNumber(question)}
                        key={question?._id}>
                        Find issue in question? Report Here
                      </button>
                    )} */}
                  </div>
                </div>
              );
            })}
          </Tab>
           <Tab
            eventKey="two"
            title={`${
              wrongAnswers?.length < 10 ? `0${wrongAnswers?.length}` : `${wrongAnswers?.length}`
            } Wrong Answer`}>
            {wrongAnswers.map((question:any , faqindex:any) => {
              return (
                <div key={question?._id} className="border rounded mb-2 p-2 mt-3">
                  <div className="row">
                    <div className="col-2 col-sm-1 px-1">
                      <span className="queno">{question.questionNumber}</span>
                      <span className="questype d-block rounded text-center mt-2">
                        {getQuestionType(question?.questionType)}
                      </span>
                    </div>
                    <div className="col-10 col-sm-11">{getQuestionDescription(question)}</div>
                  </div>
                  {question?.questionType !== 3 ? (
                    <div className="row mt-2">
                      <div className="col-2 col-sm-1 colorblue font12 px-1">Options:</div>
                      <div className="col-10 col-sm-11">
                        <table>
                          {question?.options?.map((option:any, index:any) => {
                            return (
                              <tr key={option?._id}>
                                <td
                                  className={
                                    option.isCorrect
                                      ? 'colorgreen text-break align-item-center'
                                      : option.isAnswer
                                        ? 'errorcolor text-break align-item-center'
                                        : 'text-break align-item-center'
                                  }>
                                  {' '}
                                  {option.isCorrect ? (
                                    <DoneIcon />
                                  ) : option.isAnswer ? (
                                    <ClearIcon />
                                  ) : (
                                    ''
                                  )}
                                </td>
                                <td
                                  className={
                                    option.isCorrect
                                      ? 'colorgreen text-break d-flex align-item-center'
                                      : option.isAnswer
                                        ? 'errorcolor text-break d-flex align-item-center'
                                        : 'text-break d-flex align-item-center'
                                  }>
                                  <span className="mx-2">{index + 1}.</span>{' '}
                                  {option?.descType === 'img' ? (
                                    <img
                                      className="qusimg"
                                      src={option?.description}
                                      alt="Description"
                                    />
                                  ) : (
                                    ReactHtmlParser(option.description)
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="row mt-2">
                        <div className="col-1 colorblue font12">Answer:</div>
                        <div className="col-11">
                          <table>
                            <tr>
                              <td className="colorgreen">
                                <DoneIcon />
                              </td>
                              <td className="colorgreen">
                                {question.range.r1}
                                {question.range.r2 !== null ? `-${question.range.r2}` : null}
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      {question?.answer?.length ? (
                        <div className="row mt-2">
                          <div className="col-1 colorblue font12">Your Answer:</div>
                          <div className="col-11">
                            <table>
                              <tr>
                                <td className="errorcolor">
                                  <ClearIcon />
                                </td>
                                <td className="errorcolor">
                                  {question?.answer?.length ? question?.answer[0] : ''}
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      ) : null}
                    </>
                  )}
                  <div className="row mt-2">
                    <div className="col-1 errorcolor font12 pe-0">Time Taken:</div>
                    <div className="col-11">
                      <span>{question?.timeSpent ? secondsMinute(question.timeSpent) : 0}</span>
                    </div>
                  </div>
                  <div className="mt-2">
                    {question?.answer?.image === '' &&
                    question?.answer?.text === '' &&
                    question?.answer?.video === '' ? null : (
                      <div className="accordion" id="accordionwrong">
                        <div className="accordion-item">
                        <h2 className="accordion-header" id={'heading' + faqindex}>
                          <button
                            className={`accordion-button ${faqindex === 0 ? '' : 'collapsed'}`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={'#collapse' + faqindex}
                            aria-expanded={faqindex === 0 ? 'true' : 'false'}
                            aria-controls={'collapse' + faqindex}>
                          <span className="colorgreen">Answer Description</span>
                          </button>
                        </h2>
                        <div
                          id={'collapse' + faqindex}
                          className={`accordion-collapse collapse ${faqindex === 0 ? 'show' : ''}`}
                          aria-labelledby={'heading' + faqindex}
                          data-bs-parent={'#accordionExample' + faqindex}>
                          <div className="accordion-body editorTableDesign">
                          {getAnswerDescription(question)}  
                          </div>
                        </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* <div className="my-3" key={question?._id}>
                    {question?.questionStatus ? (
                      <button type="button" className="btn submitted-btn" key={question?._id}>
                        Submitted
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn issue-report-btn"
                        onClick={() => getQuestionNumber(question)}
                        key={question?._id}>
                        Find issue in question? Report Here
                      </button>
                    )}
                  </div> */}
                </div>
              );
            })}
          </Tab>
           <Tab
            eventKey="three"
            title={`${
              skippedAnswers?.length < 10
                ? `0${skippedAnswers?.length}`
                : `${skippedAnswers?.length}`
            } Skipped`}>
            {skippedAnswers.map((question:any , faqindex:any) => {
              return (
                <div className="border rounded mb-2 p-2 mt-3" key={question?._id}>
                  <div className="row">
                    <div className="col-2 col-sm-1 px-1">
                      <span className="queno">{question.questionNumber}</span>
                      <span className="questype d-block rounded text-center mt-2">
                        {getQuestionType(question?.questionType)}
                      </span>
                    </div>
                    <div className="col-10 col-sm-11">{getQuestionDescription(question)}</div>
                  </div>
                  {question?.questionType !== 3 ? (
                    <div className="row mt-2">
                      <div className="col-2 col-sm-1 colorblue font12 px-1">Options:</div>
                      <div className="col-10 col-sm-11">
                        <table>
                          {question?.options?.map((option:any, index:any) => {
                            return (
                              <tr key={option?._id}>
                                <td
                                  className={
                                    option?.isCorrect
                                      ? 'colorgreen text-break'
                                      : 'colorpurple  text-break'
                                  }>
                                  {option?.isCorrect ? <DoneIcon /> : null}
                                </td>
                                <td
                                  className={
                                    option?.isAnswer
                                      ? 'colorpurple text-break d-flex align-item-center'
                                      : option?.isCorrect
                                        ? 'colorgreen text-break d-flex align-item-center'
                                        : 'text-break d-flex align-item-center'
                                  }>
                                  <span className="mx-2">{index + 1}.</span>{' '}
                                  {option?.descType === 'img' ? (
                                    <img
                                      src={option?.description}
                                      className="qusimg"
                                      alt="description"
                                    />
                                  ) : (
                                    ReactHtmlParser(option?.description)
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="row mt-2">
                        <div className="col-1 colorblue font12">Answer:</div>
                        <div className="col-11">
                          <table>
                            <tr>
                              <td className="colorgreen">
                                <DoneIcon />
                              </td>
                              <td className="colorgreen">
                                {question.range.r1}
                                {question.range.r2 !== null ? `-${question.range.r2}` : null}
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      {question?.ansType === 3 && question?.answer?.length ? (
                        <div className="row mt-2">
                          <div className="col-1 colorblue font12">Your Answer:</div>
                          <div className="col-11">
                            <table>
                              <tr>
                                <td className="colorpurple"></td>
                                <td className="colorpurple">
                                  {question?.answer?.length ? question?.answer[0] : ''}
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      ) : null}
                    </>
                  )}
                  <div className="row mt-2">
                    <div className="col-1 errorcolor font12 pe-0">Time Taken:</div>
                    <div className="col-11">
                      <span>
                        {question?.timeSpent ? secondsMinute(question.timeSpent) : 'Not Visited'}
                      </span>
                    </div>
                  </div>
                  <div className="mt-2">
                    {question?.answer?.image === '' &&
                    question?.answer?.text === '' &&
                    question?.answer?.video === '' ? null : (
                      <div className="accordion" id="accordionSkipped">
                        <div className="accordion-item">
                        <h2 className="accordion-header" id={'heading' + faqindex}>
                          <button
                            className={`accordion-button ${faqindex === 0 ? '' : 'collapsed'}`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={'#collapse' + faqindex}
                            aria-expanded={faqindex=== 0 ? 'true' : 'false'}
                            aria-controls={'collapse' +faqindex}>
                          <span className="colorgreen">Answer Description</span>
                          </button>
                        </h2>
                        <div
                          id={'collapse' +faqindex}
                          className={`accordion-collapse collapse ${faqindex=== 0 ? 'show' : ''}`}
                          aria-labelledby={'heading' +faqindex}
                          data-bs-parent={'#accordionExample' + faqindex}>
                          <div className="accordion-body editorTableDesign">
                          {getAnswerDescription(question)}  
                          </div>
                        </div>
                        
                        </div>
                      </div>
                    )}
                  </div>
                  {/* <div className="my-3" key={question?._id}>
                    {question?.questionStatus ? (
                      <button type="button" className="btn submitted-btn" key={question?._id}>
                        Submitted
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn issue-report-btn"
                        onClick={() => getQuestionNumber(question)}
                        key={question?._id}>
                        Find issue in question? Report Here
                      </button>
                    )}
                  </div> */}
                </div>
              );
            })}
          </Tab>  
        </Tabs>
      </div>
    </div>
    {/* {isIssue && (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="model"
        open={isIssue}
        onClose={() => handleReportIssueModal()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}>
        <Fade in={isIssue}>
          <div className="issue-report-modal col-md-4">
            <div>
              <div className="col mb-2 text-end">
                <ClearOutlinedIcon
                  className="colorblue cursor"
                  onClick={() => {
                    setIsIssue(false);
                  }}
                />
              </div>
              <div>
                <div>
                  <form className="mb-3" onSubmit={submitIssue}>
                    <div className="col-sm-6 mb-4">
                      <select
                        className="form-select issue-select"
                        value={issueType}
                        onChange={(e) => setIssueType(e.target.value)}>
                        <option selected>Select Issue</option>
                        <option value="Question Image Wrong">Question Image Wrong</option>
                        <option value="Question Wrong">Question Wrong</option>
                        <option value="Option Wrong">Option Wrong</option>
                        <option value="Correct Answer Wrong">Correct Answer Wrong</option>
                      </select>
                    </div>
                    <div className="col-sm-10 mb-3">
                      <label className={`mb-4 ${issueType ? 'issue-label' : ''}`}>
                        Add Remark
                      </label>
                      <textarea
                        className="form-control"
                        aria-label="With textarea"
                        placeholder={issueType ? issueType : 'Add Remark'}
                        onChange={(e) => setRemark(e.target.value)}
                        value={remark}
                        maxLength={1000}></textarea>
                    </div>
                    <div className="">
                      <button
                        className="btn issueSubmitBtn"
                        type="submit"
                        disabled={!issueType || issueType === 'Select Issue'}>
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    )} */}
  </div>
  );
}

export default SectionReport;
