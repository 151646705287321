// Validation functions start
 
export const containsSpecialChars = (str:any) => {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return specialChars.test(str);
};

export function containsNumber(str:any) {
  return /\d/.test(str);
}
export function hasWhiteSpace(str:any) {
  return /\s/g.test(str);
}

export function startsWithSpace(str:any){
  return /^\s/.test(str)
}

export function startsWithNumber(str:any){
  return /^\d/.test(str)
}


export function validateFullName(str:any) {
  if (
    typeof str !== 'string' ||
    /[0-9]+/g.test(str) ||
    containsSpecialChars(str) ||
    startsWithSpace(str)
  ) {
    return false;
  }
  return true;
}

export const validateIsLettersAndDotOnly = (value:any) => {
  return /^[a-zA-Z\s,.]*$/.test(value);
  
  
};

export const validateIsNumberOnly = (value:any) => {
  return /\D/.test(value);
  
};

 
export const validateEmailAddress = (value:any) => {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
};

export function validateCouponCodeName(str:string) {
  if (
    startsWithNumber(str) ||
    startsWithSpace(str) ||
    hasWhiteSpace(str) ||
    containsSpecialChars(str)
  ) {
    return false;
  }
  return true;
}



// Validation functions ends
