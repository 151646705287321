import React, { useEffect, useState } from "react";
import localImages from "../Constant/localImagesNew";
import "../Assets/Css/home.css";
import "../Assets/Css/dsatLandingPage.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css";
import Faq from "../Component/faq";
import Footer from "../Component/footer";
import { useNavigate } from "react-router-dom";
import HeaderHome from "../Component/headerHome";
import Sidebar from "../Module/sidebar";
import { useSelector } from "react-redux";
import {
  SidebarDataFreeUser,
  SidebarDataPaidUser,
} from "../Module/sidebarData";
import BookSessionPopup from "../Component/bookSessionPopup";
import BookSession from "../Module/bookSession";
import StudentSayCarousel from "../Component/studentSayCarousel";
import dataConstant from "../Constant/dataConstant";
import {
  addDSATlandingpage,
  getCountry,
  gradeLevelList,
  register,
} from "../Redux/Actions/AuthAction";
import { useAppDispatch } from "../hooks";
import CertificateSection from "../Module/certificateSection";
import InputField from "../Atom/InputField";
import PhoneInputField from "../Atom/phoneInputField";
import { validateFullName, validateIsNumberOnly } from "../Utils/validators";
import { showErrorSnackbar } from "../Redux/Actions/snackbarAction";
import {
  validateFullNameErrMsg,
  validateIsNumberOnlyErrMsg,
  validateLastNameErrMsg,
} from "../Utils/errormsg";
import BookDemoSessionPopup from "../Component/bookDemoSessionPopUp";
import Dropdown from "../Component/dropdown";
import DsatPopup from "../Component/dsatPopup";
import HeaderDsat from "../Component/headerDsat";
import CloseIcon from "@mui/icons-material/Close";
export default function DigitalSAT() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const homebannerdiv = React.useRef<HTMLDivElement>(null);
  const [isHidden, setisHidden] = useState<boolean>(true);
  const [openBookSeat, setopenBookSeat] = useState<boolean>(false);
  const [firstName, setfirstname] = useState<string>("");
  const [lastName, setlastName] = useState<string>("");
  const [email, setemail] = useState<string>("");
  const [mob, setmob] = useState<string>("");
  const [countryData, setCountryData] = useState([]);
  const [coutryCode, setcoutryCode] = useState<string>("1");
  const [openDemoSession, setOpenDemoSession] = useState<boolean>(false);
  const [gradeList, setGradeList] = useState([]);
  const [openDsat, setopenDsat] = useState<boolean>(false);
  const [closePopup, setClosePopup] = useState<boolean>(false);

  const [grade, setGrade] = useState<any>("");
  const closesBooseat = () => {
    setopenBookSeat(false);
  };
  const closesDemosession = (e: any) => {
    e.preventDefault();
    setOpenDemoSession(false);
  };

  const { ACCESS_TOKEN, hasActivePaidPlan } = useSelector((state: any) => ({
    ACCESS_TOKEN: state?.AuthReducer?.ACCESS_TOKEN,
    hasActivePaidPlan: state?.AuthReducer?.hasActivePaidPlan,
  }));
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      getCountry(
        (data: any) => {
          setCountryData(data);
        },
        () => {}
      )
    );
    dispatch(
      gradeLevelList(
        (data: any) => {
          const filterGrade = data?.filter((item: any) => item?._id >= 4);

          setGradeList(filterGrade);
        },
        () => {}
      )
    );
  }, []);

  const contactUS = (val: string) => {
    const element = document.getElementById(val);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  };

  var myScrollFunc = function () {
    // let offsetHeight = stageCanvasRef.current.offsetHeight;
    // if (window.innerWidth >= 768) {
    var y = window.scrollY;
    const maxScroll = document.body.scrollHeight - window.innerHeight;
    if (y >= 500 && y < maxScroll - 300) {
      setisHidden(false);
    } else {
      setisHidden(true);
    }
    // } else {
    //   setisHidden(false);
    // }
  };
  window.addEventListener("scroll", myScrollFunc);

  const approach = [
    {
      heading: "Customization",
      subtext:
        "Tailored learning plan designed for targeted areas of improvement focusing on accuracy and efficinet time utilization.",
    },
    {
      heading: "Comprehension",
      subtext:
        "Focusing on deep understanding ensuring learners grasp and retain complex concept , leading to exceptional learning outcome",
    },
    {
      heading: "Consistency",
      subtext:
        "Learners are challenged with rigorous course modules after each session with custom practice tests to outpace their benchmarks after every attempt.",
    },
  ];

  const handleFirstInputText = (e: any) => {
    if (validateFullName(e)) setfirstname(e);
    else {
      dispatch(showErrorSnackbar(validateFullNameErrMsg));
    }
  };
  const handleLastInputText = (e: any) => {
    if (validateFullName(e)) setlastName(e);
    else {
      dispatch(showErrorSnackbar(validateLastNameErrMsg));
    }
  };
  const setPhoneNumber = (e: any) => {
    if (!validateIsNumberOnly(e)) {
      setmob(e);
    } else {
      dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
    }
  };
  const onChangeCoutryCode = (e: any) => {
    setcoutryCode(e.target.value);
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const postData = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      mobile: mob,
      countryCode: "+1",
      countryId: "226",
      gradeId: grade,
      coursetypeId: 14,
      examtypeId: 71,
      sourceId: 2,
      statusId: 1,
    };
    console.log("postData", postData);
    dispatch(
      addDSATlandingpage(
        postData,
        (res: any) => {
          setfirstname("");
          setlastName("");
          setemail("");
          setmob("");
          setGrade("");
          // setIsSubmit(true);
          // const payload: any = {
          //   firstName: firstName,
          //   lastName: lastName,
          //   mobile: mob,
          //   email: email,
          //   coursetypeId: 14,
          //   countryCode:"+1",
          // };
          // dispatch(
          //   register(
          //     payload,
          //     (data: any) => {

          //     },
          //     () => {}
          //   )
          // );
        },
        () => {}
      )
    );
    // setOpenDemoSession(true);
  };
  const getGrade = (val: any) => {
    setGrade(val);
  };
  const program = [
    {
      bannerImg: localImages?.demandresourceBanner,
      icon: localImages?.demandresourceIcon,
      title: "On-Demand Resources",
      description:
        "Access a wealth of resources, including video lessons, practice questions, and study guides",
    },
    {
      bannerImg: localImages?.liveClassBanner,
      icon: localImages?.liveClassIcon,
      title: "Live Online Classes",
      description:
        "Join our live classes and interact with instructors and peers in real-time",
    },
    {
      bannerImg: localImages?.practiceTestBanner,
      icon: localImages?.practiceTestIcon,
      title: "Full-Length Practice Tests",
      description:
        "Simulate the real test environment with our full-length practice tests to make the learner exam ready",
    },

    {
      bannerImg: localImages?.detailsAnalysisBanner,
      icon: localImages?.detailsAnalysisIcon,
      title: "Detailed Performance Analysis",
      description:
        "Receive in-depth feedback on your practice tests to identify areas for improvement",
    },
    {
      bannerImg: localImages?.oneonOneLessonsBanner,
      icon: localImages?.oneonOneLessonsIcon,
      title: "Interactive one on one Lessons",
      description:
        "Engage with hyper personalized interactive lessons that make learning outcome effective.",
    },
  ];

  return (
    <div>
      <HeaderDsat isHidden={false} />
      {/* <div className="d-block d-md-none">
        <Sidebar
          data={
            hasActivePaidPlan === true
              ? SidebarDataPaidUser
              : SidebarDataFreeUser
          }
        />
      </div> */}
      {/*************Banner************* */}
      <div
        className="position-relative homeBannerContainer"
        id="Home"
        ref={homebannerdiv}
      >
        <div className="row mx-0 bgBlue">
          <div className="col-md-4 dsatBannerLeftDiv d-none d-md-flex">
            <img
              src={localImages?.dsatBanner}
              alt="Digital sat Banner"
              className="dsatBanner"
            />
          </div>
          <div className="col-md-8 academicsHeading mt-4 mt-md-0">
            <div className="academicsHeading">
              <h1 className="homeBannerHeading">
                Ace your <span className="footerIcon">Digital SAT</span> to
                unlock your dream university!
              </h1>
              <div className="font16 mt-3">
                Preparing for DSAT can be overwhelming and time-consuming.
                That's why we created 80 hours comprehensive program designed to
                provide you with the tools, strategies, and confidence needed to
                excel in the DSAT and secure a top score!
              </div>
              <div className="mt-4">
                <form className="w-100" onSubmit={handleSubmit}>
                  <div className="row mx-0">
                    <div className="my-2 col-md-6">
                      <label className="mb-2">
                        Parents First Name <span className="  ">*</span>
                      </label>
                      <div className="d-flex ">
                        <InputField
                          placeholder="Enter Parents Name"
                          value={firstName}
                          setValue={handleFirstInputText}
                        />
                      </div>
                    </div>
                    <div className="my-2 col-md-6">
                      <label className="mb-2">
                        Parents Last Name <span className="  ">*</span>
                      </label>
                      <div className="d-flex ">
                        <InputField
                          placeholder="Enter Parents Name"
                          value={lastName}
                          setValue={handleLastInputText}
                        />
                      </div>
                    </div>
                    <div className="my-2  col-md-6">
                      <label className="mb-2">
                        Phone Number <span>*</span>
                      </label>
                      <div className="d-flex position-relative">
                        <select
                          className="form-select cursor coutryCode  rounded-0 rounded-start"
                          aria-label="Default select example"
                          defaultValue={1}
                          value={coutryCode}
                          onChange={onChangeCoutryCode}
                        >
                          {countryData?.map((item: any, index) => {
                            return (
                              <option value={item.phonecode}>
                                {item?.isoCode} +{item?.phonecode}
                              </option>
                            );
                          })}
                        </select>
                        <PhoneInputField
                          placeholder="Enter Your Mobile Number"
                          value={mob}
                          maxLength={"10"}
                          setValue={setPhoneNumber}
                        />
                      </div>
                    </div>
                    <div className="my-2 col-md-3 ">
                      <label className="mb-2">
                        Email <span>*</span>
                      </label>
                      <div className="d-flex">
                        <input
                          className="form-control"
                          type="email"
                          value={email}
                          placeholder="Enter Email Address"
                          onChange={(e) => setemail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="my-2 col-md-3 ">
                      <label className="mb-2">
                        Student Grade <span>*</span>
                      </label>
                      <div className="  ">
                        <Dropdown
                          placeholder="Select Grade"
                          options={gradeList}
                          value={grade}
                          setInputText={(value: any) => getGrade(value)}
                        />
                      </div>
                    </div>
                    <div className="d-grid mb-5 mt-3 text-center">
                      <button className="bookSessionButton rounded fw-bold">
                        Book Your Trial Session
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="commonPad  ">
        <div className=" mx-md-5 my-5">
          <h2 className="headingFont text-center footerIcon">
            Digital SAT is a timebound exam
          </h2>
          <div className="font24 text-center fw-bold">
            Success depends not only on getting the answers right but on
            Achieving them in the shortest time possible
          </div>
        </div>
        <div className="boxshadow row d-flex justify-content-between p-2 mx-md-5 py-5 px-md-5">
          <div className="col-md-6 d-flex justify-content-center ">
            <img
              src={localImages?.Customization}
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="col-md-6">
            <p className="approachheading">Our approach to it?</p>
            {approach.map((c: any, index: any) => {
              return (
                <div className="  approachBox m-2">
                  <h5 className="fw-bold">{c?.heading}</h5>
                  <p>{c?.subtext}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div id="OurProgram"></div>
      <div className="commonPad bglightSkyblue ">
        <div className=" mx-md-5 my-5">
          <h1 className="headingFont text-center  ">Our Program Consist of?</h1>
        </div>
        <div className="row mx-0 d-flex justify-content-center">
          {program?.map((c: any, index: any) => {
            return (
              <div className="col-md-4 rounded mb-4">
                <div className="rounded bg-white overflow-hidden h-100">
                  <img
                    src={c?.bannerImg}
                    alt={c.title}
                    className="w-100 aspectRation169"
                  />
                  <div className="position-relative programContentDiv">
                    <div className="programIconBg">
                      <img src={c.icon} alt="Icon" className="programIcon" />
                    </div>

                    <div className="fw-bold font28 text-center">{c.title}</div>
                    <div className="font16 mt-3 text-center">
                      {c.description}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div id="WhyUs"></div>
      <div className="commonPad bgBlue learnSolDiv">
        <img
          src={localImages?.award}
          className="learnSolleft"
          alt="learnSolleft"
        />
        <img
          src={localImages?.award2}
          className="awardright"
          alt="awardright"
        />
        <div className="width800 mx-auto mt-5 mb-5">
          <h1 className="headingFont text-center">
            Why should you consider us?
          </h1>
          <div className="font24 text-center">Our stats speak for itself</div>
        </div>
        <div
          id="whyUs"
          className="carousel slide whyUs"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active" data-bs-interval="1000">
              <div className="row">
                <div className="col-md-8">
                  <img
                    src={localImages?.studentEnrollSVG?.default}
                    alt="icon"
                    className="w-100"
                  />
                </div>
                <div className="col-md-4">
                  <div className="d-flex flex-column justify-content-center h-100">
                    <div className="whyUsCount">1K+</div>
                    <div className="whyUsContent">Student Enrolled</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item" data-bs-interval="1000">
              <div className="row">
                <div className="col-md-8">
                  <img
                    src={localImages?.averageScoreBoost?.default}
                    alt="icon"
                    className="w-100"
                  />
                </div>
                <div className="col-md-4">
                  <div className="d-flex flex-column justify-content-center h-100">
                    <div className="whyUsCount">2.5X</div>
                    <div className="whyUsContent">Average Score Boost</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item" data-bs-interval="1000">
              <div className="row">
                <div className="col-md-8">
                  <img
                    src={localImages?.averageScore?.default}
                    alt="icon"
                    className="w-100"
                  />
                </div>
                <div className="col-md-4">
                  <div className="d-flex flex-column justify-content-center h-100">
                    <div className="whyUsCount">1550+</div>
                    <div className="whyUsContent">Average Score</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="Testimonials"></div>
      <div className="commonPad bgLightGrey">
        <div className="width800 mx-auto my-5">
          <h1 className="headingFont text-center">
            Don't just take our word for it
          </h1>
          <div className="bannersubtext mb-3 text-center">
            Hear what our students have to say
          </div>
        </div>
        <div>
          <StudentSayCarousel
            Data={dataConstant?.studentSayData}
            id="carouselStudent"
          />
        </div>
        <div></div>
      </div>
      <div id="Awards"></div>
      <CertificateSection />

      <div className="commonPad bglightSkyblue ">
        <div className="bgwhite p-5 boxshadow rounded position-relative">
          <div className="row">
            <div className="col-md-8">
              <div className="bookSessionHeading">Book your session now</div>
              <div className="bookSessionSubText">
                One of our Academic Counsellor will get in touch with you to
                discuss further.
              </div>
              <div className="mt-5">
                <button
                  className="signBtn px-5 rounded font16"
                  onClick={() => setopenDsat(true)}
                >
                  Start your DSAT Journey Today
                </button>
              </div>
            </div>
            <div className="col-md-4">
              {/* <img src={localImages?.collegePredleft} className='collegePredleft' alt='collegePredleft'/> */}
              {/* <img src={localImages?.collegePredright} className='collegePredright' alt='collegePredright'/> */}
            </div>
          </div>
        </div>
        {/* <BookSession clickEvent={() => goToLink("BookSession")} /> */}
      </div>
      <div id="FAQs"></div>
      <div className="commonPad ">
        <div className="row mx-0">
          <div className="col-md-3">
            <h1 className="headingFont mb-4">FAQs</h1>
            <div className="mb-4">
              <button
                className="contactUSbtn px-5"
                onClick={() => contactUS("Footer")}
              >
                Contact Us
              </button>
            </div>
          </div>
          <div className="col-md-9">
            <Faq faqData={dataConstant?.faqData} id={"HomeFAQ"} />
          </div>
        </div>
      </div>
      {/***************Footer*************** */}
      <BookDemoSessionPopup
        open={openDemoSession}
        handleClose={closesDemosession}
        firstName={firstName}
        lastName={lastName}
        mob={mob}
        countryCode={coutryCode}
      />
      <Footer id="Footer" />
      <BookSessionPopup open={openBookSeat} handleClose={closesBooseat} />

      <DsatPopup
        open={openDsat}
        handleClose={() => setopenDsat(false)}
        mob={mob}
        countryCode={coutryCode}
      />

      {!isHidden ? (
        <div className="fixedFooter">
          <div className="row mx-0">
            <div className="col-7 col-md-6 d-flex align-items-center mb-2 mb-md-0">
              <div className="fw-bold font20 colorblue">
                Max Out Your Digital SAT Score with Us !
              </div>
            </div>
            <div className="col-5 col-md-6 d-flex align-items-center justify-content-center justify-content-md-end flex-wrap position-relative">
              <div className="d-none d-md-flex position-relative me-2">
                <select
                  className="form-select cursor coutryCode  rounded-0 rounded-start d-none d-md-flex"
                  aria-label="Default select example"
                  defaultValue={1}
                  value={coutryCode}
                  onChange={onChangeCoutryCode}
                >
                  {countryData?.map((item: any, index) => {
                    return (
                      <option value={item.phonecode}>
                        {item?.isoCode} +{item?.phonecode}
                      </option>
                    );
                  })}
                </select>
                <PhoneInputField
                  placeholder="Enter Your Mobile Number"
                  value={mob}
                  maxLength={"10"}
                  setValue={setPhoneNumber}
                />
              </div>
              <button
                className="bookSessionButton rounded fw-bold mt-2 mt-md-0 font16"
                onClick={() => setopenDsat(true)}
              >
                Book Your Trial Session
              </button>
              {/* <div className="position-absolute"> */}
              {!closePopup ? (
                <div className="digitalSATHintBox">
                  <div className="d-flex flex-fill justify-content-end mb-3">
                    <CloseIcon onClick={() => setClosePopup(true)} />
                  </div>
                  <div className="font16 text-center fw-bold">
                    Ace your <span className="textRed">Digital SAT</span> score
                    to unlock your dream university!
                  </div>
                  <div className="text-center my-2">
                    <img
                      src={localImages?.dsathintImg}
                      alt="dsathintImg"
                      className="dsathintImg"
                    />
                  </div>
                  <div className="text-center">
                    Book Your Trial Session With Us!
                  </div>
                </div>
              ) : null}
              {/* </div> */}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
