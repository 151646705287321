import React, { useEffect, useState } from "react";
import localImages from "../Constant/localImagesNew";
import "../Assets/Css/home.css";
import Universities from "../Component/universities";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css";
import Faq from "../Component/faq";
import Footer from "../Component/footer";
import { useNavigate } from "react-router-dom";
import HeaderHome from "../Component/headerHome";
import Sidebar from "../Module/sidebar";
import { useSelector } from "react-redux";
import {
  SidebarDataFreeUser,
  SidebarDataPaidUser,
} from "../Module/sidebarData"; 
import BookSessionPopup from "../Component/bookSessionPopup";
import BookSession from "../Module/bookSession";
import StudentSayCarousel from "../Component/studentSayCarousel";
import BannerVideo from "../Module/bannerVideo";
import dataConstant from "../Constant/dataConstant";
import { getCountry } from "../Redux/Actions/AuthAction";
import { useAppDispatch } from "../hooks";
import StatCard from "../Module/statCard";
import CertificateSection from "../Module/certificateSection";
import InputField from "../Atom/InputField";
import PhoneInputField from "../Atom/phoneInputField";
import { validateFullName, validateIsNumberOnly } from "../Utils/validators";
import { showErrorSnackbar } from "../Redux/Actions/snackbarAction";
import {
  validateFullNameErrMsg,
  validateIsNumberOnlyErrMsg,
  validateLastNameErrMsg,
} from "../Utils/errormsg";
import BookDemoSessionPopup from "../Component/bookDemoSessionPopUp";
export default function Academics() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const homebannerdiv = React.useRef<HTMLDivElement>(null);
  const [isHidden, setisHidden] = useState<boolean>(true);
  const [openBookSeat, setopenBookSeat] = useState<boolean>(false);
  const [firstName, setfirstname] = useState<string>("");
  const [lastName, setlastName] = useState<string>("");
  const [mob, setmob] = useState<string>("");
  const [countryData, setCountryData] = useState([]);
  const [coutryCode, setcoutryCode] = useState<string>("61");
  const [openDemoSession, setOpenDemoSession] = useState<boolean>(false);
  const closesBooseat = () => {
   setopenBookSeat(false);
  };
  const closesDemosession = (e:any) => {
    e.preventDefault();
     setOpenDemoSession(false);
  };

  const { ACCESS_TOKEN, hasActivePaidPlan } = useSelector((state: any) => ({
    ACCESS_TOKEN: state?.AuthReducer?.ACCESS_TOKEN,
    hasActivePaidPlan: state?.AuthReducer?.hasActivePaidPlan,
  }));
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      getCountry(
        (data: any) => {
          setCountryData(data);
        },
        () => {}
      )
    );
  }, []);

  const contactUS = (val: string) => {
    const element = document.getElementById(val);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  };

  var myScrollFunc = function () {
    // let offsetHeight = stageCanvasRef.current.offsetHeight;
    if (window.innerWidth >= 768) {
      var y = window.scrollY;
      if (y >= 90) {
        setisHidden(false);
      } else {
        setisHidden(true);
      }
    } else {
      setisHidden(false);
    }
  };
  window.addEventListener("scroll", myScrollFunc);

  const personalizedProgram = [
    {
      imgPath: localImages?.oneOnOneLive,
      heading: "One-on-One Live Interactive Classes",
      subtext:
        "Receive personalized coaching from expert instructors through individual attention and customized instruction in our interactive classes.",
    },
    {
      imgPath: localImages?.regularAssessment,
      heading: "Regular Assessments",
      subtext:
        "Track your progress and identify areas for improvement with daily, weekly, and monthly assessments, ensuring you stay ahead in your test preparation.",
    },
    {
      imgPath: localImages?.dedicatedDoubt,
      heading: "Dedicated Doubt Clarification Support",
      subtext:
        "Get dedicated support for clarifying doubts, ensuring a thorough understanding of concepts and techniques required for exam success.",
    },
    {
      imgPath: localImages?.grandTest,
      heading: "Grand Tests at the End of the Program",
      subtext:
        "Assess your readiness with five comprehensive grand tests that closely simulate the actual exam experience.",
    },
    {
      imgPath: localImages?.weeklyGroup,
      heading: "Weekly Group Brainstorming Sessions",
      subtext:
        "Sharpen problem-solving skills and gain new insights through collaborative brainstorming sessions with fellow students, facilitated by peer-to-peer interactions.",
    },
    {
      imgPath: localImages?.roadmapStrategy,
      heading: "Personalized Roadmap Strategy",
      subtext:
        "Receive a tailored, step-by-step plan crafted by experts to streamline your preparation and navigate potential challenges, giving you a strategic advantage.",
    },
    {
      imgPath: localImages?.counsellorStrategy,
      heading: "Dedicated Counsellor Support",
      subtext:
        "Assess your readiness with five comprehensive grand tests that closely simulate the actual exam experience.",
    },
    {
      imgPath: localImages?.ongoingAlumni,
      heading: "Ongoing Alumni Network Interaction",
      subtext:
        "Receive a tailored, step-by-step plan crafted by experts to streamline your preparation and navigate potential challenges, giving you a strategic advantage.",
    },
  ];

  function goToLink(val:string): void {
    if(val==="BookSession"){
        navigate('/'+val,{ state: { mobNo: "",coutryCodeNo:"",isOTPVerify:ACCESS_TOKEN?true:false } });  
    }
    else{
        navigate('/'+val);
    }
  }
  const handleFirstInputText = (e: any) => {
    if (validateFullName(e)) setfirstname(e);
    else {
      dispatch(showErrorSnackbar(validateFullNameErrMsg));
    }
  };
  const handleLastInputText = (e: any) => {
    if (validateFullName(e)) setlastName(e);
    else {
      dispatch(showErrorSnackbar(validateLastNameErrMsg));
    }
  };
  const setPhoneNumber = (e: any) => {
    if (!validateIsNumberOnly(e)) {
      setmob(e);
    } else {
      dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
    }
  };
  const onChangeCoutryCode = (e: any) => {
    setcoutryCode(e.target.value);
  };
  const handleSubmit=(e: any)=>{
    e.preventDefault();
    setOpenDemoSession(true);
  }
  return (
    <div>
      <HeaderHome isHidden={isHidden} />
      <div className="d-block d-md-none">
        <Sidebar
          data={
            hasActivePaidPlan === true
              ? SidebarDataPaidUser
              : SidebarDataFreeUser
          }
        />
      </div>
      {/*************Banner************* */}
      <div
        className="position-relative homeBannerContainer"
        id="homebanner"
        ref={homebannerdiv}
      >
        <div className="row mx-0 ">
          <div className="col-md-6 position-relative pe-md-0">
            <BannerVideo src={localImages.homeBannerVideo} />
          </div>
          <div className="col-md-6 academicsHeading">
            <div className="academicsHeading">
              <h1 className="homeBannerHeading">Embark your</h1>
              <h1 className="homeBannerHeading">journey to your</h1>
              <h1 className="homeBannerHeading">Dream University</h1>
              <div className="mt-5">
                <form className="w-100" onSubmit={handleSubmit}>
                  <div className="row mx-0">
                    <div className="my-2 col-md-6">
                      <label className="mb-2">First Name</label>
                      <div className="d-flex">
                        <InputField
                          placeholder="Enter First Name"
                          value={firstName}
                          setValue={handleFirstInputText}
                        />
                        <span className="mendatory ms-2 fs-5">*</span>
                      </div>
                    </div>
                    <div className="my-2 col-md-6">
                      <label className="mb-2">Last Name</label>
                      <div className="d-flex">
                        <InputField
                          placeholder="Enter Last Name"
                          value={lastName}
                          setValue={handleLastInputText}
                        />
                        <span className="mendatory ms-2 fs-5">*</span>
                      </div>
                    </div>

                    <div className="my-2">
                      <label className="mb-2">Mobile Number</label>
                      <div className="d-flex position-relative">
                        <select
                          className="form-select cursor coutryCode  rounded-0 rounded-start"
                          aria-label="Default select example"
                          defaultValue={1}
                          value={coutryCode}
                          onChange={onChangeCoutryCode}
                        >
                          {countryData?.map((item: any, index) => {
                            return (
                              <option value={item.phonecode}>
                                {item?.isoCode} +{item?.phonecode}
                              </option>
                            );
                          })}
                        </select>
                        <PhoneInputField
                          placeholder="Enter Your Mobile Number"
                          value={mob}
                          maxLength={"10"}
                          setValue={setPhoneNumber}
                        />
                        <span className="mendatory ms-2 fs-5">*</span>
                      </div>
                    </div>
                    <div className="mt-5 text-center">
                      <button className="signBtn px-5">
                        Book Demo Session
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="commonPad">
        <Universities />
      </div>

      <div className="commonPad personalProgram">
        <div className="width800 mx-auto my-5">
          <h1 className="headingFont text-center">
            Personalized programs designed to help every student
          </h1>
        </div>
        <div className="row learnSolTab">
          {personalizedProgram?.map((item, index) => (
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
              <div className="border rounded p-3 h-100">
                <img
                  src={item?.imgPath}
                  alt={item?.heading}
                  className="w-100"
                />
                <div className="programHeading fw-bold mt-3 mb-2 lh-sm">
                  {item?.heading}
                </div>
                <div className="font16">{item?.subtext}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <StatCard
        buttonName="Explore Programs"
        handleClick={() => goToLink("AdvanceAcademics")}
      />
      <div className="commonPad bgLightGrey">
        <div className="width800 mx-auto my-5">
          <h1 className="headingFont text-center">
            Don't just take our word for it
          </h1>
          <div className="bannersubtext mb-3 text-center">
            Hear what our students have to say
          </div>
        </div>
        <div>
          <StudentSayCarousel
            Data={dataConstant?.studentSayData}
            id="carouselStudent"
          />
        </div>
        <div></div>
      </div>

      <div className="commonPad ">
        <BookSession clickEvent={() => goToLink("BookSession")} />
      </div>
      <CertificateSection />
      <div className="commonPad ">
        <div className="row mx-0">
          <div className="col-md-3">
            <h1 className="headingFont mb-4">FAQs</h1>
            <div className="mb-4">
              <button
                className="contactUSbtn px-5"
                onClick={() => contactUS("Footer")}
              >
                Contact Us
              </button>
            </div>
          </div>
          <div className="col-md-9">
            <Faq faqData={dataConstant?.faqData} id={"HomeFAQ"} />
          </div>
        </div>
      </div>
      {/***************Footer*************** */} 
       <BookDemoSessionPopup open={openDemoSession}  handleClose={closesDemosession} firstName={firstName} lastName={lastName} mob={mob} countryCode={coutryCode}/>    
      <Footer id="Footer" />
      <BookSessionPopup open={openBookSeat} handleClose={closesBooseat} />
  
    </div>
  );
}
