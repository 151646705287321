import localImages from "./localImagesNew";

const dataConstant={
  statData:[
    {
        count:"1K+",
        heading:"Students Joined"
    },
    {
        count:"120K+",
        heading:"Hours of Live Tutoring"
    },
    {
        count:"500+",
        heading:"Experts Tutors"
    },
    {
        count:"5X",
        heading:"Admission Rate to US Top 10 Colleges"
    },
    {
        count:"97%",
        heading:"Grade Improvement by 36%"
    },
    {
        count:"4/5",
        heading:"Students Get Offers from LVY"
    }
],
stats:[
    {
        count:"1",
        AdditionText:"K+",
        heading:"Happy Learners",
        animationPath:localImages?.HappyLearners
    },
   
    {
        count:"500",
        AdditionText:"+",
        heading:"Experts Tutors",
        animationPath:localImages?.ExpertTutors
    },
    {
        count:"40",
        AdditionText:"k+",
        heading:"Hours of Live Tutoring",
        animationPath:localImages?.HoursLiveTutoring
    },
    {
        count:"97",
        AdditionText:"%",
        heading:"Of the students improved their grades",
        animationPath:localImages?.HappyLearners,
        percentage:90
    },
    {
        count:"4",
        AdditionText:"/5",
        heading:"Students get admit from tier 1 universities",
        animationPath:localImages?.studentadmit
    },
   
],
insightStory:[
    {
        imgPath:localImages?.blog1,
        time:"5 MIN",
        heading:"Navigating the College Maze: Lurnigo's Guide to Smarter Choices",
        subtext:"Uncover valuable insights and expert tips on choosing the right college path. From personalized counseling to decoding admission strategies, join us on a journey of informed decisions with Lurnigo's college consulting expertise."
    },
    {
        imgPath:localImages?.blog2,
        time:"5 MIN",
        heading:"Cracking the Code: Lurnigo's Approach to Mastering Standardized Tests",
        subtext:"Dive into the world of test preparation with Lurnigo's proven strategies. Explore effective study plans, expert techniques, and success stories that will empower you to conquer standardized tests with confidence."
    },
    {
        imgPath:localImages?.blog3,
        time:"5 MIN",
        heading:"Beyond the Classroom: How Lurnigo Redefines Learning Spaces",
        subtext:"Discover a new era of education as we explore the impact of personalized learning with Lurnigo. From handpicked teachers to advanced learning resources, delve into the innovative features shaping the future of education beyond traditional boundaries."
    }, 
],
studentSayData:[
    {
        title:"“Thank you Aniketh”",
        desc:"Thank you Aniketh for being such an excellent mathematics teacher. Thank you for supervising my son's work and helping him improve & giving your honest opinions, you showed confidence in my child and helped to thrive in particular subject.Thanks a lot for helping realize his potential!",
        rating:5,
        videoPath:localImages.ParentCollabTestemonial,
        // img:localImages?.carouselimg,
        name:"Hema Chunduri",
        date:" June 07, 2024"
    },
    {
        title:"“A good way to have straight A’s at school”",
        desc:"The teachers are very enthusiastic, energetic and knowledgable. They always explain the asked questions and in the end of the lesson they ask questions to see if the student understood the topic. We started with one subject and already continued with 3 subjects, this shows how happy we are to have found this service. My child feels much more confident at school. We highly recommend LurniGo!",
        rating:5,
        videoPath:localImages.ParentCollabTestemonial,
        // img:localImages?.carouselimg,
        name:"Angelina P",
        date:"May 15, 2024"
    },
   
     {
        title:"“LurniGo is good for High Schooler”",
        desc:"LurniGo has transformed my high schooler's learning experience. Its intuitive interface and cutting-edge technology make lessons interactive and personalized. With animations, videos, and quizzes, LurniGo makes learning enjoyable and effective. It's a game-changer in education, with innovative approaches, exceptional educators, and accessibility for all students. I'm grateful for its positive impact on countless high school students, empowering them to excel with top GPAs and achieve higher SAT scores.",
        rating:5,
        videoPath:localImages.ParentCollabTestemonial,
        // img:localImages?.carouselimg,
         name:"Greta",
        date:"Feb 18, 2024"
    },
    
    {
        title:"“Professional excellent teaching”",
        desc:"Hi, our son Ishaan started taking classes through Lurnigo for his Naplan Exam from January 2024. We were quite impressed and satisfied with the process teachers provide learning experience to students and their explanation on each topic is exemplary.As we live in Perth, Australia, we were actually looking for the tutors who can teach like the teachers in India, explaining the method and process of each learning areas, ultimately through Lurnigo we have found a good teaching academy.Their academic councilor and relationship manager are equally excellent in their responsibilities.",
        rating:5,
        videoPath:localImages.ParentCollabTestemonial,
        // img:localImages?.carouselimg,
          name:"Anita Lohani",
        date:"Mar 30, 2024"
      
    },
    {
        title:"“Lurnigo is a v good platform its the…”",
        desc:"Lurnigo is a v good platform its the best one I hv found for my daugther",
        rating:5,
        videoPath:localImages.ParentCollabTestemonial,
        // img:localImages?.carouselimg,
          name:"Hira Jalil",
        date:"April 24, 2024"
      
    },
    

],
faqData:[
    {
        title:"What makes LurniGo stand out in the EdTech sector?",
        desc:"LurniGo combines elite tutor recruitment (top 1% industry professionals) with personalized, one-on-one live sessions, specializing in comprehensive academic and test prep services."
    },
    {
        title:"Who benefits from LurniGo's services?",
        desc:"Students aiming for academic excellence and admission to prestigious universities globally, seeking tailored support in academics, test prep, and college admissions."
    },
    {
        title:"What is included in LurniGo's test preparation coaching?",
        desc:"A personalized study plan with diagnostic assessments, practice tests, and strategies focused on maximizing strengths and addressing weaknesses for comprehensive test readiness."
    },
    {
        title:"What does LurniGo's college consulting service offer?",
        desc:"Strategic guidance through the college application process, including college selection, personal statement crafting, and interview preparation, tailored to enhance the student's admission prospects."
    },
    {
        title:"How can students enroll in LurniGo's programs?",
        desc:"Prospective students can start by visiting our website or contacting our customer service for a personalized consultation and guidance through the enrollment process."
    }
],
universityTable:[
    {
        universityname: "Harvard University",
        generalRate: "3%",
        lurnigoRate: "6"
    },
    {
        universityname: "Columbia University",
        generalRate: "4%",
        lurnigoRate: "4"
    },
    {
        universityname: "MIT",
        generalRate: "4%",
        lurnigoRate: "10"
    },
    {
        universityname: "Stanford University",
        generalRate: "4%",
        lurnigoRate: "6"
    },
    {
        universityname: "Brown University",
        generalRate: "5%",
        lurnigoRate: "13"
    },
    {
        universityname: "University of Chicago",
        generalRate: "5%",
        lurnigoRate: "19"
    },
    {
        universityname: "Yale University",
        generalRate: "5%",
        lurnigoRate: "17"
    },
    {
        universityname: "Dartmouth College",
        generalRate: "6%",
        lurnigoRate: "19"
    },
    {
        universityname: "Duke University",
        generalRate: "6%",
        lurnigoRate: "23"
    },
    {
        universityname: "Princeton University",
        generalRate: "6%",
        lurnigoRate: "15"
    },
    {
        universityname: "Cornell University",
        generalRate: "7%",
        lurnigoRate: "10"
    },
    {
        universityname: "Johns Hopkins University",
        generalRate: "7%",
        lurnigoRate: "21"
    },
    {
        universityname: "University of Pennsylvania",
        generalRate: "7%",
        lurnigoRate: "19"
    },
    {
        universityname: "UCLA",
        generalRate: "9%",
        lurnigoRate: "38"
    },
    {
        universityname: "Carnegie Mellon University",
        generalRate: "11%",
        lurnigoRate: "31"
    },
    
]
};

export default dataConstant;
