import React, { useEffect, useState } from "react";
import "../Assets/Css/login.css";
import { useNavigate } from "react-router-dom";
import { validateIsNumberOnly } from "../Utils/validators";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../Redux/Actions/snackbarAction";
import { validateIsNumberOnlyErrMsg } from "../Utils/errormsg";
import { useAppDispatch } from "../hooks";
import { getCountry, updateMoreValues } from "../Redux/Actions/AuthAction";
import { auth } from "../firebase.config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import PhoneInputField from "../Atom/phoneInputField";
import HeaderHome from "../Component/headerHome";
import localImages from "../Constant/localImagesNew";
import Sidebar from "../Module/sidebar";
import { SidebarDataFreeUser } from "../Module/sidebarData";
import { useSelector } from "react-redux";
import { AuthReducerModal } from "../Modals";

declare global {
  interface Window {
    // ⚠️ notice that "Window" is capitalized here
    recaptchaVerifier: any;
    confirmationResult: any;
  }
}

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [Mobno, setMobno] = useState("");
  const [countryData, setCountryData] = useState([]);
  const [loginFor, setLoginFor] = useState("Student");

  const { firstName, countryCode } = useSelector(
    (state: { AuthReducer: AuthReducerModal }) => ({
      firstName: state?.AuthReducer?.firstName,
      countryCode: state?.AuthReducer?.userCountryCallingCode,
    })
  );
  const [coutryCode, setcoutryCode] = useState<string>(
    countryCode ? countryCode.substring(1) : "61"
  );
  useEffect(() => {
    dispatch(
      getCountry(
        (data: any) => {
          setCountryData(data);
        },
        () => {}
      )
    );
  }, []);
  console.log("coutryCode", coutryCode);
  function validations() {
    return (
      (coutryCode !== "61" && Mobno?.length < 10) ||
      (coutryCode === "61" && Mobno?.length < 9)
    );
  }

  const onSignup = () => {
    dispatch(updateMoreValues({ isLoader: true }));
    const formatPh = "+" + coutryCode + Mobno;
    let verifier = new RecaptchaVerifier(auth, "recaptcha-container", {
      size: "invisible",
    });

    if (auth && formatPh && verifier) {
      signInWithPhoneNumber(auth, formatPh, verifier)
        .then(function (result: any) {
          if (result) {
            dispatch(updateMoreValues({ isLoader: false }));
            window.confirmationResult = result;
            dispatch(showSuccessSnackbar("OTP sent successfully!"));
            verifier.clear();
            navigate("/OTPVerification", {
              state: {
                mobileNo: Mobno,
                coutryCode: coutryCode,
                loginFor: loginFor,
              },
            });
          }
        })
        .catch(function (err: any) {
          dispatch(updateMoreValues({ isLoader: false }));
          dispatch(showErrorSnackbar(err.name));
          verifier.clear();
          // window.location.reload()
        });
    }
  
  };
  const onChangeCoutryCode = (e: any) => {
    setcoutryCode(e.target.value);
  };
  const setPhoneNumber = (e: any) => {
    if (!validateIsNumberOnly(e)) {
      setMobno(e);
    } else {
      dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
    }
  };
  return (
    <div>
      <HeaderHome isHidden={true} />
      <div className="d-block d-md-none">
        <Sidebar data={SidebarDataFreeUser} />
      </div>
      <div className="loginBG bgLightGrey d-flex position-relative">
        <img
          src={localImages?.loginleftarrow}
          className="loginLeftArrow"
          alt="loginLeftArrow"
        />
        <img
          src={localImages?.loginrightarrow}
          className="loginRightArrow"
          alt="loginRightArrow"
        />
        <div className="row authDiv w-100">
          <div className="col-lg-5 rightDivPad position-relative m-auto boxshadow">
            <h3 className="boldfont text-center mb-2">Welcome to Lurnigo</h3>
            <div className="colorblue text-center my-2">Let's Get Started</div>
            <div className="colorgray707070 text-center">
              To get started, please verify mobile number
            </div>

            <div className="mobileField mt-5 px-2">
              <div className="phoneInputBox">
                {/* <div className="d-flex mb-3">
                  <div className="form-check me-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      checked={loginFor === "Student" ? true : false}
                      onChange={() => setLoginFor("Student")}
                    />
                    <label
                      className="form-check-label font16"
                      htmlFor="flexRadioDefault1"
                    >
                      Student
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      checked={loginFor === "Parent" ? true : false}
                      onChange={() => setLoginFor("Parent")}
                    />
                    <label
                      className="form-check-label font16"
                      htmlFor="flexRadioDefault2"
                    >
                      Parent
                    </label>
                  </div>
                </div> */}
                <div className="my-2">
                  <label className="mb-2">Mobile Number</label>
                  <div className="d-flex position-relative">
                    <select
                      className="form-select cursor coutryCode  rounded-0 rounded-start"
                      aria-label="Default select example"
                      defaultValue={1}
                      value={coutryCode}
                      onChange={onChangeCoutryCode}
                    >
                      {countryData?.map((item: any, index) => {
                        return (
                          <option value={item.phonecode}>
                            {item?.isoCode} +{item?.phonecode}
                          </option>
                        );
                      })}
                    </select>
                    <PhoneInputField
                      placeholder="Enter Your Mobile Number"
                      value={Mobno}
                      maxLength={"10"}
                      setValue={setPhoneNumber}
                    />
                    {/* <span className="mendatory ms-2 fs-5">*</span> */}
                  </div>
                </div>
                <div id="recaptcha-container"></div>
                <div className="mt-5">
                  <button
                    className={`w-100 rounded px-4 py-1 font24 border-0 ${
                      validations() ? "btnDisable" : "btnActive"
                    }`}
                    disabled={validations()}
                    onClick={onSignup}
                    // onClick={sendOTP}
                  >
                    Login
                  </button>
                </div>
              </div>
            </div>

            {/* <div className="mb-o tncdiv bottom-0 start-0 end-0 m-auto">
              <p className="text-center ">
                <span className="colorblue cursor" onClick={gototerms}>
                  T&C
                </span>{" "}
                and
                <span className="colorblue cursor" onClick={gotoprivacypolicy}>
                  {" "}
                  Privacy Policy
                </span>
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
