import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; 
import localImages from "../Constant/localImagesNew";
import MultiLayerChart from "./multiLayerChart";
import SemiCircleChart from "./semiCircleChart";
import moment from "moment";
import DppMultilayerChart from "./dppMultilayerChart";
import DppBarChart from "./dppBarChart";

function DppAnalysis(props: any) { 


  const seconds = props?.sectionData?.examStats?.totalTimeSpent 
  function convertSecondsToMinutes(seconds: any ) {
    const minutes = Math.floor(seconds/ 60);
    const remainingSeconds = seconds % 60; 
    return `${minutes} `;
  }
 const time = convertSecondsToMinutes(seconds);
  console.log("time",time)
  const ScoreHolder = [
    {
      icon: localImages?.rightTick,
      backgroundColor: "#158803",
      color: "#158803",
      heading: "Your Marks",
      marks: props?.sectionData?.examStats?.marksObtained,
      totalMarks: props?.sectionData?.examStats?.totalMarks,
      children: "OVERALL",
    },
    {
      icon: localImages?.star,
      backgroundColor: "#FFA40B",
      color: "#FFA40B",
      heading: "Your Rank",
      marks: props?.sectionData?.examStats?.rank,
     totalMarks: props?.sectionData?.examStats?.rank,
      children: "OVERALL",
    },
    {
      icon: localImages?.clock,
      backgroundColor: "#FF3B30",
      color: "#FF3B30",
      heading: "Time Spent",
      marks:time,
      totalMarks: parseInt(props?.sectionData?.examStats?.totalDuration)?.toFixed(2),
      children: "OVERALL",
    },
  ]; 
  const DotListItem = ({ backgroundColor, text, value }) => (
    <li className="mb-2">
      <div className="d-flex">
        <span className="dot me-3" style={{ backgroundColor }}></span>
        <div className="text-holder">
          {text}
          <span>{value}</span>
        </div>
      </div>
    </li>
  );

  return (
    <div className="result-analysis px-2 ">
       <div className="exam-name-holder ms-2 mb-3">
       {/* {props?.sectionData?.examData[0]?.examSetType?.name}
        <span className="d-block">{`Attempted on ${moment(
          props?.sectionData?.examData[0]?.examSetType?.createdAt
        ).format("LL")} |${moment(
          props?.sectionData?.examData[0]?.examSetType?.createdAt
        ).format("LT")} `}</span> */}
      </div>
       <div className="row mb-3">
        <div className="col-xl-7 col-lg-12 mb-md-1 mb-4 p-0">
          <div className="score-div d-flex justify-content-between">
            {ScoreHolder.map((c: any, index: any) => {
              return (
                <div className="col-md-4 mb-md-0 mb-3 p-2">
                  <div className="score-holder py-4">
                     <span className="analysis-caption" 
              style={{backgroundColor:c?.backgroundColor}}
              >
                {c?.children}
              </span>  
                    <div className="heading-holder mt-2">
                      <p className="boldfont ms-1">
                        <img
                          src={c?.icon}
                          className="me-1"
                          alt={c?.heading}
                          width="23"
                        />
                        {c?.heading}
                      </p>
                      <SemiCircleChart
                        color={c?.color}
                        marks={c?.marks}
                        totalMarks={c?.totalMarks}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        
         <div className="col-xl-5 col-lg-12">
          <div className="performance-holder pt-1">
            <div className="row my-3">
              <div className="col-md-6 ps-0">
                <span
                  className="analysis-caption"
                  style={{ backgroundColor: "#EFEFEF", color: "#4F4F4F" }}
                >
                  OVERALL PERFORMANCE
                </span>
                <ul className="ms-md-4 ms-0 my-2">
                  {[
                    {
                      backgroundColor: "#158803",
                      text: "Percentile",
                      value: props?.sectionData?.examStats?.percentile,
                    },
                    {
                      backgroundColor: "#FFA40B",
                      text: "Accuracy",
                      value: parseInt(
                        props?.sectionData?.examStats?.accuracy
                      )?.toFixed(2),
                    },
                    {
                      backgroundColor: "#FF3B30",
                      text: "Attempted",
                      value: props?.sectionData?.examStats?.attempted,
                    },
                  ].map((item, index) => (
                    <DotListItem key={index} {...item} />
                  ))}
                </ul>
              </div>
              <div className="col-md-6 p-3">
                <DppMultilayerChart
                  percentile={props?.sectionData?.examStats?.percentile}
                  accuracy={props?.sectionData?.examStats?.accuracy}
                  attempted={props?.sectionData?.examStats?.attempted}
                  style={{ position: "relative", width: "18vh" }}
                />
              </div>
            </div>
          </div>
        </div>  
      </div> 
      <div className="row mb-md-1 mb-4">
        <div className="col-xl-7 col-lg-12 mb-3">
          <div className="bar-charts">
            <div className="row">
              <div className="section-wise">
                <div className="heading-holder mt-2">
                  <p className="boldfont">
                    Question Distribution <span>(In numbers)</span>
                  </p>
                </div>
                <div className="d-flex   indicators px-5">
                  <div className="d-flex me-5">
                    <span
                      className="dot my-1 me-2"
                      style={{ background: "#158833" }}
                    ></span>
                    <p>Correct Answers</p>
                  </div>
                  <div className="d-flex me-5">
                    <span
                      className="dot my-1 me-2"
                      style={{ background: "#E23F36" }}
                    ></span>
                    <p>Wrong Answers</p>
                  </div>
                  <div className="d-flex ">
                    <span
                      className="dot my-1 me-2"
                      style={{ background: "#707070" }}
                    ></span>
                    <p>Skipped Questions</p>
                  </div>
                </div>
                <div className="px-3 py-2">
                  <DppBarChart
                    color1={"#158833"}
                    color2={"#E23F36"}
                    color3={"#707070"}
                    lable1={"Correct Answers"}
                    lable2={"Wrong Answers"}
                    lable3={"Skipped Answer"}
                    sectionData={[
                      props?.sectionData?.examStats?.correct,
                      props?.sectionData?.examStats?.incorrect,
                      props?.sectionData?.examStats?.skipped,
                    ]}
                    style={{ position: "relative", height: "60vh" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-5 col-lg-12">
          <div className="leader-board">
            <h3>Leaderboard</h3>
            {props?.leaderboard.length > 0 ? (
              <div className="table-responsive">
                <table className="table w-100">
                  <thead>
                    <tr className="border-bottom" style={{ fontSize: "13px" }}>
                      <th>Rank</th>
                      <th>Name</th>
                      <th>Total Marks</th>
                      <th>Attempted</th>
                    </tr>
                  </thead>
                  <tbody className="rank-table" style={{ height: "90px" }}>
                    {props?.leaderboard
                      .slice(0, 51)
                      .map((item: any, index: any) => (
                        <tr
                          style={{
                            fontWeight: item?.isCurrentUser ? "bold" : "normal",
                            backgroundColor: item?.isCurrentUser
                              ? "#f0f0f0"
                              : "transparent",
                          }}
                        >
                          <td className={index == 0 ? "  fw-bold" : " "}>
                            {item?.rank}
                          </td>
                          <td className="d-flex">
                            <div className="">
                              {item?.profilePicture ? (
                                <img
                                  src={item?.profilePicture}
                                  alt=""
                                  className="me-2"
                                />
                              ) : (
                                ""
                                //  <img src={localImages.user} alt="" className="me-2" />
                              )}
                            </div>
                            <div
                              className={
                                index == 0
                                  ? "text-center fw-bold"
                                  : "text-center"
                              }
                            >
                              {item?.firstName} {item?.lastName}
                            </div>
                          </td>
                          <td
                            className={
                              index == 0
                                ? "  text-center fw-bold"
                                : "text-center  "
                            }
                          >
                            {item?.totalMarks}
                          </td>
                          <td
                            className={
                              index == 0
                                ? "  text-center fw-bold"
                                : "text-center  "
                            }
                          >
                            {item?.attemptedQuestion}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div> 
    </div>
  );
}

export default DppAnalysis;
