import React, { useEffect, useState } from 'react';
import localImages from '../Constant/localImagesNew';
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css";
import Faq from '../Component/faq';
import Footer from '../Component/footer';
import { useNavigate } from 'react-router-dom';
import HeaderHome from '../Component/headerHome';
import Sidebar from '../Module/sidebar';
import { useSelector } from 'react-redux';
import { SidebarDataFreeUser, SidebarDataPaidUser } from '../Module/sidebarData';
import BookSession from '../Module/bookSession';
import StudentSayCarousel from '../Component/studentSayCarousel';
import BannerImage from '../Module/bannerImage';
import '../Assets/Css/testPrep.css'
import dataConstant from '../Constant/dataConstant';
import { getCountry, getCourseTypelist, getGeneralExamList, getSubjectList } from '../Redux/Actions/AuthAction';
import { useAppDispatch } from '../hooks';
import DropdownNew from '../Component/dropdownNew';
import { getPackageList } from '../Redux/Actions/examActions';
import SubscriptionPlanTestPrep from '../Module/subscriptionPlanTestPrep';

export default function TestPrep() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const homebannerdiv = React.useRef<HTMLDivElement>(null);
    const [course, setCourse] = useState<any>("");
    const [examList, setExamList] = useState<any>([]);
    const [exam, setExam] = useState<any>([]);
    const [subjectList, setSubjectList] = useState([]);
    const [subject, setSubject] = useState("");
    const [packageData, setpackageData] = useState("");
    
    const {ACCESS_TOKEN,hasActivePaidPlan,userCountryCallingCode} = useSelector((state:any) => ({
        ACCESS_TOKEN: state?.AuthReducer?.ACCESS_TOKEN,
        hasActivePaidPlan: state?.AuthReducer?.hasActivePaidPlan,
        userCountryCallingCode:state?.AuthReducer?.userCountryCallingCode
      }));
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    useEffect(() => {
        dispatch(getCountry(
            (data: any) => {
                const filterCountry=data?.filter((item:any)=>item?.phonecode==userCountryCallingCode?.substring(1))
                const postData = {
                    countryId: filterCountry[0]?._id,
                  };
                dispatch(getCourseTypelist(
                    postData,
                    (res: any) => {                        
                        const filterCourseType=res?.data?.filter((item:any)=>item?.type===1);
                        setCourse(filterCourseType[0]?._id)
                        dispatch(
                            getGeneralExamList(
                              {
                                courseId: filterCourseType[0]._id,
                              },
                              (res: any) => {
                                setExamList(res?.data);
                                setExam(res?.data[0]?._id)
                              },
                              () => {}
                            )
                          );
                       
                    },
                    () => { })
                );
            },
            () => { })
        );
        
    }, [userCountryCallingCode]);
    
    const contactUS = (val:string) => {
        const element = document.getElementById(val);
        if (element) { 
          element.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        }
      };
    const testPrepFeatData=[
        {
            imgPath:localImages?.maths,
            title:"Beyond the textbook",
            desc:"Navigate the complexities of class assignments with ease using Lurnigo's Classwork Help feature. Our dedicated team of educators is ready to assist you, ensuring that you not only understand the material but also excel in your classwork."
        },
        {
            imgPath:localImages?.knowledge,
            title:"Tracking and transparency",
            desc:"Elevate your learning experience with Lurnigo's Advanced Learning feature. Dive deeper into subjects with challenging yet engaging content designed to stimulate critical thinking and foster a love for learning."
        },
        {
            imgPath:localImages?.knowledge,
            title:"Easy to use",
            desc:"Navigate the complexities of class assignments with ease using Lurnigo's Classwork Help feature. Our dedicated team of educators is ready to assist you, ensuring that you not only understand the material but also excel in your classwork."
        },
        {
            imgPath:localImages?.maths,
            title:"The best tutors and expert support",
            desc:"Elevate your learning experience with Lurnigo's Advanced Learning feature. Dive deeper into subjects with challenging yet engaging content designed to stimulate critical thinking and foster a love for learning."
        },
        
    ]
    function goToLink(val:string): void {
        if(val==="BookSession"){
            navigate('/'+val,{ state: { mobNo: "",coutryCodeNo:"",isOTPVerify:ACCESS_TOKEN?true:false } });  
        }
        else{
            navigate('/'+val);
        }
      }

          const getExam=(val:any)=>{
            setExam(val)
            setSubjectList([])
            setpackageData("")
          }
          const onChangeSubject=(val:any)=>{
            setpackageData("")
            setSubject(val)        
          }

          useEffect(() => {
            const postData:any={
              coursetypeId:course,
              subjectId:subject,
              examtypeId:exam
          }
          
          dispatch(
            getPackageList(
              postData,
              (res: any) => {
                if(res?.data.length>0){
                setpackageData(res?.data[0]);
                }
              },
              () => {}
            )
          ) 
          }, [subject]);
    
          useEffect(() => {
            const postData:any={
              coursetypeId:course,
              examtypeId:exam
          }
          
            dispatch(getSubjectList(
              postData,
                (res: any) => {
                  if(res?.data?.length>0){
                    setSubjectList(res?.data)
                   setSubject(res?.data[0]?._id)
                  }
                },
                () => { })
            )  
          }, [exam,course]);

          const gotoSection = (val:string) => {
            const element = document.getElementById(val);
            if (element) { 
              element.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "nearest",
              });
            }
          };
          const onClickExam=(val:any)=>{
            setExam(val?._id)
            setSubjectList([])
            setpackageData("")
            gotoSection("SubScription")
              }
    return (
        <div>
            <HeaderHome isHidden={true} />
            <div className="d-block d-md-none">
            <Sidebar data={hasActivePaidPlan===true?SidebarDataPaidUser:SidebarDataFreeUser}/> 
            </div>
            {/*************Banner************* */}
            <div className='position-relative homeBannerContainer' id='homebanner' ref={homebannerdiv}>
                <div className='row mx-0 '>
                    <div className="col-md-6 homeBannerLeftSide">
                    <div className="homeBannerLeftSide">
                        <h1 className='homeBannerHeading'>Test Prep reinvented</h1>
                        
                        <div className='bannersubtext mt-3'>We Believe in Fostering Growth and Building Trust</div>
                        <div className='mt-5'>
                        <button className='signBtn px-4 font16' onClick={()=>goToLink('BookSession')}>Join Lurnigo Today</button>
                        </div>
                        <div className='bannersubtext mt-3'><img src={localImages?.studentEnroll} alt="studentEnroll" className='me-2'/>10,000+ Students Enrolled</div>
                    </div>
                    </div>
                    <div className="col-md-6 position-relative pe-0">
                        <BannerImage src={localImages.TestPrepBanner}/>
                    </div>
                </div>
            </div>
            <div className='commonPad pb-0 mt-5'>
                <div className='px-md-4'>
                    <h1 className='headingFont width800'>Knowledgeable, supportive admissions experts committed to your success</h1>
                </div>
                <div className='row mt-5 flex-wrap'>
                    {
                        testPrepFeatData?.map((item, index) => (
                            <div className='col-12 col-sm-6 col-md-6 mb-3' key={index}>
                                <div className='p-3 h-100 d-flex align-items-start'>
                                    <img src={item?.imgPath} alt={item?.title} className='me-4' style={{width:"60px"}}/>
                                    <div>
                                    <div className='programHeading fw-bold mb-2 lh-sm'>{item?.title}</div>
                                    <div className='font16 text-Justify'>{item?.desc}</div>
                                    </div>
                                    
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className='commonPad  mt-5'>
                <div className='px-md-4'>
                    <h1 className='headingFont'>Our Tutoring Services</h1>
                </div>
                <div className='flex-wrap mt-5 px-md-4'>
                    {examList?.map((item:any,index:number)=>(
                        <div key={index} className='tutoringServices cursor' onClick={()=>onClickExam(item)}>
                            {item?.name}
                        </div>
                    ))}
                </div>
            </div>

            <div className="mt-4 p-4 p-md-5 shadow" id='SubScription'>
                <h2 className="fw-bold my-2 text-center">Our pricing & subscription plans</h2>
                <div className="text-center mx-auto subscriptionSubHeading mt-3">Elevate your learning experience with Lurnigo's Advanced subjects with challenging engaging content designed to stimulate critical thinking and foster a love for learning.</div>

                <div className="d-flex row justify-content-center mt-4">
                 <div className="col-md-3 mb-2 mb-md-0">
                        <DropdownNew
                            placeholder="Select Exam"
                            options={examList}
                            value={exam}
                            setInputText={(value: any) => getExam(value)}
                        />
                    </div> 
                    <div className="col-md-3 mb-2 mb-md-0">
                        <DropdownNew
                            placeholder="Select Subject"
                            options={subjectList}
                            value={subject}
                            setInputText={(value: any) => onChangeSubject(value)}
                        />
                    </div>

                </div>
                <div className="featureDiv border rounded p-4 mt-5">
                    <SubscriptionPlanTestPrep priceData={packageData} />
                </div>
            </div>
            {/* <div>
            <TextRightImageWithStrip data={PersonalisedLearning}/>
            </div> */}
            
            <div className='commonPad bgLightGrey'>
                <div className='width800 mx-auto my-5'>
                    <h1 className='headingFont text-center'>Don't just take our word for it</h1>
                    <div className='bannersubtext mb-3 text-center'>Hear what our students have to say</div>
                </div>
                <div>
                <StudentSayCarousel Data={dataConstant?.studentSayData} id='carouselStudent' />
                </div>
                <div>
                </div>
            </div>
           
            <div className='commonPad '>
                <div className='row mx-0'>
                    <div className='col-md-3'>
                        <h1 className='headingFont mb-4'>FAQs</h1>
                        <div className='mb-4'>
                            <button className='contactUSbtn px-5'onClick={()=>contactUS('Footer')}>Contact Us</button>
                        </div>
                    </div>
                    <div className='col-md-9'>
                        <Faq faqData={dataConstant?.faqData} id={"HomeFAQ"} />
                    </div>
                </div>
            </div>

            <div className='commonPad '>
                <BookSession clickEvent={()=>goToLink('BookSession')}/>
            </div>
                    {/***************Footer*************** */}
                    <Footer id='Footer'/>
            </div>
            )
}
