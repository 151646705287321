
function DragndropLeft({items}) { 
   
  return (
    <div className="left-side">
    {items.map((item:any, index:number) => (
      <div key={index} className="left-item">
        {item.label}
      </div>
    ))}
  </div>
  );
}

export default DragndropLeft;
