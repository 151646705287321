export default {
  academic: {
    addBookSession: "/v1/academic/add",
    gradeLevelList: "/v1/academic/grade-level",
    findAboutUsList: "/v1/academic/find-about-us",
    registerUser: "/v1/leads/add",
    getRolesList: "/v1/auth/static/role/list",
    getCounsellingExamList: "/v1/auth/static/counsellingExam/list",
    sendOtp: "/v1/users/auth/get-otp",
  },
  exam: {
    getExamList: "v1/auth/static/exam/list",
    getExamDescription: "/v1/description/get-exam-description",
    getCourseExam: "/v1/auth/all/course-exam",
    getCourseTypelist: "/v1/admin/course-type/list",
    getGeneralExamList: "/v1/auth/exam/list",
  },
  auth: {
    sendOtp: "/v1/users/auth/get-otp",
    verifyOtp: "/v1/users/auth/verify-otp",
    register: "v1/users/auth/register-user",
    logout: "/v1/users/auth/logout",
    updateProfile: "/v1/users/auth/edit-userprofile/",
    getUserData: "v1/users/auth/users-list/",
    uploadProfileImage: "/v1/users/auth/upload-picture/",
    getUserPackageList: "/v1/users/auth/users-packagelist/",
    deleteAccount: "/v1/users/auth/delete/",
    getUserDetails: "/v1/users/auth/user-Details/",
    getTimezoneList: "/v1/auth/timezone/list",
    getCountry: "/v1/general/countries",
    gettimezones: "/v1/general/timezones",
    addbookSession: "/v1/academic/add-v2",
    getSubjectList: "/v1/admin/subject/bind-list",
    getTeacher: "/v1/order/teacher-list",
  },
  package: {
    getPackageList: "/v1/package/list",
    getPackageData: "/v1/package/details/",
    getRecomendedData: "v1/package/recomended-list/",
    getSessionData: "/v1/package/pakage-sessions-list/",
    getPackageDetails: "/v1/package/Packagedetails/",
    getRecomendedList: "/v1/package/recomended-Package-list",
    scheduleClass: "/v1/order/schedule-class",
    reScheduleClass: "/v1/live-lecture/reschedule/request",
  },
  live: {
    getLiveLecture: "/v1/live-lecture/get",
    getLiveLectureByID: "/v1/live-lecture/getbyPackageId",
    getLectureDetails: "/v1/live-recorded/lecture-detail/",
    getRecordedLectureDetails: "/v1/live-recorded/getSubjectTopicSubTopic",
    getRecordedforFreeUser: "/v1/live-recorded/getForFreeUsers",
    VideoRating: "/v1/live-recorded/lecture-rating/add",
    getAllExamList: "/v1/auth/static/exam/list",
    getupcomingLect: "/v1/live-lecture/get-upcoming",
    getLiveLecturev2: "/v1/live-lecture/get-v2",
    getRecordedLecture: "/v1/users/dashboard/recorded-video/get",
    getLectureAmount: "/v1/admin/global-setting/lecture/amount",
  },
  cart: {
    addCart: "/v1/cart/add-to-cart",
    getCartList: "/v1/cart/get-cart-list",
    deleteCart: "/v1/cart/delete-cart-package/",
    placeOrder: "/v1/order/place-order",
    updateCart: "/v1/cart/update-cart/",
    getcartCount: "/v1/cart/get-count-Unpurchesed",
    getExtendedCartList: "/v1/cart/get-cart-list",
  },
  payment: {
    paymentRequest: "/v1/users/payment/payment-request",
    paymentExecute: "/v1/users/payment/payment-execute",
    paymentCancel: "/v1/users/payment/payment-cancel",
    getInstallmentData:"/v1/users/dashboard/installments/get"
  },
  order: {
    getOrderHistory: "/v1/orderHistory/order-history/",
    getOrderDetails: "/v1/orderHistory/order-history-details",
  },
  recordedVideo: {
    getRcordedVideos: "/v1/live-recorded/get",
    getRcordedLecture: "/v1/live-recorded/getLive-RecordedLectures",
  },
  notification: {
    getnotificationTypeList: "/v1/auth/static/notificationType/list",
    getnotificationList: "/v1/notification/student-notification",
    getNotificationCount: "/v1/notification/isread/",
  },

  onlineTest: {
    getExam: "/v1/examPattern/getonlineExamData/",
    submitExam: "/v1/examPattern/submitExamData",
    startExam: "/v1/examPattern/createStartOnlineExam",
    getExamResult: "/v1/examPattern/resultOnlineExam/",
    getOnlineTestList: "/v1/test/details",
    getscholorshipTestList: "/v1/test/scholorship-test/list",
  },
  blog: {
    getBlog: "/v1/blogs/list",
  },
  collegeCosulting: {
    addTalkToAdvisor: "/v1/collegeConsulting/add",
    addJoinLurnigo: "/v1/tutorNetwork/add",
  },
  landingPage: {
    getSchoolList: "/v1/general/school-list",
    addLsatLandingData: "/v1/landingpage/LSATlandingpage/add",
    addMarketingLandingData: "/v1/landingpage/Marketinglandingpage/add",
    addDSATlandingpage: "/v1/landingpage/DSATlandingpage/add",
    addAffilatemarketingPage:"/v1/landingpage/AffiliateMarketingLeads/add"
  },
  demoSession: {
    getDemoSessionData: "/v1/users/dashboard/demo-schedule/get",
    addSessionFeedback: "/v1/users/dashboard/demo-feedback/submit",
    getSessionFeedback: "/v1/admin/demo/user-feedback",
  },
  couponCode: {
    applyCoupon: "/v1/order/apply-coupon",
  },
  AcademicData: {
    getAcademicDataByTopicId: "/v1/admin/teacher/academic-data",
    getAcademicDataLecturewise: "/v1/admin/teacher/get-IndexWise-Data",
    getAcademicAnalysis: "/v1/admin/teacher/academic-analysis",
    getUsersAcademicData: "/v1/live-recorded/get-Users-AcademicData",
    addAcademicData: "/v1/admin/teacher/academicData-add",
    getCompleteAcademicData: "/v1/admin/teacher/academicData-get",
    addLiveFeedback: "/v1/users/dashboard/live-feedback/submit",
    getLiveFeedback: "/v1/users/dashboard/get-User-liveFeedback",
    addSubmitDescriptiveQuestion:"/v1/description/submit-Descriptive-questionData"
  },
};
