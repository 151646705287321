import React, { useEffect, useState } from "react";
import { showErrorSnackbar } from "../Redux/Actions/snackbarAction";
import { useAppDispatch } from "../hooks";
import { useSelector } from "react-redux";
import {
  addMarketingLandingData,
  getCountry,
  getCourseTypelist,
  getGeneralExamList,
  getSubjectList,
  gradeLevelList,
} from "../Redux/Actions/AuthAction";
import { validateFullName, validateIsNumberOnly } from "../Utils/validators";
import {
  validateFullNameErrMsg,
  validateIsNumberOnlyErrMsg,
  validateLastNameErrMsg,
} from "../Utils/errormsg";
import InputField from "../Atom/InputField";
import PhoneInputField from "../Atom/phoneInputField";
import Dropdown from "./dropdown";
import DropdownCustom from "./dropdownCustom";

export default function LearnLandingForm(props?: any) {
  const dispatch = useAppDispatch();
  const [firstName, setfirstname] = useState<string>("");
  const [lastName, setlastName] = useState<string>("");
  const [email, setemail] = useState("");
  const [mob, setmob] = useState<string>("");
  const [countryData, setCountryData] = useState([]);
  const [coutryCode, setcoutryCode] = useState<string>("1");
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [subject, setSubject] = useState<string>("");
  const [subjectList, setSubjectList] = useState([]);
  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [grade, setGrade] = useState<string>("");
  const [exam, setExam] = useState<string>("");
  const [examList, setexamList] = useState([]);

  const { userCountryCallingCode } = useSelector((state: any) => ({
    userCountryCallingCode: state?.AuthReducer?.userCountryCallingCode,
  }));

  useEffect(() => {
    dispatch(
      gradeLevelList(
        (data: any) => {
          const filterGrade = data?.filter((item: any) => item?._id >= 4);
          setGradeList(filterGrade);
        },
        () => {}
      )
    );
  }, []);

  useEffect(() => {
    dispatch(
      getCountry(
        (data: any) => {
          // setCountryData(data)
          // const filterCountry=data?.filter((item:any)=>item?.phonecode==userCountryCallingCode?.substring(1))
          const filterCountry = data?.filter(
            (item: any) => item?.phonecode == 1
          );
          setCountryData(filterCountry);
          const postData = {
            countryId: filterCountry[0]?._id,
          };

          dispatch(
            getCourseTypelist(
              postData,
              (res: any) => {
                setCourseList(res?.data);
              },
              () => {}
            )
          );
        },
        () => {}
      )
    );
  }, [userCountryCallingCode]);
  const closePopup = () => {
    setIsSubmit(false);
    clearData();
  };

  const handleFirstInputText = (e: any) => {
    if (validateFullName(e)) setfirstname(e);
    else {
      dispatch(showErrorSnackbar(validateFullNameErrMsg));
    }
  };
  const handleLastInputText = (e: any) => {
    if (validateFullName(e)) setlastName(e);
    else {
      dispatch(showErrorSnackbar(validateLastNameErrMsg));
    }
  };
  const setPhoneNumber = (e: any) => {
    if (!validateIsNumberOnly(e)) {
      setmob(e);
    } else {
      dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
    }
  };

  function submitForm(e: { preventDefault: () => void }) {
    e.preventDefault();
    const PostData: any = {
      firstName,
      mobile: mob,
      countryCode: "+" + coutryCode,
      coursetypeId: course?._id,
      sourceId: 4,
      email: email,
      gradeId: grade,
    };
    if (course?.type === 2) {
      PostData["subjectId"] = subject;
    }
    if (course?.type === 1) {
      PostData["examtypeId"] = exam;
      PostData["subjectId"] = subject;
    }
    console.log("PostData", PostData);
    dispatch(
      addMarketingLandingData(
        PostData,
        (res: any) => {
          setIsSubmit(true);
          clearData();
          props?.handleClose();
        },
        () => {}
      )
    );
    // return (true)
  }

  function clearData() {
    setfirstname("");
    setemail("");
    setmob("");
    setSubject("");
    setExam("");
    setCourse("");
    setexamList([]);
    setSubjectList([]);
  }

  function validation() {
    return (
      firstName === "" ||
      email === "" ||
      mob?.length < 10 ||
      (course?.type !== 3 && subject === "")
    );
  }
  const onChangeCoutryCode = (e: any) => {
    setcoutryCode(e.target.value);
  };
  const changeCategory = (val: any) => {
    console.log("changeCategory", val);
    setCourse(val);
    if (val?.type === 1) {
      dispatch(
        getGeneralExamList(
          {
            courseId: val?._id,
          },
          (res: any) => {
            setexamList(res?.data);
          },
          () => {}
        )
      );
    } else if (val?.type === 2) {
      const postData = {
        coursetypeId: val?._id,
        gradeId: grade,
      };
      dispatch(
        getSubjectList(
          postData,
          (res: any) => {
            setSubjectList(res?.data);
          },
          () => {}
        )
      );
    } else {
    }
  };
  const changeSubject = (val: any) => {
    setSubject(val);
  };

  const onChangeExam = (val: any) => {
    setExam(val);
    const postData = {
      coursetypeId: course?._id,
      examtypeId: val,
    };
    dispatch(
      getSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res?.data);
        },
        () => {}
      )
    );
  };
  return (
    <div className="row mx-0">
      <form className="w-100">
        <div className="row mx-0">
          <div className="my-2 col-md-6">
            <label className="mb-2 colorgray707070">Parents Name</label>
            <div className="d-flex">
              <InputField
                placeholder="Enter First Name"
                value={firstName}
                setValue={handleFirstInputText}
                maxLength={100}
              />
              <span className="mendatory ms-2 fs-5">*</span>
            </div>
          </div>
          <div className="my-2 col-md-6">
            <label className="mb-2 colorgray707070">Phone Number</label>
            <div className="d-flex position-relative">
              <select
                className="form-select cursor coutryCode  rounded-0 rounded-start"
                aria-label="Default select example"
                defaultValue={1}
                value={coutryCode}
                onChange={onChangeCoutryCode}
              >
                {countryData?.map((item: any, index) => {
                  return (
                    <option value={item.phonecode}>
                      {item?.isoCode} +{item?.phonecode}
                    </option>
                  );
                })}
              </select>
              <PhoneInputField
                placeholder="Enter Mobile Number"
                value={mob}
                maxLength={"10"}
                setValue={setPhoneNumber}
              />
              <span className="mendatory ms-2 fs-5">*</span>
            </div>
          </div>
          <div className="my-2 col-md-6">
            <label className="mb-2 colorgray707070">Email</label>
            <div className="d-flex">
              <input
                className="form-control p-2"
                type="email"
                value={email}
                placeholder="Enter Your Email Address"
                onChange={(e) => setemail(e.target.value)}
              />
              <span className="mendatory ms-2 fs-5">*</span>
            </div>
          </div>
          <div className="my-2 col-md-6">
            <label className="mb-2 colorgray707070">Student Grade</label>
            <div className="d-flex">
              <Dropdown
                placeholder="Select Grade"
                setInputText={(value: any) => setGrade(value)}
                value={grade}
                options={gradeList}
              />
              <span className="mendatory ms-2 fs-5">*</span>
            </div>
          </div>
          <div className="my-2 col-md-6">
            <label className="mb-2 colorgray707070">Category</label>
            <div className="d-flex">
              <DropdownCustom
                placeholder="Select Category"
                setInputText={(value: any) => changeCategory(value)}
                value={course}
                options={courseList}
              />
              <span className="mendatory ms-2 fs-5">*</span>
            </div>
          </div>
          {course?.type === 1 ? (
            <div className="my-2 col-md-6">
              <label className="mb-2 colorgray707070">Exam</label>
              <div className="d-flex">
                <Dropdown
                  placeholder="Select Exam"
                  setInputText={(value: any) => onChangeExam(value)}
                  value={exam}
                  options={examList}
                />
                <span className="mendatory ms-2 fs-5">*</span>
              </div>
            </div>
          ) : null}
          {course?.type !== 3 ? (
            <div className="my-2 col-md-6">
              <label className="mb-2 colorgray707070">Subject</label>
              <div className="d-flex">
                <Dropdown
                  placeholder="Select Your Subject"
                  setInputText={(value: any) => changeSubject(value)}
                  value={subject}
                  options={subjectList}
                />
                <span className="mendatory ms-2 fs-5">*</span>
              </div>
            </div>
          ) : null}
        </div>
      </form>
      <div className="p-2 px-4 border-top my-3">
        <button
          onClick={submitForm}
          className={`bookSessionButton w-100 rounded font16 mt-2 ${
            validation() ? "bg-secondary" : ""
          }`}
          disabled={validation()}
        >
          Book Your Trial Session
        </button>
      </div>
    </div>
  );
}
