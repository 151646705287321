import { useEffect, useState } from 'react';
import '../App.css';
import '../Assets/Css/cart.css';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import RecommendedCard from '../Module/recommendedCard';
import CartCard from '../Module/cartCard';
import Header from '../Component/header';
import BuyNowCard from '../Module/buyNowCard';
import { addCart, deleteCart, getCartList, getRecomendedData, getRecomendedList, getSessionData, updateCart } from '../Redux/Actions/examActions';
import SessionPopup from '../Module/sessionPopup';
import HeaderHome from '../Component/headerHome';
import Lottie from 'react-lottie';
import localImages from '../Constant/localImagesNew';
import { getCountry } from '../Redux/Actions/AuthAction';
import { useAppDispatch } from '../hooks';
import { useSelector } from 'react-redux';
function MyCart() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [cartList, setCartList] = useState<any>([])
  const [totalPrice, settotalPrice] = useState("");
  const [recommendedID, setrecommendedID] = useState("");
  const [RecomendedData, setRecomendedData] = useState([]);
  const [sessionData, setsessionData] = useState<any>();
  const [openSession, setopenSession] = useState<boolean>(false)
  const [SelectedSession, setSelectedSession] = useState<string | number>();
  const [PackageID, setPackageID] = useState("");
  const [updatesession, setupdatesession] = useState<boolean>(false)
  const [country, setCountry] = useState("");
  const { userCountryCurrency, userCountryCallingCode } = useSelector((state: any) => ({
    userCountryCurrency: state?.AuthReducer?.userCountryCurrency,
    userCountryCallingCode: state?.AuthReducer?.userCountryCallingCode
  }));
  useEffect(() => {
    dispatch(getCountry(
      (data: any) => {
        const filterCountry = data?.filter((item: any) => item?.phonecode == userCountryCallingCode?.substring(1))
        setCountry(filterCountry[0]?._id)
      },
      () => { })
    );

  }, [userCountryCallingCode]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: localImages?.cart_empty,
    renderer: 'svg'
  };

  useEffect(() => {
    if(country){
      getCart();
    }
  }, [country]);
  const getPackageId = (cartList: any) => {
    const packageIds = cartList?.map((item:any) => ({
      packageId: item?.packageId?._id,
      levelId: item?.levelId?._id
    }));
    return {packages:packageIds}
  }
  useEffect(() => {
    if (cartList.length > 0) {
      
      dispatch(
        getRecomendedList(
          getPackageId(cartList)
          ,
          (res: any) => {
            setRecomendedData(res?.data)
          },
          () => { }
        )
      );
    }
  }, [cartList]);
  

  function getCart() {
    setCartList([]);
    settotalPrice("0")
    dispatch(
      getCartList(
        {countryId:country},
        (res: any) => {
          setCartList(res?.cart);
          settotalPrice(res?.netAmount)
        },
        () => { }
      )
    );
  }
  const removeCart = (id: any) => {
    dispatch(
      deleteCart(
        id,
        (res: any) => {
          getCart()
        },
        () => { }
      )
    );
  }

  const handleCheckout = () => {
    navigate('/Address');
  };

  const handleAddMore = () => {
    navigate('/User/demoDashboard');
  };

  function addCourse(val: any) {
    setrecommendedID(val?._id)
    dispatch(
      getSessionData(
        val?._id,
        (res: any) => {
          if (res && res?.length != 0) {
            setsessionData(res);
            // setupdatesession(true)
            setopenSession(true)
          }
          else {
            addToCart(val?.packageId,val?._id)
          }
        },
        () => { }
      )
    );
    // if(val?.sessions && val?.sessions?.length!=0){
    //   setsessionData(val?.sessions)
    //   setopenSession(true)
    // }
    // else{
    //   addToCart()
    // }
  }

  const addToCart = (id: any,packageOptionId:any) => {
    let PoseData = {};
    if (updatesession && SelectedSession) {
      PoseData = {
        sessionId: SelectedSession
      }
      dispatch(
        updateCart(
          PoseData,
          PackageID,
          (res: any) => {
            setopenSession(false)
            setupdatesession(false)
            setSelectedSession("")
            setsessionData([])
            getCart()
          },
          () => { }
        )
      );
    }
    else {
      if (SelectedSession) {
        PoseData = {
          packages: [{
            packageId: recommendedID,
            sessionId: SelectedSession,
            packageOptionId:packageOptionId
          }]
        }
      }
      else {
        PoseData = {
          packages: [{
            packageId: id,
            packageOptionId:packageOptionId
          }]
        }
      }
      dispatch(
        addCart(
          PoseData,
          (res: any) => {
            setopenSession(false)
            setSelectedSession("")
            setsessionData([])
            getCart()
          },
          (error: any) => {
          }
        )
      );
    }
  }

  const closeSession = () => {
    setopenSession(false)
    setSelectedSession("")
    setsessionData([])
    setupdatesession(false)
  }
  const handleChange = (e: any) => {
    setSelectedSession(e.target.value)
  }

  return (
    <div>
      {/* <Header isHidden={false} /> */}
      <HeaderHome isHidden={false} />
      <div className="container ContainerDiv pt-4">
        <h3 className="fw-bold mb-5 ">My Cart</h3>
        {/* {cartListDetails?.length > 0 ? ( */}
        {cartList?.length>0?(
          <div className="row">
          <div className="col-md-6 ">
            <p className="font-12 fw-bold bgwhite">Recommended Add-on</p>
            <div className='checkoutHeight p-2'>
              {RecomendedData?.map((item, index) => (
                <RecommendedCard data={item} addCart={addCourse} />
              ))}

            </div>
          </div>
          <div className="col-md-6  order-1 order-md-2 mb-3">
            <div className="mb-3 checkoutHeight p-md-3">
              {cartList?.map((item: any, index: any) => (
                <CartCard data={item} removeCart={removeCart} setsessionData={setsessionData} setopenSession={setopenSession} setupdatesession={setupdatesession} setPackageID={setPackageID} />
              ))}

              <div className="d-flex justify-content-end mt-3">
                <button
                  className="btnActive rounded px-3 font12"
                  onClick={handleAddMore}>
                  Add More
                </button>
              </div>

            </div>
            <BuyNowCard btnname="Checkout" setOnClick={handleCheckout} amount={totalPrice} count={cartList?.length} />
          </div>
        </div>
        )
      :
      <div className="boxshadow rounded customcontainer bgwhite w-78 mt-2">
                <div className="p-5  py-3 text-center">
                  <Lottie
                    options={defaultOptions}
                    height={130}
                    width={200}
                    style={{ cursor: 'default' }}></Lottie>
                  <p className="text-center font15">
                    <b>Your Cart bag is empty</b>
                  </p>
                  <p className="text-center font12 mb-0">
                    Explore our ever-growing selection of courses{' '}
                  </p>
                  <p className="text-center font12 ">and exciting new offers</p>
                  <button type="button" className="btn btn-primary" onClick={handleAddMore}>
                    Browse Courses
                  </button>
                </div>

                {/* <div className="tab-content" id="pills-tabContent">
                  <div className="row ms-md-5">
                    {recentView.length > 0 ? (
                      <p className="font-12 fw-bold mt-5 colorblue">Recently Viewed</p>
                    ) : (
                      ''
                    )}
                    {recentView &&
                      recentView?.map((c, index) => (
                        <div
                          key={index.toString()}
                          className="col-sm-5 col-lg-4 col-xl-3 mx-2 mx-lg-3 mb-3">
                          <CourseCardNew data={c} />
                        </div>
                      ))}
                  </div>
                </div> */}
              </div>}
        
      </div>
      <SessionPopup open={openSession} handleClose={closeSession} sessionData={sessionData} handleChange={handleChange} addToCart={addToCart} SelectedSession={SelectedSession} />
    </div>
  );
}

export default MyCart;
