import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Interface } from "readline";

interface props {
  value?: string,
  setInputText: Function,
  defaultValue?: string,
  maxdate?: any,
  mindate?: any,
  Isrequired?: boolean,
  disabled?: boolean
}

export default function DatePicker({ value, setInputText, defaultValue, maxdate, mindate, Isrequired,disabled }: props) {

  return (
    <div className="d-flex mb-2 align-items-center custommargin">
      <input
        className="form-control"
        type="date"
        value={value}
        onChange={(e) => setInputText(e.target.value)}
        defaultValue={defaultValue?.substring(0, 10)}
        max={maxdate}
        min={mindate}
        disabled={disabled}
        pattern=" d{4}-\d{2}-\d{2}"
      />
      {Isrequired ? <span className="required ms-2 fs-6">*</span> : null}
    </div>
  );
}