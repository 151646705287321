import { Key, useEffect } from "react";
import { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import "../Assets/Css/onlineTestExam.css";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import LocalImages from "../Constant/localImages";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getQuestionDescription,
  getQuestionType,
  getStatsFromQuestionData,
  getTimeTakenToStringFormat,
} from "../Utils/utils";
import { secondsMinute } from "../Utils/utils";
import parser from "html-react-parser";
import { getExamResult } from "../Redux/Actions/examActions";
import { useAppDispatch } from "../hooks";
import ResultSidebar from "../Component/resultSidebar";
import MenuIcon from "@mui/icons-material/Menu";
import DppAnalysis from "../Component/dppAnalysis";
import SectionReport from "../Component/sectionReport";
function DetailResult() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [key, setKey] = useState<any>("one");
  const location = useLocation();
  const [examData, setExamData] = useState<any[]>([]);
  const [sectionData, setSectionData] = useState<any>([]);
  const [leaderboard, setLeaderboard] = useState<any>([]);
  const [menu, showMenu] = useState(false);
  const pageName = location?.state?.pageName;
  const examSetId = location?.state?.examSetId;

  console.log("examSetId", examSetId);
  useEffect(() => {
    dispatch(
      getExamResult(
        examSetId,
        (data: any) => {
          console.log("examSetId", data);
          setLeaderboard(data?.Leaderboard);
          setSectionData(data?.resultData[0]);
        },
        () => {}
      )
    );
  }, [examSetId]);

  useEffect(() => {
    console.log("examSetId12", examSetId);
  });
  console.log("sectionData", sectionData);

  // const getQuestionData = (examData: { questionData: any }[]) => {
  //   const questionData = examData[0]?.questionData;
  //   return questionData.map(
  //     (item: { questionNumber: any }, index: number) =>
  //       (item.questionNumber = index + 1)
  //   ); // added current question number
  // };
  function toggleMenu(): void {
    showMenu(!menu);
    const element = document.getElementById("sidebarMenu") as HTMLInputElement;
    if (menu) {
      element.classList.add("active");
    } else {
      element.classList.remove("active");
    }
  }

  function handleRedirect() {
    localStorage.setItem("startTest", "2");
    sessionStorage.removeItem("examId");
    sessionStorage.removeItem("examData");
    window.opener.location.reload();
    window.close();
  }

  // const getSectionMark = (sectionId: any) => {
  //   let questionsData = examData?.length > 0 && examData[0]?.questionData;
  //   let markArr = questionsData
  //     .map(
  //       (item: {
  //         section: { sectionId: any };
  //         ansStatus: number;
  //         ansType: number;
  //         correctMarks: any;
  //         negativeMarks: any;
  //       }) =>
  //         item.section?.sectionId == sectionId &&
  //         item.ansStatus == 2 &&
  //         (item.ansType == 1 || item.ansType == 4)
  //           ? {
  //               ansStatus: 2,
  //               marks: item.correctMarks,
  //               neg: item.negativeMarks,
  //               sectionId: item.section?.sectionId,
  //             }
  //           : item.section?.sectionId == sectionId &&
  //             item.ansStatus == 1 &&
  //             (item.ansType == 1 || item.ansType == 4)
  //           ? {
  //               ansStatus: 1,
  //               marks: item.correctMarks,
  //               neg: item.negativeMarks,
  //               sectionId: item.section?.sectionId,
  //             }
  //           : null
  //     )
  //     .filter((im: null) => im != null);

  //   const tempArrMark: any = {};
  //   for (const itm of markArr) {
  //     tempArrMark[itm.marks] = markArr.filter(
  //       (obj: { marks: any }) => obj?.marks == itm?.marks
  //     );
  //   }

  //   return tempArrMark;
  // };
  // const countCorrectQuestionsSectionWiseMarks = (
  //   itemArr: any[] | undefined
  // ) => {
  //   return itemArr != undefined
  //     ? itemArr?.reduce(function (val, obj) {
  //         return val + (obj.ansStatus == 1 ? 1 : 0);
  //       }, 0)
  //     : 0;
  // };

  // const countIncorrectQuestionsSectionWiseMarks = (
  //   itemArr: any[] | undefined
  // ) => {
  //   return itemArr != undefined
  //     ? itemArr?.reduce(function (val, obj) {
  //         return val + (obj.ansStatus == 2 ? 1 : 0);
  //       }, 0)
  //     : 0;
  // };

  // const getAnswerDescription = (question: any) => {
  //   const ansDescription = question.examAnsDesc;
  //   return (
  //     <div className="text-start">
  //       {ansDescription?.image === "" &&
  //       ansDescription?.text === "" &&
  //       ansDescription?.video === "" ? null : (
  //         <>
  //           {ansDescription?.image ? (
  //             <div>
  //               <img
  //                 className="img-fluid qusimgdesc"
  //                 src={ansDescription?.image}
  //                 alt={"Anwer"}
  //               />
  //             </div>
  //           ) : null}
  //           {ansDescription?.text ? parser(ansDescription?.text) : null}
  //           {ansDescription?.video ? (
  //             <>
  //               {/* <video
  //             muted
  //             autoplay loop preload="auto"
  //             className="videotag m-auto"
  //             width="320"
  //             height="240"
  //             controls>
  //             <source src={ansDescription?.video} type="video/mp4" />
  //           </video> */}
  //               <video
  //                 className="videotag m-auto"
  //                 width="320"
  //                 height="240"
  //                 controls
  //               >
  //                 <source src={ansDescription?.video} type="video/mp4" />
  //                 Your browser does not support the video tag.
  //               </video>
  //             </>
  //           ) : null}
  //         </>
  //       )}
  //     </div>
  //   );
  // };

  window.addEventListener("beforeunload", function (e) {
    e.preventDefault();
    e.returnValue = "";
    window.location.reload();
    window.close();
  });

  return (
    
    <>
      <div
        className=""
        style={{ position: "sticky", top: "0", width: "100%", zIndex: "99999" }}
      >
        <header className="d-flex align-items-center justify-content-between position-sticky">
          <div className="d-flex align-items-center">
            <div className="cursor" onClick={() => navigate(`../`)}>
              <img
                src={LocalImages.logo}
                alt="IFAS Logo"
                className="logo"
                style={{ width: "120px" }}
              />
            </div>
            {/* <h5 className="mb-0 ms-3">{examData?.en?.name}</h5> */}
          </div>
          <div>
            <button
              type="button"
              className="btn btn-primary py-2 px-5 font12 me-md-1"
              onClick={handleRedirect}
            >
              Close
            </button>
          </div>
          <div
            className="d-xs-flex d-sm-flex d-md-none me-2 cursor"
            onClick={toggleMenu}
          >
            <MenuIcon />
          </div>
        </header>
      </div>
      <div className="detailsResul">
        <div className="d-flex p-4">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <div className="analysisSidebar" id="sidebarMenu">
              <ResultSidebar sectionsData={sectionData} onClick={toggleMenu} />
            </div>
            <div className="ExamrightPanel">
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <DppAnalysis
                    sectionData={sectionData}
                    leaderboard={leaderboard}
                  />
                </Tab.Pane>
                  {sectionData?.examData?.map((items:any, index:any) => {
                console.log("items",items)
                  return (
                    <Tab.Pane eventKey={index} key={index}>
                       <SectionReport
                        // examName={examData?.en?.name}
                        // startedOn={examData?.startedOn}
                        // examId={examData?._id}
                        // examTypeId={examData?.examTypeId}
                        // baseCourseId={examData?.baseCourseId}
                        // courseId={examData?.courseId}
                        examsStats={sectionData}
                        accuracy={sectionData?.examStats?.accuracy}
                        attempted={sectionData?.examStats?.attempted}
                        correct={sectionData?.examStats?.correct}
                        incorrect={sectionData?.examStats?.incorrect}
                        skipped={sectionData?.examStats?.skipped}
                        questionsData={items?.questionData}
                      />  
                    </Tab.Pane>
                  );
                })} 
              </Tab.Content>
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  );
}

export default DetailResult;
