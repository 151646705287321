import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import localImages from "../Constant/localImages";
import InputField from "../Atom/InputField";
import Dropdown from "./dropdown";
import {
  addDSATlandingpage,
  getCountry,
  gradeLevelList,
} from "../Redux/Actions/AuthAction";
import { useAppDispatch } from "../hooks";
import { showErrorSnackbar } from "../Redux/Actions/snackbarAction";
import {
  validateFullNameErrMsg,
  validateIsNumberOnlyErrMsg,
  validateLastNameErrMsg,
} from "../Utils/errormsg";
import { validateFullName, validateIsNumberOnly } from "../Utils/validators";
import PhoneInputField from "../Atom/phoneInputField";

interface props {
  open: boolean;
  handleClose: Function;
  countryCode: string;
  mob: string;
}
export default function DsatPopup({
  open,
  handleClose,
  mob,
  countryCode,
}: props) {
  const dispatch = useAppDispatch();
  const [firstName1, setfirstname1] = useState<string>("");
  const [lastName1, setlastName1] = useState<string>("");
  const [email1, setemail1] = useState<string>("");
  console.log("mob", mob);
  const [mob1, setmob1] = useState<string>(mob ? mob : "");
  const [countryData1, setCountryData1] = useState([]);
  const [coutryCode1, setcoutryCode1] = useState<string>(
    countryCode ? countryCode : "1"
  );
  const [gradeList1, setGradeList1] = useState([]);
  const [grade1, setGrade1] = useState<any>("");
  function closePopup(
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ): void {
    handleClose();
  }
  useEffect(() => {
    dispatch(
      getCountry(
        (data: any) => {
          setCountryData1(data);
        },
        () => {}
      )
    );
    dispatch(
      gradeLevelList(
        (data: any) => {
          const filterGrade = data?.filter((item: any) => item?._id >= 4);
          setGradeList1(filterGrade);
        },
        () => {}
      )
    );
  }, []);

  const handleFirstInputText1 = (e: any) => {
    if (validateFullName(e)) setfirstname1(e);
    else {
      dispatch(showErrorSnackbar(validateFullNameErrMsg));
    }
  };
  const handleLastInputText1 = (e: any) => {
    if (validateFullName(e)) setlastName1(e);
    else {
      dispatch(showErrorSnackbar(validateLastNameErrMsg));
    }
  };
  const setPhoneNumber1 = (e: any) => {
    if (!validateIsNumberOnly(e)) {
      setmob1(e);
    } else {
      dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
    }
  };
  const onChangeCoutryCode1 = (e: any) => {
    setcoutryCode1(e.target.value);
  };
  const handleSubmitDSAT = (e: any) => {
    e.preventDefault();
    const postData = {
      firstName: firstName1,
      lastName: lastName1,
      email: email1,
      mobile: mob1,
      countryCode: "+1",
      countryId: "226",
      gradeId: grade1,
      coursetypeId: 14,
      examtypeId: 71,
      sourceId: 2,
      statusId: 1,
    };
    console.log("postData", postData);
    dispatch(
      addDSATlandingpage(
        postData,
        (res: any) => {
          setfirstname1("");
          setlastName1("");
          setemail1("");
          setmob1("");
          setGrade1("");
          handleClose();
        },
        () => {}
      )
    );
    // setOpenDemoSession(true);
  };
  const getGrade = (val: any) => {
    setGrade1(val);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="model"
      open={open}
      onClose={closePopup}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="modeldiv p-0 position-relative width60">
          <div className="commonPad bookfreeSession">
            <div className="col mb-2 d-flex justify-content-end ">
              <ClearOutlinedIcon
                className="colorblue cursor font28"
                onClick={closePopup}
              />
            </div>
            <div className="mt-4">
              <form className="w-100" onSubmit={handleSubmitDSAT}>
                <div className="row mx-0">
                  <div className="my-2 col-md-6">
                    <label className="mb-2">
                      Parents First Name <span className="  ">*</span>
                    </label>
                    <div className="d-flex ">
                      <InputField
                        placeholder="Enter Parents Name"
                        value={firstName1}
                        setValue={handleFirstInputText1}
                      />
                    </div>
                  </div>
                  <div className="my-2 col-md-6">
                    <label className="mb-2">
                      Parents Last Name <span className="  ">*</span>
                    </label>
                    <div className="d-flex ">
                      <InputField
                        placeholder="Enter Parents Name"
                        value={lastName1}
                        setValue={handleLastInputText1}
                      />
                    </div>
                  </div>
                  <div className="my-2  col-md-6">
                    <label className="mb-2">
                      Phone Number <span>*</span>
                    </label>
                    <div className="d-flex position-relative">
                      <select
                        className="form-select cursor coutryCode  rounded-0 rounded-start"
                        aria-label="Default select example"
                        defaultValue={1}
                        value={coutryCode1}
                        onChange={onChangeCoutryCode1}
                      >
                        {countryData1?.map((item: any, index) => {
                          return (
                            <option value={item.phonecode}>
                              {item?.isoCode} +{item?.phonecode}
                            </option>
                          );
                        })}
                      </select>
                      <PhoneInputField
                        placeholder="Enter Your Mobile Number"
                        value={mob1}
                        maxLength={"10"}
                        setValue={setPhoneNumber1}
                      />
                    </div>
                  </div>
                  <div className="my-2 col-md-3 ">
                    <label className="mb-2">
                      Email <span>*</span>
                    </label>
                    <div className="d-flex">
                      <input
                        className="form-control"
                        type="email"
                        value={email1}
                        placeholder="Enter Email Address"
                        onChange={(e) => setemail1(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="my-2 col-md-3 ">
                    <label className="mb-2">
                      Student Grade <span>*</span>
                    </label>
                    <div className="  ">
                      <Dropdown
                        placeholder="Select Grade"
                        options={gradeList1}
                        value={grade1}
                        setInputText={(value: any) => getGrade(value)}
                      />
                    </div>
                  </div>
                  <div className="mb-5 mt-3 text-center px-md-5">
                    <button className="bookSessionButton rounded fw-bold px-md-5">
                      Book Your Trial Session
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
