import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import localImages from "../Constant/localImagesNew";
import InputField from "../Atom/InputField";
import Dropdown from "./dropdown";
import {
  addDSATlandingpage,
  getCountry,
  gradeLevelList,
} from "../Redux/Actions/AuthAction";
import { useAppDispatch } from "../hooks";
import { showErrorSnackbar } from "../Redux/Actions/snackbarAction";
import {
  validateFullNameErrMsg,
  validateIsNumberOnlyErrMsg,
  validateLastNameErrMsg,
} from "../Utils/errormsg";
import { validateFullName, validateIsNumberOnly } from "../Utils/validators";
import PhoneInputField from "../Atom/phoneInputField";
import LearnLandingForm from "./learnLandingForm";

interface props {
  open: boolean;
  handleClose: Function;
}
export default function LearnLandingPopup2({
  open,
  handleClose,
}: props) {
  const dispatch = useAppDispatch();
  function closePopup(
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ): void {
    handleClose();
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="model"
      open={open}
      onClose={closePopup}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="modeldiv p-0 position-relative width750 rounded-4">
          <div className="row mx-0">
            <div className="bgwhite rounded overflow-hidden px-0">
              <div className="bookfreeSession">
                <div className="row mx-0 border-bottom p-3">
                  <div className="col-10 font20 fw-bold">
                  Take Your First Step Towards <span className="colorOrange">Academic Success !</span>
                  </div>
                <div className="col-2 mb-2 d-flex justify-content-end ">
                  <ClearOutlinedIcon
                    className="colorblue cursor font28"
                    onClick={closePopup}
                  />
                </div>
                </div>
                
                <div className="mt-4">
                  <LearnLandingForm handleClose={handleClose}/>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </Fade>
    </Modal>
  );
}
