// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.left-side, .right-side {
  width: 200px;
  padding: 10px;
  border: 1px solid #ccc;
}

.left-item, .right-item {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #000;
  background-color: #f9f9f9;
}

.drop-area {
  margin-bottom: 10px;
  padding: 10px;
  border: 2px dashed #ccc;
  text-align: center;
}

.hovered {
  background-color: #e0e0e0;
}
.right-container{
  width: 200px;
  padding: 10px;
}


/******************match The PAir with Line***********************/
.left, .right {
  display: inline-block;
  width: 200px;
  vertical-align: top;
}

.left div, .right div {
  margin: 20px;
  padding: 10px;
  border: 1px solid #000;
  cursor: pointer;
}

.lineCanvascontainer{
 position: absolute;
 z-index: -1;
}
`, "",{"version":3,"sources":["webpack://./src/Assets/Css/matchThePair.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,YAAY;EACZ,aAAa;AACf;;;AAGA,kEAAkE;AAClE;EACE,qBAAqB;EACrB,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,eAAe;AACjB;;AAEA;CACC,kBAAkB;CAClB,WAAW;AACZ","sourcesContent":[".left-side, .right-side {\n  width: 200px;\n  padding: 10px;\n  border: 1px solid #ccc;\n}\n\n.left-item, .right-item {\n  margin-bottom: 10px;\n  padding: 10px;\n  border: 1px solid #000;\n  background-color: #f9f9f9;\n}\n\n.drop-area {\n  margin-bottom: 10px;\n  padding: 10px;\n  border: 2px dashed #ccc;\n  text-align: center;\n}\n\n.hovered {\n  background-color: #e0e0e0;\n}\n.right-container{\n  width: 200px;\n  padding: 10px;\n}\n\n\n/******************match The PAir with Line***********************/\n.left, .right {\n  display: inline-block;\n  width: 200px;\n  vertical-align: top;\n}\n\n.left div, .right div {\n  margin: 20px;\n  padding: 10px;\n  border: 1px solid #000;\n  cursor: pointer;\n}\n\n.lineCanvascontainer{\n position: absolute;\n z-index: -1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
