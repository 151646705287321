import React from 'react';
import { useDrag } from 'react-dnd';

const RightItem = ({ item, index }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'ITEM',
    item: { id: item.id,label:item?.label },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <div ref={drag} className="right-item" style={{ opacity: isDragging ? 0.5 : 1 }}>
      {item.label}
    </div>
  );
};

const DragndropRight = ({ items }) => {
  return (
    <div className="right-side">
      {items.map((item:any, index:number) => (
        <RightItem index={index} item={item} />
      ))}
    </div>
  );
};

export default DragndropRight;
