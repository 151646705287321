import DrawIcon from "@mui/icons-material/Draw";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import CreateIcon from "@mui/icons-material/Create";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  ReactSketchCanvas,
  type ReactSketchCanvasRef,
} from "react-sketch-canvas";
interface Props {
  toggle: boolean;
  setToggle: Function;
}

function LectureCanvasBox({ toggle, setToggle }: Props) {
  const [inputFields, setInputFields] = useState<any>([]);
  const [KeyBoardMode, setKeyBoardMode] = useState<boolean>(false);
  // const canvasRef = useRef<ReactSketchCanvasRef>(null);
  const [eraseMode, setEraseMode] = useState(false);
  const [canvasColor, setCanvasColor] = useState("#f5f5f5");
  const [strokeColor, setStrokeColor] = useState("#000000");
  const [shapes, setShapes] = useState<any>([]);

  const [tool, setTool] = useState<String>("");
  const [isDrawing, setIsDrawing] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [currentShape, setCurrentShape] = useState(null);

  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const overlayRef = useRef(null);
  // const canvas: any = canvasRef.current;
  // const overlay: any = overlayRef.current;
  // const ctx = canvas.getContext("2d");
  // const ctxo = overlay.getContext("2d");
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [prevRect, setPrevRect] = useState({ x: 0, y: 0, width: 0, height: 0 });

  useEffect(() => {
    const canvas: any = canvasRef.current;

    if (canvas) {
      canvas.addEventListener("mousedown", handleMouseDown);
      canvas.addEventListener("mousemove", handleMouseMove);
      canvas.addEventListener("mouseup", handleMouseUp);
      canvas.addEventListener("mouseout", handleMouseOut);

      return () => {
        canvas.removeEventListener("mousedown", handleMouseDown);
        canvas.removeEventListener("mousemove", handleMouseMove);
        canvas.removeEventListener("mouseup", handleMouseUp);
        canvas.removeEventListener("mouseout", handleMouseOut);
      };
    }
  }, [isDown, startX, startY, prevRect]);

  const drawSomething = () => {
    setTool("rectangle");
    const canvas: any = canvasRef.current;
    const overlay: any = overlayRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      const ctxo = overlay.getContext("2d");

      // Style the context
      ctx.strokeStyle = "blue";
      ctx.lineWidth = 3;
      ctxo.strokeStyle = "blue";
      ctxo.lineWidth = 3;

      ctx.moveTo(0, 0);
      ctx.lineTo(200, 200);
      ctx.stroke();
      // ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
  };

  const handleMouseDown = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const canvas: any = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    console?.log("rectttt", rect);
    setStartX(e.clientX - rect.left);
    setStartY(e.clientY - rect.top);
    setIsDown(true);
  };

  const handleMouseUp = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const overlay: any = overlayRef.current;
    setIsDown(false);
    const ctxo = overlay.getContext("2d");
    ctxo.strokeRect(prevRect.x, prevRect.y, prevRect.width, prevRect.height);
  };

  const handleMouseOut = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDown(false);
  };

  const handleMouseMove = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isDown) return;
    const canvas: any = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const rect = canvas.getBoundingClientRect();
    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;
    const width = mouseX - startX;
    const height = mouseY - startY;

    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Draw a new rect from the start position to the current mouse position
    ctx.strokeRect(startX, startY, width, height);

    setPrevRect({ x: startX, y: startY, width, height });
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        console.log("Context obtained.");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
      } else {
        console.error("Failed to get canvas 2D context");
      }
    } else {
      console.error("Canvas is not available");
    }
  };

  const eraseCanvas = (e: React.MouseEvent<HTMLCanvasElement>) => {
    setTool("erase");
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        const rect = canvas.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        // ctx.moveTo(0, 0);
        // ctx.lineTo(200, 200);
        // ctx.stroke();
        ctx.clearRect(x - 10, y - 10, 20, 20); // Erase area around the cursor
      }
    }
  };

  const handleClearClick = () => {
    // canvasRef.current?.clearCanvas();
    clearInputField();
  };
  const handleResetClick = () => {
    // setCanvasColor("#f5f5f5");
    // // canvasRef.current?.resetCanvas();
    // clearInputField();
  };

  const handleEraserClick = () => {
    setEraseMode(true);
    // canvasRef.current?.eraseMode(true);
    disabledKeyBoardMode();
  };

  const handlePenClick = () => {
    setEraseMode(false);
    // canvasRef.current?.eraseMode(false);
    disabledKeyBoardMode();
  };

  const handleUndoClick = () => {
    // canvasRef.current?.undo();
    disabledKeyBoardMode();
  };

  const handleRedoClick = () => {
    // canvasRef.current?.redo();
    disabledKeyBoardMode();
  };

  const handleCanvasColorChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCanvasColor(event.target.value);
    disabledKeyBoardMode();
  };

  const handleStrokeColorChange = (event: ChangeEvent<HTMLInputElement>) => {
    disabledKeyBoardMode();
    setStrokeColor(event.target.value);
  };

  const clearInputField = () => {
    setInputFields([]);
    setKeyBoardMode(false);
  };
  const disabledKeyBoardMode = () => {
    setKeyBoardMode(false);
  };
  console.log("inputFields", inputFields);
  const handleCanvasClick = (event: any) => {
    validateInputFiled();
    if (
      event.target.tagName !== "TEXTAREA" &&
      KeyBoardMode &&
      !validateInputFiled()
    ) {
      const rect = event.target.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      setInputFields([
        ...inputFields,
        { x, y, colorCode: strokeColor, id: Date.now(), value: "" },
      ]);
    }
  };

  const handleKeyBoard = () => {
    setKeyBoardMode(true);
  };

  const onChangeInput = (val: string, i: number) => {
    const newArr = [...inputFields];
    newArr[i].value = val;
    setInputFields(newArr);
  };
  const validateInputFiled = () => {
    let isDescBlank: boolean = false;
    inputFields?.map((item: any) => {
      if (item?.value === "") {
        isDescBlank = true;
      }
    });
    return isDescBlank;
  };

  // const handleMouseDown = (e) => {
  //   if (tool === 'rectangle' || tool === 'circle') {
  //     setIsDrawing(true);
  //     setStartPos({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
  //   }
  // };

  // const handleMouseMove = (e:any) => {
  //   if (isDrawing && (tool === 'rectangle' || tool === 'circle')) {
  //     const currentPos = { x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY };
  //     const shape:any = {
  //       type: tool,
  //       startX: startPos.x,
  //       startY: startPos.y,
  //       endX: currentPos.x,
  //       endY: currentPos.y,
  //     };
  //     setCurrentShape(shape);
  //   }
  // };

  // const handleMouseUp = (e) => {
  //   if (isDrawing && (tool === 'rectangle' || tool === 'circle')) {
  //     const currentPos = { x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY };
  //     const shape = {
  //       type: tool,
  //       startX: startPos.x,
  //       startY: startPos.y,
  //       endX: currentPos.x,
  //       endY: currentPos.y,
  //     };
  //     setCurrentShape(null);
  //     setIsDrawing(false);
  //     // Save shape to shapes array
  //     setShapes((prevShapes) => [...prevShapes, shape]);
  //   }
  // };

  return (
    <div
      className={`EditorDiv position-relative flex-column ${
        toggle ? "active" : ""
      }`}
    >
      <div className="d-flex  align-items-center">
        <div>
          <button onClick={() => drawSomething()}>Rectangle</button>
          <button onClick={() => setTool("circle")}>Circle</button>
          <button onClick={() => clearCanvas()}>reset</button>
          <button onClick={(e: any) => eraseCanvas(e)}>Erase</button>
        </div>
        <div
          onClick={() => setToggle(!toggle)}
          style={{ cursor: "pointer" }}
          className="sideMenuBtn bgOrangeWithTextWhite"
        >
          <DrawIcon />
        </div>
      </div>
      <div className={`EditorDivBox flex-column ${toggle ? "active" : ""}`}>
        <div className="canvasMenu d-flex align-items-center">
          <input
            type="color"
            value={canvasColor}
            className="w-100"
            onChange={handleCanvasColorChange}
          />
          <button
            className="btn btn-outline-dark rounded-0 p-1"
            onClick={handleKeyBoard}
          >
            <KeyboardIcon />
          </button>
          <button
            className="btn btn-outline-dark rounded-0 p-1"
            onClick={handlePenClick}
          >
            <CreateIcon />
          </button>
          <input
            type="color"
            value={strokeColor}
            className="w-100"
            onChange={handleStrokeColorChange}
          />
          <button
            className="btn btn-outline-dark rounded-0 p-1"
            onClick={handleEraserClick}
          >
            <AutoFixHighIcon />
          </button>
          <button
            className="btn btn-outline-dark rounded-0 p-1"
            onClick={handleUndoClick}
          >
            <UndoIcon />
          </button>
          <button
            className="btn btn-outline-dark rounded-0 p-1"
            onClick={handleRedoClick}
          >
            <RedoIcon />
          </button>
          <button
            className="btn btn-outline-dark rounded-0 p-1"
            onClick={handleClearClick}
          >
            <DeleteForeverIcon />
          </button>
          <button
            className="btn btn-outline-dark rounded-0 p-1"
            onClick={handleResetClick}
          >
            <RestartAltIcon />
          </button>
        </div>

        <div className="h-100 position-relative" onClick={handleCanvasClick}>
          {KeyBoardMode ? <div className="transperantPatch"></div> : null}
          {/* <ReactSketchCanvas
            //   style={styles}
            ref={canvasRef}
            width="500"
            height="400"
            strokeWidth={4}
            strokeColor={strokeColor}
            className="w-100 border-0 h-100"
            canvasColor={canvasColor}
            //     onMouseDown={handleMouseDown}
            // onMouseUp={handleMouseUp}
          /> */}
          <div id="canvasWrapper" style={{ position: "relative" }}>
            <canvas
              ref={overlayRef}
              id="overlay"
              width={450}
              height={450}
              style={{
                border: "1px solid red",
                position: "absolute",
              }}
            ></canvas>
            <canvas
              ref={canvasRef}
              id="canvas"
              width={450}
              height={450}
              style={{
                border: "1px solid red",
                position: "absolute",
              }}
            ></canvas>
          </div>
          {inputFields.map((input: any, index: number) =>
            KeyBoardMode ? (
              <textarea
                key={input.id}
                style={{
                  position: "absolute",
                  left: `${input.x}px`,
                  top: `${input.y}px`,
                  color: input?.colorCode,
                }}
                rows={3}
                value={input?.value}
                onChange={(e: any) => onChangeInput(e.target.value, index)}
              ></textarea>
            ) : (
              <span
                style={{
                  position: "absolute",
                  left: `${input.x}px`,
                  top: `${input.y}px`,
                  color: input?.colorCode,
                }}
              >
                {input?.value}
              </span>
            )
          )}

          {/* <div>
            {shapes?.map((shape, index) => (
              <div key={index}>
                {shape.type === "rectangle" && (
                  <div
                    style={{
                      position: "absolute",
                      left: shape.startX,
                      top: shape.startY,
                      width: shape.endX - shape.startX,
                      height: shape.endY - shape.startY,
                      border: "1px solid black",
                    }}
                  />
                )}
                {shape.type === "circle" && (
                  <div
                    style={{
                      position: "absolute",
                      left: shape.startX,
                      top: shape.startY,
                      width: shape.endX - shape.startX,
                      height: shape.endY - shape.startY,
                      borderRadius: "50%",
                      border: "1px solid black",
                    }}
                  />
                )}
              </div>
            ))}
          </div> */}
        </div>
        {/* } */}
      </div>
    </div>
  );
}

export default LectureCanvasBox;
