import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
const ResultSidebar = ({ sectionsData, onClick }) => {
    console.log("sectionsData",sectionsData)
  const [activeIndex, setActiveIndex] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);
  const handleClick = (index:any) => {
    setActiveIndex(index);
    if (index === 0) {
      setShowDropdown(false);
    }
  };

  return (
    <div className="analysis-sidebar">
      <div className="analysis-nav-list ms-3">
        <Nav variant="pills" className="flex-column analysisSidebarList">
          <Nav.Item>
            <Nav.Link
              eventKey="first"
              className={`list-item ${activeIndex === 0 ? 'active' : ''}`}
              onClick={() => handleClick(0)}>
              Results Analysis
            </Nav.Link>
          </Nav.Item>
          <Nav.Item onClick={() => setShowDropdown(true)}>
            <Nav.Link
              eventKey={0}
              className={`list-item mb-1 ${activeIndex === 1 ? 'active' : ''}`}
              onClick={() => handleClick(1)}>
              Sections Report
            </Nav.Link>
            {showDropdown ? (
              <ul className="card py-2">
                {sectionsData?.examData[0]?.sections?.map((items:any, index:any) => {
                  return (
                    <Nav.Item key={index} className="highlight" onClick={onClick}>
                      <Nav.Link eventKey={index}>{items?.name}</Nav.Link>
                    </Nav.Item>
                  );
                })}
              </ul>
            ) : (
              ''
            )}
          </Nav.Item>
        </Nav>
      </div>
    </div>
  );
};

export default ResultSidebar;
