import React, { useState } from 'react';
import { DndProvider, useDrop } from 'react-dnd';
import DragndropLeft from '../Module/dragndropLeft';
import DragndropRight from '../Module/dragndropRight';
import { HTML5Backend } from 'react-dnd-html5-backend';
import '../Assets/Css/matchThePair.css';

const initialLeftItems = [
  { id: 1, label: 'Item 1', },
  { id: 2, label: 'Item 2' },
  { id: 3, label: 'Item 3' },
];

const initialRightItems = [
  { id: 4, label: 'Match 1' },
  { id: 5, label: 'Match 2' },
  { id: 6, label: 'Match 3' },
];

const MatchThePair = () => {
  const [matches, setMatches] = useState({});
  const [leftItems] = useState(initialLeftItems);
  const [rightItems] = useState(initialRightItems);
console.log('matches', matches);

const handleDrop = (item:any, leftItemId:any) => {
  console.log('handleDrop', item);
  setMatches((prevMatches) => ({
    ...prevMatches,
    [leftItemId]: { id: item.id, label: item.label }, // Storing both id and label
  }));
};

const checkMatches = () => {
  let results = leftItems.map((leftItem) => {
    const matchedItem = matches[leftItem.id];
    return matchedItem && matchedItem.id === leftItem.id + 3; // Assuming the match has an ID that is 3 greater
  });
  alert(results.every((result) => result) ? 'All correct!' : 'Some matches are incorrect.');
};

console.log('leftItems', leftItems);
console.log('rightItems', rightItems);
  return (
    <DndProvider backend={HTML5Backend}>
    <div className="match-container d-flex ">
      <DragndropLeft items={leftItems} />
      <div className="right-container mx-2">
        {leftItems.map((leftItem) => (
          <DroppableArea
            key={leftItem.id}
            leftItem={leftItem}
            onDrop={(item:any) => handleDrop(item, leftItem.id)}
            matchedItem={matches[leftItem.id]}
          />
        ))}
      </div>
      <DragndropRight items={rightItems} />
    </div>
      <button className='btn btn-primary mt-4' onClick={checkMatches}>Submit</button>
    </DndProvider>
  );
};

const DroppableArea = ({ leftItem, onDrop, matchedItem }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'ITEM',
    drop: (item) => onDrop(item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  return (
    <div ref={drop} className={`drop-area ${isOver ? 'hovered' : ''}`}>
      {matchedItem ? matchedItem.label : 'Drop here'} {/* Displaying the matched item label */}
    </div>
  );
};

export default MatchThePair;
