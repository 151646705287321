import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../hooks";
import "../Assets/Css/lectureDashboard.css";
import Data from "../Constant/userLectureData";
import localImagesNew from "../Constant/localImagesNew";
import Hints_Coins from "../Assets/Images/UserLecture/Hints_Coins.webp";
import {
  getAcademicDataByTopicId,
  getExam,
  notSyncedDataAction,
} from "../Redux/Actions/examActions";
import { useSelector } from "react-redux";
import { _retrieveData } from "../Utils/utils";
import waiting from "../Assets/Images/json/waiting.json";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import Lottie from "react-lottie";

export default function LectureDashboard() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [lectureData, setLectureData] = useState<any>();
  const [idData, setidData] = useState<any>();
  const [noVideo, setNoVideo] = useState(false);
  let selectedQuestionIndex = 0;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: waiting,
    renderer: "svg",
  };

  const { notSyncedDataFromStore, examIdDataFromStoreObject } = useSelector(
    (state: any) => ({
      notSyncedDataFromStore: state?.ExamSelectReducer?.notSyncedData,
      examIdDataFromStoreObject: state?.ExamSelectReducer?.examIdData,
    })
  );

  const AllId: any = sessionStorage?.getItem("AllId");
  const AllIDData: any = JSON.parse(AllId);
  // useEffect(() => {
  //   sessionStorage.removeItem("Lecture");
  //   const query = new URLSearchParams(window.location.search);
  //   const dataString = query.get("data");
  //   if (dataString) {
  //     sessionStorage.setItem("AllId", decodeURIComponent(dataString));
  //   } else {
  //     console.error("No data received");
  //   }
  // }, []);
  useEffect(() => {
    console.log("location.state", location?.state);
    if (location.state) {
      sessionStorage.setItem("AllId", JSON.stringify(location.state?.data));
      setidData(location.state?.data);
    }
  }, [location.state]);

  const getData = (data: any) => {
    // navigate("/LectureIndex", { state: { lectureDataIndex: index } });
    console?.log("lectureId", data);
    const sendData = {
      lectureId: data?.lectureId,
      topicId: AllIDData?.topicId,
      studentId: AllIDData?.studentId,
      teacherId: AllIDData?.teacherId,
      teacher: AllIDData?.teacher,
      isLectureComplete: true,
    };
    console.log("sendData", sendData);
    const encodedData = encodeURIComponent(JSON.stringify(sendData));
    // window.open(
    //   `${process.env.REACT_APP_WEBLINK}/LectureIndex?data=${encodedData}`,
    //   "_parent"
    // );
    window.open(
      `http://localhost:3700/LectureIndex?data=${encodedData}`,
      "_parent"
    );
  };
  console.log("AllIDData", AllIDData);
  useEffect(() => {
    // sessionStorage.clear();
    console.log("object", AllIDData?.teacherId);
    if (
      AllIDData?.topicId &&
      AllIDData?.studentId &&
      AllIDData?.teacherId &&
      AllIDData?.teacher != undefined
    ) {
      dispatch(
        getAcademicDataByTopicId(
          {
            topicId: AllIDData?.topicId,
            studentId: AllIDData?.studentId,
            teacherId: AllIDData?.teacherId,
            teacher: AllIDData?.teacher,
          },
          (res: any) => {
            console.log("res", res);
            setLectureData(res);
          },
          () => {}
        )
      );
    }
  }, [
    AllIDData?.topicId,
    AllIDData?.studentId,
    AllIDData?.teacherId,
    AllIDData?.teacher,
  ]);
  console.log("AllIDData", AllIDData);

  const handleOpenAnalysis = (lectureId: any) => {
    navigate("/detailsAnalysis", {
      state: { lectureId: lectureId, studentId: AllIDData?.studentId },
    });
  };
  const handleOpenDppAnalysis = (id: any) => {
    console.log("_Id", id);
    navigate("/DetailResult", { state: { examSetId: id } });
  };
  const onStartExam = (examId: any) => {
    const examIdDataFromStore = examIdDataFromStoreObject[examId];
    const args = {
      examId,
      dispatch,
      getExam,
      notSyncedDataFromStore,
      selectedQuestionIndex,
      notSyncedDataAction,
      examIdDataFromStore,
    };
    _retrieveData(args);
  };

  function goToLink(
    val: string,
    lectureId: any,
    teacherId: any,
    studentId: any,
    teacherName: any,
    lectureDate: any
  ): void {
    navigate("/" + val, {
      state: {
        lectureData: lectureData,
        lectureId: lectureId,
        teacherId: teacherId,
        studentId: studentId,
        teacherName: teacherName,
        lectureDate: lectureDate,
      },
    });
  }
  function gotoCourseDetail(data: any) {
    if (data?.linkStatus === 1) {
      setNoVideo(true);
    } else {
      navigate("/VideoPlayer", {
        state: { lectureId: "", recordedData: data },
      });
    }
  }
  return (
    <div className="lectureDashboard">
      <div className="daywiseData rounded">
        {/* <div
          className="position-fixed"
          style={{ zIndex: "1", top: "20px", left: "20px" }}
        >
          <div className="position-relative">
            <span className="totalHints fw-bold">
              {lectureData?.totalHints}
            </span>
            <span className="totalEarnedPoints fw-bold">
              {lectureData?.totalEarnedPoints}
            </span>
            <img
              src={Hints_Coins}
              style={{ width: "175px" }}
              alt={"hints_coins"}
            />
          </div>
        </div> */}

        <div className="text-center">
          <h3 className="fw-bold text-center colorWhite LectureHeading rounded-pill position-relative position-relative">
            {lectureData?.topicName}
            <div className="verticalLine1"></div>
          </h3>
        </div>
        {lectureData?.lectureData?.map((item: any, index: number) => {
          console.log("itemitemitem", item);
          return (
            <div
              className={`dayData rounded position-relative p-3 ${
                index % 2 === 0 ? "blue" : "red"
              }`}
            >
              <div className="row">
                <div className="col-md-1">
                  <div className="imgBg rounded me-3">
                    <img
                      src={localImagesNew?.DayType1}
                      alt=""
                      className="dayIcon"
                    />
                  </div>
                </div>
                <div className="d-flex col-md-11 align-items-center">
                  <div className="col-md-1">
                    <div>
                      <div className="fw-bold dayText lh-1">DAY</div>
                      <div className="fw-bold dayCount lh-1">
                        {index > 9 ? "" : 0}
                        {index + 1}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <h5 className="dayTitle p-2 mb-0 fw-bold px-3 rounded">
                      {item?.title}
                    </h5>
                  </div>
                  <div className="col-md-2 mx-auto">
                    <div className="p-0 d-flex ">
                      {item?.status === 1 ? (
                        <button
                          className="btn btn-primary rounded-pill px-5"
                          onClick={() => getData(item)}
                        >
                          Start
                        </button>
                      ) : item?.status === 2 ? (
                        <button
                          className="btn btn-warning rounded-pill px-5"
                          onClick={() => getData(item)}
                        >
                          Resume
                        </button>
                      ) : item?.status === 3 ? (
                        <button
                          className="btn btn-success rounded-pill px-5"
                          onClick={() => getData(item)}
                        >
                          Completed
                        </button>
                      ) : (
                        <button
                          className="btn btn-secondary rounded-pill px-5"
                          disabled
                        >
                          Start
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-10 mx-auto">
                  <div className="d-flex my-3 justify-content-between">
                    <div className="d-flex col-md-6 align-items-center">
                      <div className="questionTypeImgBox">
                        <img
                          src={localImagesNew?.recordedLecture}
                          alt="Watch Video"
                          className="practiceQueicon"
                        />
                      </div>
                      <div
                        className="quesTypeText rounded font16 fw-bolder  w-50"
                        onClick={() => gotoCourseDetail(item)}
                      >
                        Watch Video
                      </div>
                    </div>
                    <div className="d-flex col-md-6 align-items-center">
                      <div className="questionTypeImgBox">
                        <img
                          src={localImagesNew?.PracticeQuestion}
                          alt="Practice Question"
                          className="practiceQueicon"
                        />
                      </div>
                      <div className="quesTypeText font16 fw-bolder w-50">
                        Practice Question
                      </div>
                      {item?.status === 3 ? (
                        <div
                          className="AnalysisText font16 fw-bolder colorWhite"
                          onClick={() => handleOpenAnalysis(item?.lectureId)}
                        >
                          <span>Analysis</span>
                        </div>
                      ) : (
                        <div className="quesTypeTextVal font16 rounded-end fw-bolder">
                          {item?.practiceQuestionCount}
                        </div>
                      )}
                    </div>
                  </div>

                  {item?.dppData?.id ? (
                    <div className="d-flex col-md-6 align-items-center">
                      <div className="questionTypeImgBox">
                        <img
                          src={localImagesNew?.Homework}
                          alt="Homework"
                          className="practiceQueicon"
                        />
                      </div>
                      <div className="quesTypeText font16  w-50">Home Work</div>

                      {item?.status === 3 && item?.dppData?.status === 2 ? (
                        <div
                          className="AnalysisText font16 fw-bolder colorWhite"
                          onClick={() =>
                            handleOpenDppAnalysis(item?.dppData?.id)
                          }
                        >
                          Analysis
                        </div>
                      ) : item?.status === 3 && item?.dppData?.status === 1 ? (
                        <div
                          className="AnalysisText font16 fw-bolder colorWhite"
                          style={{
                            background: "#0d82e4",
                          }}
                          onClick={() =>
                            AllIDData?.teacher
                              ? console.log("Not Allowed")
                              : onStartExam(item?.dppData?.id)
                          }
                        >
                          Attempt
                        </div>
                      ) : item?.dppData?.status === 1 ? (
                        <div className="quesTypeTextVal font16 rounded-end fw-bolder">
                          {item?.dppData?.dppQuesCount}
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
                {/* <div className="col-md-10 mx-auto">
                  <div className="d-flex my-3 justify-content-between">
                    <div className="d-flex col-md-6 align-items-center">
                      <div className="questionTypeImgBox">
                        <img
                          src={localImagesNew?.PracticeQuestion}
                          alt="Practice Question"
                          className="practiceQueicon"
                        />
                      </div>
                      <div className="quesTypeText font16 fw-bolder w-50">
                        Practice Question
                      </div>
                      {item?.status === 3 ? (
                        <div
                          className="AnalysisText font16 fw-bolder colorWhite"
                          onClick={() => handleOpenAnalysis(item?.lectureId)}
                        >
                          <span>Analysis</span>
                        </div>
                      ) : (
                        <div className="quesTypeTextVal font16 rounded-end fw-bolder">
                          {item?.practiceQuestionCount}
                        </div>
                      )}
                    </div>
                    {item?.dppData?.id ? (
                      <div className="d-flex col-md-6 align-items-center">
                        <div className="questionTypeImgBox">
                          <img
                            src={localImagesNew?.Homework}
                            alt="Homework"
                            className="practiceQueicon"
                          />
                        </div>
                        <div className="quesTypeText font16  w-50">
                          Home Work
                        </div>

                        {item?.status === 3 && item?.dppData?.status === 2 ? (
                          <div
                            className="AnalysisText font16 fw-bolder colorWhite"
                            onClick={() =>
                              handleOpenDppAnalysis(item?.dppData?.id)
                            }
                          >
                            Analysis
                          </div>
                        ) : item?.status === 3 &&
                          item?.dppData?.status === 1 ? (
                          <div
                            className="AnalysisText font16 fw-bolder colorWhite"
                            style={{
                              background: "#0d82e4",
                            }}
                            onClick={() =>
                              AllIDData?.teacher
                                ? console.log("Not Allowed")
                                : onStartExam(item?.dppData?.id)
                            }
                          >
                            Attempt
                          </div>
                        ) : item?.dppData?.status === 1 ? (
                          <div className="quesTypeTextVal font16 rounded-end fw-bolder">
                            {item?.dppData?.dppQuesCount}
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                  {item?.status === 3 ? (
                    <div className="d-flex my-3 justify-content-between">
                      <div className="d-flex col-md-6 align-items-center">
                        <div className="questionTypeImgBox">
                          <img
                            src={localImagesNew?.recordedLecture}
                            alt="Watch Video"
                            className="practiceQueicon"
                          />
                        </div>
                        <div className="quesTypeText rounded font16 fw-bolder  w-50">
                          Watch Video
                        </div>
                      </div>
                      <div className="d-flex col-md-6 align-items-center">
                        <div className="questionTypeImgBox">
                          <img
                            src={localImagesNew?.feedback}
                            alt="Feedback"
                            className="practiceQueicon"
                          />
                        </div>
                        <div className="quesTypeText font16  w-50">
                          Feedback
                        </div>
                        <div
                          className="AnalysisText font16 fw-bolder colorWhite"
                          style={{
                            background: "#0d82e4",
                          }}
                          onClick={() =>
                            // AllIDData?.teacher?console.log("Not Allowed"):
                            goToLink(
                              "StudentFeedback",
                              item?.lectureId,
                              lectureData?.teacherId,
                              lectureData?.studentId,
                              lectureData?.teacherName,
                              lectureData?.lectureDate
                            )
                          }
                        >
                          {item?.feedbackStatus === 2 ? "View" : "Feedback"}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div> */}
              </div>
            </div>
          );
        })}
      </div>
      {noVideo && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="model"
          open={noVideo}
          onClose={() => {
            setNoVideo(false);
          }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={noVideo}>
            <div className="addUpi-modal col-md-4">
              <div>
                <div className="col mb-2 text-end">
                  <ClearOutlinedIcon
                    className="colorblue cursor"
                    onClick={() => {
                      setNoVideo(false);
                    }}
                  />
                </div>
                <div className="modal-info-holder">
                  <div className="animation-holder mb-4">
                    <Lottie
                      options={defaultOptions}
                      height={200}
                      width={200}
                    ></Lottie>
                  </div>
                  <div className="text-holder text-center my-5">
                    <h3 className="colorBlue">Please Wait for a While!!! </h3>
                    <p className="text-success fw-bold">
                      Lecture Will Be Available Soon...
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      )}
    </div>
  );
}
