import React, { useEffect, useRef, useState } from 'react';
import localImages from '../Constant/localImagesNew';
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css";
import Faq from '../Component/faq';
import Footer from '../Component/footer';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import BookSessionPopup from '../Component/bookSessionPopup';
import StudentSayCarousel from '../Component/studentSayCarousel';
import '../Assets/Css/marketinglandingpage.css'
import dataConstant from '../Constant/dataConstant';
import { useAppDispatch } from '../hooks';
import CertificateSection from '../Module/certificateSection';
import Stats from '../Module/stats';
import LearnLandingPopup1 from '../Component/learnLandingPopup1';
import LearnLandingPopup2 from '../Component/learnLandingPopup2';
import HeaderLearn from '../Component/headerLearn';

export default function Marketinglandingpage() {
    const navigate = useNavigate();
    const homebannerdiv = React.useRef<HTMLDivElement>(null);
    const [isHidden, setisHidden] = useState<boolean>(true);
    const dispatch = useAppDispatch();
  const [openpopup1, setopenpopup1] = useState<boolean>(false);
  const [openpopup2, setopenpopup2] = useState<boolean>(false);

  const [popupShown, setPopupShown] = useState(false); // To track if the popup has been shown before
  const targetRef:any = useRef(null); 
  const statRef:any = useRef(null); 
  
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    
    const contactUS = (val:string) => {
        const element = document.getElementById(val);
        if (element) { 
          element.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        }
      };
 
      const offerData=[
        {
          title:"Academic Tutoring",
          imgPath:localImages?.learnAcademicTutoring,
          desc:"Elevate your child's school performance with LurniGo’s hyper personalized 1 on 1 live classes. Our holistic approach focuses on providing guidance to achieve academic success, through our personalized roadmap strategy to build strong fundamentals and staying consistent with improving the scores."
        },
        {
          title:"Test Prep",
          imgPath:localImages?.learnTestPrep,
          desc:"Our comprehensive test preparation services are designed to help students excel in their standardized tests. We provide targeted preparation and conduct rigorous practice tests at regular intervals to thoroughly assess understanding in every topic, ensuring students achieve their desired results. "
        },
        {
          title:"College Counseling",
          imgPath:localImages?.learnCollegeCounseling,
          desc:"Our advanced college counseling services provide personalized guidance, crafting a customized roadmap to develop a holistic student profile. We engage high school students with admission officers and foster valuable connections through monthly alumni network sessions, all aimed at helping your child secure admission to their dream university. "
        },
      ]
      const learnOurProgramData=[
        {
          title:"Interactive one on one Lessons",
          imgPath:localImages?.learnInteractiveoneononeLessons,
          desc:"Engage with hyper personalized interactive lessons that make learning outcome effective."
        },
        {
          title:"Live Online Classes",
          imgPath:localImages?.learnLiveOnlineClasses,
          desc:"Join our live classes and interact with instructors and peers in real-time"
        },
        {
          title:"Detailed Performance Analysis",
          imgPath:localImages?.learnDetailedPerformanceAnalysis,
          desc:"Receive in-depth feedback on your practice tests to identify areas for improvement"
        },
        {
          title:"On-Demand Resources",
          imgPath:localImages?.learnOnDemandResources,
          desc:"Access a wealth of resources, including video lessons, practice questions, and study guides"
        },
        {
          title:"Full-Length Practice Tests",
          imgPath:localImages?.learnFullLengthPracticeTests,
          desc:"Simulate the real test environment with our full-length practice tests to make the learner exam ready"
        },
      ]
      var myScrollFunc = function () {
        
        var y = window.scrollY;
        const maxScroll = document.body.scrollHeight - window.innerHeight;
        if (y >= 500 && y < maxScroll - 300) {
          setisHidden(false);
        } else {
          setisHidden(true);
        }
      };
      window.addEventListener("scroll", myScrollFunc);


      useEffect(() => {
        const observer = new IntersectionObserver(
          (entries) => {
            const entry = entries[0];
            if (entry.isIntersecting && !popupShown) {
              setopenpopup1(true);
              setPopupShown(true);
            }
          },
          { threshold: 0.5 } // Adjust threshold as needed
        );
    
        if (targetRef.current) {
          observer.observe(targetRef.current);
        }
    
        return () => {
          if (targetRef.current) {
            observer.unobserve(targetRef.current);
          }
        };
      }, [popupShown]);

      
      
    return (
      <div>
        <HeaderLearn isHidden={false} />
        
        {/*************Banner************* */}
        <div className='position-relative  bgBlue' id='Home' ref={homebannerdiv}>
          <div className='row mx-0 '>
            <div className="col-md-7">
              <div className="learnBannerBox">
                <h1 className='homeBannerHeading font48'>Your Single Stop destination for</h1>
                <div className='bgwhite p-2 px-4 d-inline-block mb-4 mt-2'>
                <h1 className='homeBannerHeading font48 colorOrange mb-0'>Academic Excellence !</h1>
                </div>
                <div className='bgwhite' style={{height:"1px"}}></div>
                <div className='font20 fw-bold mt-4'>Hyper personalized learning for students between Grade 4 - 12 !</div>
                <div className='bannersubtext mt-3'>Assisting your children with their course work to complex test prep and guiding them with the right choice for their college admission process as a parent can be quite daunting. We got your back in guiding your children in turning them into academic champ.</div>
                <div className="mb-5 mt-4 ">
                  <button className="bookSessionButton rounded fw-bold"  onClick={() => setopenpopup2(true)}>
                    Book Your Trial Session
                  </button>
                </div>
                
              </div>
            </div>
            <div className="col-md-5 position-relative pe-0 pb-0 landingPageRigthDiv text-center d-flex align-items-end  justify-content-center">
              <img
                src={localImages?.learnBanner}
                alt="Digital sat Banner"
                className="w-100"
                style={{maxWidth:"500px"}}
              />
            </div>
          </div>
        </div>
        <div className='commonPad position-relative wow fadeIn' data-wow-duration="2s" data-wow-delay="0s" id='offer'>
        <img src={localImages?.award} className='learnSolleft' alt='learnSolleft' style={{maxWidth:"130px"}} />
        <div className='width800 mx-auto my-5'>
            <h1 className='headingFont text-center'>What we Offer ?</h1>           
          </div>
          <div className='d-flex row mx-0 mx-md-5 wow fadeIn' data-wow-duration="2s" data-wow-delay="0s">
              {offerData?.map((item:any,index:number)=>(
                <div className='col-md-4 mb-3 mb-md-0 wow fadeIn' data-wow-duration="2s" data-wow-delay={`${index * 0.5}s`} key={index}>
                  <div className='learnWeOfferCard overflow-hidden'>
                      <div className='learnWeOfferTitle text-center font20'>{item?.title}</div>
                      <div>
                        <img src={item?.imgPath} alt={item?.title} className='w-100'/>
                      </div>
                      <div className='p-4 font16'>
                        {item?.desc}
                      </div>
                  </div>
                </div>
              ))}
            </div>
        </div>
        <div id='OurProgram' style={{height:"65px"}}></div>
        <div className='commonPad position-relative bgLightGrey' ref={targetRef}>
        <h1 className='headingFont text-center'>Our Program consists of?</h1> 
        <div className='row mx-0 d-flex justify-content-center mt-5'>
              {learnOurProgramData?.map((item,index)=>(
                <div className='col-md-4 mb-4 wow fadeIn' data-wow-duration="2s" data-wow-delay={`${index * 0.5}s`} key={index}>
                  <div className='learnourProgram'>
                    <div className='icon-container'>
                      <div className='learnourProgramImgCard'>
                        <img src={item?.imgPath} alt={item?.title} className='learnourProgramImg' />
                      </div>
                    </div>
                    <div className='fw-bold font20 my-3 text-center'>{item?.title}</div>
                    <div className='text-center font16'>{item?.desc}</div>
                  </div>
                </div>
              ))}
        </div>  
        </div>
        <div id='WhyUs'></div>
        <div ref={statRef}>
        <Stats />
        </div>
        
        <div className='commonPad bgLightGrey' id='Testimonials'>
          <div className='width800 mx-auto my-5'>
            <h1 className='headingFont text-center'>Don't just take our word for it</h1>
            <div className='bannersubtext mb-3 text-center'>Hear what our students have to say</div>
          </div>
          <div>
            <StudentSayCarousel Data={dataConstant?.studentSayData} id='carouselStudent' />
          </div>
        </div>

        
            <CertificateSection />

            <div className="commonPad bglightSkyblue ">
        <div className="bgwhite p-3 p-md-5 boxshadow rounded position-relative overflow-hidden">
          <div className="row">
            <div className="col-md-8">
              <div className="bookSessionHeading">Book your session now</div>
              <div className="bookSessionSubText">
                One of our Academic Counsellor will get in touch with you to
                discuss further.
              </div>
              <div className="mt-5">
                <button
                  className="signBtn px-3 px-md-5 rounded font16"
                   onClick={() => setopenpopup2(true)}
                >
                  Start your Academic Journey Today
                </button>
              </div>
            </div>
            <div className="col-md-4">
              {/* <img src={localImages?.collegePredleft} className='collegePredleft' alt='collegePredleft'/> */}
              <img src={localImages?.bookSessionLine} className='position-absolute bottom-0 end-0 d-none d-md-block' alt='collegePredright' style={{maxWidth:"400px"}}/>
            </div>
          </div>
        </div>
      </div>


        <div className='commonPad' id='FAQs'></div>
        <div className='commonPad '>
          <div className='row mx-0'>
            <div className='col-md-3'>
              <h1 className='headingFont mb-4'>FAQs</h1>
              <div className='mb-4'>
                <button className='contactUSbtn px-5' onClick={() => contactUS('Footer')}>Contact Us</button>
              </div>
            </div>
            <div className='col-md-9'>
              <Faq faqData={dataConstant?.faqData} id={"HomeFAQ"} />
            </div>
          </div>
        </div>


        {/***************Footer*************** */}
        <Footer id='Footer' />
        <LearnLandingPopup1
        open={openpopup1}
        handleClose={() => setopenpopup1(false)}
      />
       <LearnLandingPopup2
        open={openpopup2}
        handleClose={() => setopenpopup2(false)}
      />

        {!isHidden ? (
        <div className="fixedFooter">
          <div className="row mx-0">
            <div className="col-7 col-md-6 d-flex align-items-center mb-2 mb-md-0">
              <div className="fw-bold font20 colorblue fontLibre">
              Book Your Trial Class With Us Today!
              </div>
            </div>
            <div className="col-5 col-md-6 d-flex align-items-center justify-content-center justify-content-md-end flex-wrap position-relative">
              
              <button
                className="bookSessionButton rounded fw-bold mt-2 mt-md-0 font16"
                 onClick={() => setopenpopup2(true)}
              >
                Book Your Trial Session
              </button>
            </div>
          </div>
        </div>
      ) : null}
      </div>
            )
}
