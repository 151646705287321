import React, { useEffect, useState } from "react";
import "../Assets/Css/login.css";
import InputField from "../Atom/InputField";
import CustomDropdown from "../Atom/customDropdown";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getCountry,
  getExamList,
  getTimezoneList,
  register,
  updateMoreValues,
} from "../Redux/Actions/AuthAction";
import { useAppDispatch } from "../hooks";
import {
  validateEmailAddress,
  validateFullName,
  validateIsNumberOnly,
} from "../Utils/validators";
import { showErrorSnackbar } from "../Redux/Actions/snackbarAction";
import {
  validateEmailAddressErrMsg,
  validateFullNameErrMsg,
  validateIsNumberOnlyErrMsg,
  validateLastNameErrMsg,
} from "../Utils/errormsg";
import Dropdown from "../Component/dropdown";
import service from "../Constant/service";
import HeaderHome from "../Component/headerHome";
import Sidebar from "../Module/sidebar";
import localImages from "../Constant/localImagesNew";
import PhoneInputField from "../Atom/phoneInputField";
export default function CreateAccount() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { mobNo,coutryCodeNo } = location?.state;
  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const [email, setemail] = useState("");
  const [examList, setExamList] = useState([]);
  const [examval, setExamval] = useState("");
  const [pname, setpname] = useState("");
  const [pmobno, setpmobno] = useState("");
  const [pemail, setpemail] = useState<string>("");
  const [altMobno, setaltMobno] = useState<string>(""); 
  const [countryData, setCountryData] = useState([]);
  const [countryCode, setcountryCode] = useState<string>(coutryCodeNo);

  useEffect(() => {
    dispatch(
      getExamList(
        (data: any) => {
          setExamList(data);
        },
        () => {}
      )
    ); 
    dispatch(
      getCountry(
        (data: any) => {
          setCountryData(data);
        },
        () => {}
      )
    );
  }, []);
  function validations() {
    return (
      fname.length == 0 ||
      lname === "" ||
      email === "" ||
      examval === "" ||
      // pname === "" ||
      // pmobno === "" ||
      // pmobno.length != 10 ||
      // pemail === "" || 
      (altMobno.length > 0 && altMobno.length < 10) ||
      !validateEmailAddress(email) 
      // !validateEmailAddress(pemail)
    );
  }
  const handleFirstInputText = (e: any) => {
    if (validateFullName(e)) setfname(e);
    else {
      dispatch(showErrorSnackbar(validateFullNameErrMsg));
    }
  };

  const handleLastInputLnameText = (e: any) => {
    if (validateFullName(e)) setlname(e);
    else {
      dispatch(showErrorSnackbar(validateLastNameErrMsg));
    }
  };
  const handleParentNameInputLnameText = (e: any) => {
    if (validateFullName(e)) setpname(e);
    else {
      dispatch(showErrorSnackbar("Please enter valide Parent Name"));
    }
  };

  const clearData = () => {
    setfname("");
    setlname("");
    setemail("");
    setExamval("");
    setpname("");
    setpmobno("");
    setpemail("");
    setaltMobno("");
  };

  function navigatelink(
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ): void {
    navigate("/Login");
  }

  function getExamVal(value: any) {
    setExamval(value);
  }

  const onclickRegister = () => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      const payload: any = {
        firstName: fname,
        lastName: lname,
        mobile: mobNo,
        email: email,
        examtypeId: examval,
        parentEmail: pemail,
        parentName: pname,
        parentNumber: pmobno,
        altNumber: altMobno, 
        countryCode:"+"+coutryCodeNo
      };
      if (altMobno === "") {
        delete payload.altNumber;
      } 
      if (pemail === "") {
        delete payload.parentEmail;
      } 
      if (pname === "") {
        delete payload.parentName;
      } 
      if (pmobno === "") {
        delete payload.parentNumber;
      } 
      dispatch(
        register(
          payload,
          (data: any) => {
            dispatch(updateMoreValues({ ACCESS_TOKEN: data.data.authToken }));
            dispatch(updateMoreValues({ email: data.data.email }));
            dispatch(updateMoreValues({ countryCode: data.data.countryCode }));  
            dispatch(updateMoreValues({ firstName: data.data.firstName }));
            dispatch(updateMoreValues({ lastName: data.data.lastName }));
            dispatch(updateMoreValues({ mobileNo: data.data.mobile }));
            dispatch(
              updateMoreValues({ examTypeId: data.data.examtypeId?._id })
            );
            dispatch(
              updateMoreValues({ examTypeName: data.data.examtypeId?.name })
            );
            dispatch(updateMoreValues({ courseType: data.data.coursetypeId }));
            dispatch(updateMoreValues({ parentName: data.data.parentName }));
            dispatch(
              updateMoreValues({ parentNumber: data.data.parentNumber })
            );
            dispatch(updateMoreValues({ parentEmail: data.data.parentEmail }));
            dispatch(updateMoreValues({ altNumber: data.data.altNumber }));
            dispatch(updateMoreValues({ userId: data.data.userId }));
            dispatch(updateMoreValues({ studentId: data.data.studentId }));

            service.setAuthorizationToken(data.data.authToken);
            dispatch(
              updateMoreValues({
                hasActivePaidPlan: data.data.hasActivePaidPlan,
              })
            );
            navigate("/User/Course");
          },
          () => {}
        )
      );
    } else {
      //alert("You have entered an invalid email address!");
      dispatch(showErrorSnackbar(validateEmailAddressErrMsg));
      return false;
    }
  };

  const changepMob = (e: any) => {
    if (!validateIsNumberOnly(e)) {
      setpmobno(e);
    } else {
      dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
    }
  };
  const changealtMob = (e: any) => {
    if (!validateIsNumberOnly(e)) {
      setaltMobno(e);
    } else {
      dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
    }
  };
  // function changealtMob(e: React.ChangeEvent<HTMLInputElement>): void {
  //   if (!validateIsNumberOnly(e.target.value)) {
  //     setaltMobno(e.target.value);
  //   } else {
  //     dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
  //   }
  // }
  const onChangeCountryCode = (e: any) => {
    setcountryCode(e.target.value);
  };
  return (
    <div>
    <HeaderHome isHidden={true} />
    <div className="d-block d-md-none">
          <Sidebar/> 
          </div>
    <div className="loginBG bgLightGrey d-flex position-relative">
        <img src={localImages?.loginleftarrow} className='loginLeftArrow' alt='loginLeftArrow' />
              <img src={localImages?.loginrightarrow} className='loginRightArrow' alt='loginRightArrow' />
      <div className="row authDiv w-100">
        <div className="col-lg-5 rightDivPad position-relative m-auto boxshadow">
          <div>
            <h3 className="colorblue boldfont text-center mb-3">
              Create your account
            </h3>
            <div className="colorgray707070 text-center my-2">
              Registration for{" "}
              <span className="colorblue fw-bold">+{coutryCodeNo} {mobNo}</span>
            </div>
            <div className="text-center">
              Want to change{" "}
              <span
                className="colorblue  text-decoration-underline"
                onClick={navigatelink}
                style={{ cursor: "pointer" }}
              >
                {" "}
                Mobile Number{" "}
              </span>
            </div>

            <div className="createAccountBox">
              <div className="row">
                <div className="col-md-6 mb-2">
                  <label className="mb-2">First Name</label>
                  <div className="d-flex">
                    <InputField
                      placeholder="Enter First Name"
                      value={fname}
                      maxLength={"50"}
                      //setValue={setfname}
                      setValue={handleFirstInputText}
                    />
                    <span className="required ms-2 fs-6 text-danger">*</span>
                  </div>
                </div>
                <div className="col-md-6 mb-2">
                  <label className="mb-2">Last Name</label>
                  <div className="d-flex">
                    <InputField
                      placeholder="Enter Last Name"
                      value={lname}
                      maxLength={"50"}
                      // setValue={setlname}
                      setValue={handleLastInputLnameText}
                    />
                    <span className="required ms-2 fs-6 text-danger">*</span>
                  </div>
                </div>
                <div className="col-md-12 mb-2">
                  <label className="mb-2">Email Address</label>
                  <div className="d-flex">
                    <InputField
                      placeholder="Enter Email Address"
                      value={email}
                      setValue={setemail}
                    />
                    <span className="required ms-2 fs-6 text-danger">*</span>
                  </div>
                </div>
                <div className="col-md-12 mb-2">
                  <label className="mb-2">Exam Name</label>
                  <div className="d-flex">
                    <Dropdown
                      placeholder="Select Exam Name"
                      value={examval}
                      options={examList}
                      setInputText={getExamVal}
                    />
                    <span className="required ms-2 fs-6 text-danger">*</span>
                  </div>
                </div>
                <div className="col-md-12 mb-2">
                  <label className="mb-2">Parent Name</label>
                  <div className="d-flex me-3">
                    <InputField
                      placeholder="Enter Parent Name"
                      value={pname}
                      // setValue={setlname}
                      setValue={handleParentNameInputLnameText}
                      maxLength={100}
                    />
                    {/* <span className="required ms-2 fs-6 text-danger">*</span> */}
                  </div>
                </div>
                {/* <div className="phoneInputBox col-md-12">
                  <label>Parent Mobile number</label>

                  <div className="d-flex">
                    <div className="w-100 position-relative my-2">
                      
                      <span className="countryCode boldfont cursor me-1">
                        {" "}
                        + 1{" "}
                      </span>
                      <input
                        type="text"
                        className="form-control border phoneNoInput "
                        placeholder="Enter Parent Mobile number"
                        onChange={changepMob}
                        maxLength={10}
                        value={pmobno}
                      />
                    </div>
                    <span className="required ms-2 fs-6 text-danger">*</span>
                  </div>
                </div> */}
                <div className="my-2">
                  <label className="mb-2">Parent Mobile number</label>
                  <div className="d-flex position-relative me-3">
                    <select
                      className="form-select cursor coutryCode  rounded-0 rounded-start"
                      aria-label="Default select example"
                      defaultValue={1}
                      value={countryCode}
                      onChange={onChangeCountryCode}
                    >
                      {countryData?.map((item: any, index) => {
                        return (
                          <option value={item.phonecode}>
                            {item?.isoCode} +{item?.phonecode}
                          </option>
                        );
                      })}
                    </select>
                    <PhoneInputField
                      placeholder="Enter Parent Mobile Number"
                      value={pmobno}
                      maxLength={"10"}
                      setValue={changepMob}
                    />
                    {/* <span className="mendatory ms-2 fs-5">*</span> */}
                  </div>
                </div>
                <div className="col-md-12 mb-2 ">
                  <label className="mb-2">Parent Email Address</label>
                  <div className="d-flex me-3">
                    <input
                      className="form-control "
                      type="email"
                      value={pemail}
                      placeholder="Enter Parent Email Address"
                      onChange={(e) => setpemail(e.target.value)}
                    />
                    {/* <span className="required ms-2 fs-6 text-danger">*</span> */}
                  </div>
                </div> 

                {/* <div className="phoneInputBox col-12">
                  <label>Alternative Mobile number</label>
                  <div className="position-relative my-2">
                    <span className="countryCode boldfont cursor me-1">
                      {" "}
                      + 1{" "}
                    </span>
                    <input
                      type="text"
                      className="form-control border phoneNoInput"
                      placeholder="Enter Alternative Mobile number"
                      onChange={changealtMob}
                      maxLength={10}
                      value={altMobno}
                    />
                  </div>
                </div> */}
                <div className="my-2">
                  <label className="mb-2">Alternative Mobile number</label>
                  <div className="d-flex position-relative me-3">
                    <select
                      className="form-select cursor coutryCode rounded-0 rounded-start"
                      aria-label="Default select example"
                      defaultValue={1}
                      value={countryCode}
                      onChange={onChangeCountryCode}
                    >
                      {countryData?.map((item: any, index) => {
                        return (
                          <option value={item.phonecode}>
                            {item?.isoCode} +{item?.phonecode}
                          </option>
                        );
                      })}
                    </select>
                    <PhoneInputField
                      placeholder="Enter Alternative Mobile number"
                      value={altMobno}
                      maxLength={"10"}
                      setValue={changealtMob}
                    />
                    {/* <span className="mendatory ms-2 fs-5">*</span> */}
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <button
                  className={`w-100 rounded px-4 py-1 font24 border-0 ${
                    validations() ? "btnDisable" : "btnActive"
                  }`}
                  disabled={validations()}
                  onClick={onclickRegister}
                >
                  Register
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}
