import {
  SET_EXAM_ID_DATA,
  SET_NOT_SYNCED_DATA,
  SET_INSTRUCTIONS,
  SELECTED_LANGUAGE,
  SET_OPTION_VALUE_FALSE,
  SET_EXAM_STATUS_CHANGE_VALUE,
  SET_EXAM_STATUS_SECTION_CHANGED,
  SET_QUESTIONS_SELECTED_VALUE,
} from "../../Types/examTypes";
import services from "../../Constant/service";
import endpoint from "../../Constant/endpoint";
import { showErrorSnackbar, showSuccessSnackbar } from "./snackbarAction";
import { setParentCourse, updateMoreValues } from "./AuthAction";

export const notSyncedDataAction = (data: any) => ({
  type: SET_NOT_SYNCED_DATA,
  payload: data,
});

export const examIdDataAction = (key: any, value: any) => ({
  type: SET_EXAM_ID_DATA,
  payload: { [key]: value },
});

export const setInstructionsAction = (data: any) => ({
  type: SET_INSTRUCTIONS,
  payload: data,
});

export const selectedLanguageAction = (data: any) => ({
  type: SELECTED_LANGUAGE,
  payload: data,
});

export const setOptionValueFalseAction = (data: any) => ({
  type: SET_OPTION_VALUE_FALSE,
  payload: data,
});

export const setExamStatusValueChangeAction = (data: any) => ({
  type: SET_EXAM_STATUS_CHANGE_VALUE,
  payload: data,
});

export const setExamStatusSectionChangeAction = (data: any) => ({
  type: SET_EXAM_STATUS_SECTION_CHANGED,
  payload: data,
});

export const setExamQuestionSelectedAction = (data: any) => ({
  type: SET_QUESTIONS_SELECTED_VALUE,
  payload: data,
});

export const getExamDescription =
  (data: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.exam.getExamDescription,
      data,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data) {
          callback(res);
        } else errorCallback(res);
      },
      (error: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };

export const getPackageList =
  (data: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.package.getPackageList,
      data,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data) {
          callback(res);
        } else errorCallback(res);
      },
      (error: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };

export const getPackageData =
  (id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.getApiCall(
      endpoint.package.getPackageData + id,
      "",
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
      }
    );
  };
export const getRecomendedData =
  (id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.getApiCall(
      endpoint.package.getRecomendedData + id,
      "",
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
      }
    );
  };

/****************Live Lecture******************** */
export const getLiveLecture =
  (data: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.live.getLiveLecture,
      data,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data) {
          callback(res);
        } else errorCallback(res);
      },
      (error: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };

export const getLiveLectureByID =
  (data: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.live.getLiveLectureByID,
      data,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data) {
          callback(res);
        } else errorCallback(res);
      },
      (error: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };

/************************addToCart*************************** */

export const addCart =
  (data: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.cart.addCart,
      data,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data) {
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
          errorCallback(res);
        }
      },
      (error: any) => {
        dispatch(showErrorSnackbar(error?.data?.msg));
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };

export const getCartList =
  (data: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.cart.getCartList,
      data,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data) {
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
          errorCallback(res);
        }
      },
      (error: any) => {
        dispatch(showErrorSnackbar(error?.data?.msg));
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };

// export const getCartList = (id: any, callback: Function, errorCallback: Function): any =>
//   async (dispatch: Function, getState: Function) => {
//     dispatch(updateMoreValues({ isLoader: true }));
//     services.getApiCall(
//       endpoint.cart.getCartList,
//       "",
//       (res: any) => {
//         dispatch(updateMoreValues({ isLoader: false }));
//         if (res?.data?.statusCode === services?.status_code?.success) {
//           callback(res?.data?.data);
//         } else {
//           errorCallback(res);
//           // dispatch(showErrorSnackbar(res?.msg));
//         }
//       },
//       (error: any) => {
//         dispatch(updateMoreValues({ isLoader: false }));
//       }
//     );
//   };

export const deleteCart =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.deleteApiCall(
      endpoint.cart.deleteCart + params,
      {},
      (res: { data: { statusCode: number; msg: any } }) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };

export const getSessionData =
  (id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.getApiCall(
      endpoint.package.getSessionData + id,
      "",
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
      }
    );
  };

export const updateCart =
  (params: any, id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.patchApiCall(
      endpoint.cart.updateCart + id,
      params,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          if (res?.code) {
            // dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            // dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };

/*************************placeOrder************************ */
// export const placeOrder = (id: any, callback: Function, errorCallback: Function): any =>
//   async (dispatch: Function, getState: Function) => {
//     dispatch(updateMoreValues({ isLoader: true }));
//     services.getApiCall(
//       endpoint.cart.placeOrder,
//       "",
//       (res: any) => {
//         dispatch(updateMoreValues({ isLoader: false }));
//         if (res?.data?.statusCode === services?.status_code?.success) {
//           callback(res?.data?.data);
//         } else {
//           errorCallback(res);
//           // dispatch(showErrorSnackbar(res?.msg));
//         }
//       },
//       (error: any) => {
//         dispatch(updateMoreValues({ isLoader: false }));
//       }
//     );
//   };

export const placeOrder =
  (data: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.cart.placeOrder,
      data,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data) {
          callback(res?.data);
        } else errorCallback(res);
      },
      (error: any) => {
        dispatch(showErrorSnackbar(error?.data?.msg));
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };

/*********************paymentRequest************** */

export const paymentRequest =
  (data: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.payment.paymentRequest,
      data,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data) {
          callback(res);
        } else errorCallback(res);
      },
      (error: any) => {
        dispatch(showErrorSnackbar(error?.data?.msg));
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };

export const paymentExecute =
  (data: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.payment.paymentExecute,
      data,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data) {
          callback(res);
        } else errorCallback(res);
      },
      (error: any) => {
        dispatch(showErrorSnackbar(error?.data?.msg));
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };
export const paymentCancel =
  (data: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.payment.paymentCancel,
      data,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        callback(res);
      },
      (error: any) => {
        dispatch(showErrorSnackbar(error?.data?.msg));
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };

/*********************************************Notification***********************************************/

export const getnotificationList =
  (id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    // dispatch(updateMoreValues({ isLoader: true }));
    services.getApiCall(
      endpoint.notification.getnotificationList,
      "",
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
          dispatch(
            updateMoreValues({
              NotificationCount: res?.data?.data[0]?.unreadCount,
            })
          );
          dispatch(
            updateMoreValues({
              NotificationID: res?.data?.data[0]?.notificationId,
            })
          );
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        // dispatch(updateMoreValues({ isLoader: false }));
      }
    );
  };

// export const getnotificationList = (data: any, callback: Function, errorCallback: Function): any =>
// async (dispatch: Function, getState: Function) => {
//   dispatch(updateMoreValues({ isLoader: true }));
//   services.postApiCall(
//     endpoint.notification.getnotificationList,
//     data,
//     (res: any) => {
//       dispatch(updateMoreValues({ isLoader: false }));
//       if (res?.data?.statusCode === services?.status_code?.success) {
//         callback(res?.data?.data);
//       } else {
//         dispatch(showSuccessSnackbar(res?.msg));
//         errorCallback(res);
//       }

//     },
//     (error: any) => {
//       dispatch(showErrorSnackbar(error?.data?.msg));
//       dispatch(updateMoreValues({ isLoader: false }));
//       errorCallback(error);
//     }
//   );
// };

export const getnotificationTypeList =
  (callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    // dispatch(updateMoreValues({ isLoader: true }));
    services.getApiCall(
      endpoint.notification.getnotificationTypeList,
      "",
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        // dispatch(updateMoreValues({ isLoader: false }));
      }
    );
  };

export const getNotificationCount =
  (params: any, id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.patchApiCall(
      endpoint.notification.getNotificationCount + id,
      params,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          if (res?.code) {
            // dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            // dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };
/************************* Exam */

export const getOnlineTestList =
  (data: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.onlineTest.getOnlineTestList,
      data,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.test) {
          callback(res?.test);
        } else errorCallback(res);
      },
      (error: any) => {
        dispatch(showErrorSnackbar(error?.data?.msg));
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };

export const startExam =
  (data: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.onlineTest.startExam,
      data,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data) {
          callback(res);
        } else errorCallback(res);
      },
      (error: any) => {
        dispatch(showErrorSnackbar(error?.data?.msg));
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };

export const getExam =
  (id: any, callback: Function, ErrorCallback: Function) =>
  async (dispatch: any, getState: any) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.getApiCall(
      endpoint.onlineTest.getExam + id,
      "",
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res.status === services.status_code.success)
          callback(res?.data?.examData);
        else {
          ErrorCallback();
        }
      },
      (error: Error) => {
        dispatch(updateMoreValues({ isLoader: false }));
        ErrorCallback(error);
      }
    );
  };

export const submitExam =
  (data: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.onlineTest.submitExam,
      data,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data) {
          callback(res.data);
        } else errorCallback(res.data);
      },
      (error: any) => {
        dispatch(showErrorSnackbar(error?.data?.msg));
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };

export const getExamResult =
  (id: any, callback: Function, ErrorCallback: Function) =>
  async (dispatch: any, getState: any) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.getApiCall(
      endpoint.onlineTest.getExamResult,
      id,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res.status === services.status_code.success)
          callback(res?.data?.data);
        else {
          ErrorCallback();
        }
      },
      (error: Error) => {
        dispatch(updateMoreValues({ isLoader: false }));
        ErrorCallback(error);
      }
    );
  };

export const getcartCount =
  (id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.getApiCall(
      endpoint.cart.getcartCount,
      "",
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
      }
    );
  };

export const getUserDetails =
  (id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.getApiCall(
      endpoint.auth.getUserDetails + id,
      "",
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
      }
    );
  };

export const getLectureDetails =
  (id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.getApiCall(
      endpoint.live.getLectureDetails + id,
      "",
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
      }
    );
  };

export const getRecordedLectureDetails =
  (data: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.live.getRecordedLectureDetails,
      data,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data) {
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
          errorCallback(res);
        }
      },
      (error: any) => {
        dispatch(showErrorSnackbar(error?.data?.msg));
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };

export const getRecordedforFreeUser =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.live.getRecordedforFreeUser,
      params,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };

export const VideoRating =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.live.VideoRating,
      params,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));

        callback(res);
      },
      (error: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };

export const getAllExamList =
  (callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.getApiCall(
      endpoint.live.getAllExamList,
      "",
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
      }
    );
  };

export const getscholorshipTestList =
  (data: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.onlineTest.getscholorshipTestList,
      data,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.test) {
          callback(res?.test);
        } else errorCallback(res);
      },
      (error: any) => {
        dispatch(showErrorSnackbar(error?.data?.msg));
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };

export const getDemoSessionData =
  (data: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.demoSession.getDemoSessionData,
      data,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data) {
          callback(res?.data);
        } else errorCallback(res);
      },
      (error: any) => {
        dispatch(showErrorSnackbar(error?.data?.msg));
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };

export const addSessionFeedback =
  (data: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.demoSession.addSessionFeedback,
      data,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data) {
          callback(res?.data);
        } else errorCallback(res);
      },
      (error: any) => {
        dispatch(showErrorSnackbar(error?.data?.msg));
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };

export const getSessionFeedback =
  (data: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.demoSession.getSessionFeedback,
      data,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data) {
          callback(res?.data);
        } else errorCallback(res);
      },
      (error: any) => {
        dispatch(showErrorSnackbar(error?.data?.msg));
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };

export const getLiveLecturev2 =
  (data: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.live.getLiveLecturev2,
      data,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data) {
          callback(res);
        } else errorCallback(res);
      },
      (error: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };

export const getRecordedLecture =
  (data: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.live.getRecordedLecture,
      data,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data) {
          callback(res);
        } else errorCallback(res);
      },
      (error: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };

export const getPackageDetails =
  (id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.getApiCall(
      endpoint.package.getPackageDetails + id,
      "",
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
      }
    );
  };

export const getRecomendedList =
  (data: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.package.getRecomendedList,
      data,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data) {
          callback(res);
        } else errorCallback(res);
      },
      (error: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };

export const applyCoupon =
  (data: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.couponCode.applyCoupon,
      data,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data) {
          callback(res?.data);
        } else errorCallback(res);
      },
      (error: any) => {
        // dispatch(showErrorSnackbar(error?.data?.msg));
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };

export const getExtendedCartList =
  (data: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.cart.getExtendedCartList,
      data,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data) {
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
          errorCallback(res);
        }
      },
      (error: any) => {
        dispatch(showErrorSnackbar(error?.data?.msg));
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };

export const getAcademicDataByTopicId =
  (data: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.AcademicData.getAcademicDataByTopicId,
      data,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data) {
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
          errorCallback(res);
        }
      },
      (error: any) => {
        dispatch(showErrorSnackbar(error?.data?.msg));
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };

export const getAcademicDataLecturewise =
  (data: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.AcademicData.getAcademicDataLecturewise,
      data,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data) {
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
          errorCallback(res);
        }
      },
      (error: any) => {
        dispatch(showErrorSnackbar(error?.data?.msg));
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };


  

  export const getUsersAcademicData =
  (data: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.AcademicData.getUsersAcademicData,
      data,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data) {
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
          errorCallback(res);
        }
      },
      (error: any) => {
        dispatch(showErrorSnackbar(error?.data?.msg));
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };

  
  export const addAcademicData =
  (data: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.AcademicData.addAcademicData,
      data,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data) {
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
          errorCallback(res);
        }
      },
      (error: any) => {
        dispatch(showErrorSnackbar(error?.data?.msg));
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };
export const getAcademicAnalysis =
  (data: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.AcademicData.getAcademicAnalysis,
      data,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data) {
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
          errorCallback(res);
        }
      },
      (error: any) => {
        dispatch(showErrorSnackbar(error?.data?.msg));
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };
  export const getCompleteAcademicData =
  (data: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.AcademicData.getCompleteAcademicData,
      data,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data) {
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
          errorCallback(res);
        }
      },
      (error: any) => {
        dispatch(showErrorSnackbar(error?.data?.msg));
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };

export const addLiveFeedback =
  (data: any, callback: Function, errorCallback: Function): any =>
    async (dispatch: Function, getState: Function) => {
      dispatch(updateMoreValues({ isLoader: true }));
      services.postApiCall(
        endpoint.AcademicData.addLiveFeedback,
        data,
        (res: any) => {
          dispatch(updateMoreValues({ isLoader: false }));
          if (res?.data) {
            callback(res?.data);
          } else errorCallback(res);
        },
        (error: any) => {
          dispatch(showErrorSnackbar(error?.data?.msg));
          dispatch(updateMoreValues({ isLoader: false }));
          errorCallback(error);
        }
      );
    };

export const getLiveFeedback =
  (data: any, callback: Function, errorCallback: Function): any =>
    async (dispatch: Function, getState: Function) => {
      dispatch(updateMoreValues({ isLoader: true }));
      services.postApiCall(
        endpoint.AcademicData.getLiveFeedback,
        data,
        (res: any) => {
          dispatch(updateMoreValues({ isLoader: false }));
          if (res?.data) {
            callback(res?.data);
          } else errorCallback(res);
        },
        (error: any) => {
          dispatch(showErrorSnackbar(error?.data?.msg));
          dispatch(updateMoreValues({ isLoader: false }));
          errorCallback(error);
        }
      );
    };

    export const addSubmitDescriptiveQuestion =
  (data: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.AcademicData.addSubmitDescriptiveQuestion,
      data,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data) {
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
          errorCallback(res);
        }
      },
      (error: any) => {
        dispatch(showErrorSnackbar(error?.data?.msg));
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };
