import React, { useEffect, useState } from "react";
import localImages from "../Constant/localImages";
import { useLocation, useNavigate } from "react-router-dom";
import { logoutUser } from "../Redux/Actions/AuthAction";
import { useAppDispatch } from "../hooks";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { useSelector } from "react-redux";
import { menuData, menuDataPaid } from "../Utils/dataConstants";
import {
  getNotificationCount,
  getcartCount,
  getnotificationList,
} from "../Redux/Actions/examActions";
import "../Assets/Css/header.css";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationList from "./notificationList";

interface props {
  isHidden: boolean;
}

export default function HeaderHome({ isHidden }: props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [menu, showMenu] = useState(false);
  const [show, setShow] = useState(false);
  const [menuArray, setmenuArray] = useState<any>();
  const [cartCount, setCartCount] = useState<any>();
  const [showNotification, setShowNotification] = useState(false);
  const {
    ACCESS_TOKEN,
    firstName,
    lastName,
    email,
    mobileNo,
    hasActivePaidPlan,
    studentId,
    countryCode,
    loginFor,
    NotificationCount,
  } = useSelector((state: any) => ({
    ACCESS_TOKEN: state?.AuthReducer?.ACCESS_TOKEN,
    firstName: state?.AuthReducer?.firstName,
    lastName: state?.AuthReducer?.lastName,
    email: state?.AuthReducer?.email,
    mobileNo: state?.AuthReducer?.mobileNo,
    hasActivePaidPlan: state?.AuthReducer?.hasActivePaidPlan,
    studentId: state?.AuthReducer?.studentId,
    NotificationCount: state?.AuthReducer?.NotificationCount,

    countryCode: state?.AuthReducer?.countryCode,
    loginFor: state?.AuthReducer?.loginFor,
  }));
  const currentURLarr = useLocation().pathname.split("/");
  let link = currentURLarr[currentURLarr.length - 1];
  useEffect(() => {
    if (ACCESS_TOKEN) {
      dispatch(
        getcartCount(
          studentId,
          (data: any) => {
            setCartCount(data[0].total_count);
          },
          () => { }
        )
      );
      dispatch(
        getnotificationList(
          {},
          (data: any) => { },
          () => { }
        )
      );
    }
  }, []);
  function goToLogin(event: React.MouseEvent<HTMLButtonElement>): void {
    navigate("/Login");
  }
  function goToLink(val: string): void {
    navigate("/" + val);
  }
  function goToHome(): void {
    navigate("/");
  }
  function myCart(): void {
    navigate("/MyCart");
  }

  const gotoNotification = () => {
    // if (link !== "Notification") {
    //   setShowNotification(!showNotification);
    // }
    navigate("/User/Notification");

  };
  function clickProfile() {
    setShow(!show);
  }

  function onClickAcademics() {
    setShow(!show);
  }

  function onClickNotificationIcon(): void {
    console.log('firstfirstfirst')
    navigate("/Notification");
  }

  function handleClick(item: any) {
    showMenu(false);
    if (item.title === "Logout") {
      dispatch(
        logoutUser(
          (res: any) => { },
          () => { },
          () => { }
        )
      );
    } else {
      if (loginFor === "Parent") {
        navigate("/Parent" + item?.link);
      } else {
        navigate("/User" + item?.link);
      }
    }
  }
  function toggleMenu(): void {
    showMenu(!menu);
    const element = document.getElementById("sidebarMenu") as HTMLInputElement;
    if (menu) {
      element.classList.add("active");
    } else {
      element.classList.remove("active");
    }
  }

  useEffect(() => {
    if (hasActivePaidPlan === true) {
      setmenuArray(menuDataPaid);
    } else {
      setmenuArray(menuData);
    }
  }, [hasActivePaidPlan]);
  return (
    <header className={`${isHidden ? "hide" : "show"}`} id="headerHome">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <img
            src={localImages.logo}
            alt="IFAS Logo"
            className="headerlogo cursor"
            onClick={goToHome}
          />
        </div>
        <div className="d-none d-md-flex flex-fill justify-content-between align-items-center ms-md-5">
          <div className="d-flex">
            <div
              className={`cursor menuDiv ${link === "AdvanceAcademics" ? "active" : ""
                }`}
              onClick={() => goToLink("AdvanceAcademics")}
            >
              {" "}
              Academics
              {/* <div className="position-absolute top-50 ">
                 <div  className="boxshadow position-absolute rounded font12 p-2  pb-1 academicDiv" >
                   <div className=" submenu w-100 d-flex align-items-center border-bottom border-2 py-2 p-3  " onClick={()=>goToLink('BasicAcademics')}>
                    Basic Academics
                   </div>
                   <div className=" submenu w-100 d-flex align-items-center border-2 py-2 p-3 mb-2" onClick={()=>goToLink('AdvanceAcademics')}>
                    Advance Academics
                   </div>
                 
                 </div>
              </div> */}
            </div>
            <div
              className={`cursor menuDiv ${link === "TestPrep" ? "active" : ""
                }`}
              onClick={() => goToLink("TestPrep")}
            >
              {" "}
              Test Prep{" "}
            </div>
            <div
              className={`cursor menuDiv ${link === "CollegeConsulting" ? "active" : ""
                }`}
              onClick={() => goToLink("CollegeConsulting")}
            >
              College Consulting
            </div>
            {/* <div className='cursor menuDiv' onClick={()=>goToLink('CollegePredictor')}>College Predictor</div> */}
            <div
              className={`cursor menuDiv ${link === "AlumniNetwork" ? "active" : ""
                }`}
              onClick={() => goToLink("AlumniNetwork")}
            >
              Alumni Network
            </div>
            <div
              className={`cursor menuDiv ${link === "TutorNetwork" ? "active" : ""
                }`}
              onClick={() => goToLink("TutorNetwork")}
            >
              Tutor Network
            </div>
          </div>
          {ACCESS_TOKEN ? (
            <div className="d-none d-md-flex">
              <div className="position-relative d-flex align-items-center me-3">
                <ShoppingCartOutlinedIcon
                  className="font28 cursor"
                  onClick={myCart}
                />
                <span className="position-absolute top-1 start-100 translate-middle badge rounded-pill bg-primary">
                  {cartCount}
                </span>
              </div>
              <div className="d-flex  align-items-center me-1">
                <div className="position-relative " onClick={gotoNotification}>
                  <NotificationsOutlinedIcon className="font28 cursor" />
                  <span className="position-absolute top-1 start-100 translate-middle badge rounded-pill bg-primary mt-1">
                    {NotificationCount}
                  </span>
                  {showNotification ? (
                    <div className="position-absolute top-50  ">
                      <div
                        className="position-absolute "
                        onClick={() => setShowNotification(false)}
                      ></div>
                      {/* <NotificationList /> */}
                    </div>
                  ) : (
                    ""
                  )}



                </div>
              </div>
              <div className="d-flex align-items-center">
                <span className="profilebtn" onClick={clickProfile}>
                  <img
                    src={localImages.profile}
                    alt="Profile"
                    className="rounded-circle top-50 start-50 "
                    style={{ width: "28px" }}
                  />

                  <div className="position-absolute top-50  notiDiv">
                    <div
                      className="position-absolute transperentbg"
                      onClick={() => setShow(false)}
                    ></div>
                    <div
                      className="boxshadow position-absolute p-3 rounded font12"
                      style={{
                        background: "#fff",
                        top: "25px",
                        right: "-40px",
                        width: "200px",
                      }}
                    >
                      <div className="d-flex align-items-center border-bottom border-2 pb-2 mb-2">
                        <div className="profilePhoto shadow rounded-circle">
                          <img
                            src={localImages.profile}
                            alt="Profile"
                            className="rounded-circle"
                            style={{ width: "20px" }}
                          />
                        </div>
                        <div className="ms-3">
                          <div className="colorblue">
                            {firstName + " " + lastName}
                          </div>
                          <div className="colorgray707070">
                            {countryCode}-{mobileNo}
                          </div>
                        </div>
                      </div>
                      <div className="px-2">
                        {menuArray?.map((c: any) => (
                          <div
                            className="pb-2"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleClick(c);
                            }}
                          >
                            <img
                              src={c.imgpath}
                              alt={c.title}
                              style={{ width: "17px" }}
                            />{" "}
                            <span className="ms-3 colorgray707070">
                              {c.title}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          ) : (
            <button className="signBtn px-4" onClick={goToLogin}>
              Sign In
            </button>
          )}
        </div>

        <div
          className="d-xs-flex d-sm-flex d-md-none me-2 cursor"
          onClick={toggleMenu}
        >
          <MenuIcon />
        </div>
      </div>
    </header>
  );
}
