import React, { useEffect, useState } from 'react';
import localImages from '../Constant/localImagesNew';
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css";
import Faq from '../Component/faq';
import Footer from '../Component/footer';
import { useNavigate } from 'react-router-dom';
import HeaderHome from '../Component/headerHome';
import Sidebar from '../Module/sidebar';
import { useSelector } from 'react-redux';
import { SidebarDataFreeUser, SidebarDataPaidUser } from '../Module/sidebarData';
import BookSessionPopup from '../Component/bookSessionPopup';
import BookSession from '../Module/bookSession';
import StudentSayCarousel from '../Component/studentSayCarousel';
import '../Assets/Css/marketinglandingpage.css'
import dataConstant from '../Constant/dataConstant';
import StatCard from '../Module/statCard';
import { addJoinLurnigo, addLsatLandingData, getCountry, getCourseExam, getCourseTypelist, getGeneralExamList, getSchoolList, register } from '../Redux/Actions/AuthAction';
import { useAppDispatch } from '../hooks';
import { validateFullName, validateIsNumberOnly } from '../Utils/validators';
import { showErrorSnackbar } from '../Redux/Actions/snackbarAction';
import { validateFullNameErrMsg, validateIsNumberOnlyErrMsg, validateLastNameErrMsg } from '../Utils/errormsg';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import InputField from '../Atom/InputField';
import PhoneInputField from '../Atom/phoneInputField';
import Dropdown from '../Component/dropdown';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import HeaderLandingPage from '../Component/headerLandingPage';

export default function Lsatlandingpage() {
    const navigate = useNavigate();
    const homebannerdiv = React.useRef<HTMLDivElement>(null);
    const [isHidden, setisHidden] = useState<boolean>(true);
    const [openBookSeat, setopenBookSeat] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const {userCountryCallingCode} = useSelector((state:any) => ({
      userCountryCallingCode:state?.AuthReducer?.userCountryCallingCode
    }));
  const [firstName, setfirstname] = useState<string>("");
  const [lastName, setlastName] = useState<string>("");
  
  const [email,setemail]=useState("");
  const [mob, setmob] = useState<string>("");
  const [countryData, setCountryData] = useState([]);
  const [coutryCode, setcoutryCode] = useState<string>("61");
  const [coutryObj, setcoutryObj] = useState<{_id: number;
    name: string;
    isoCode:string;
    phonecode:number}>({
    _id: 13,
    name: "AUSTRALIA",
    isoCode: "AUS",
    phonecode: 61
});
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [school, setSchool] = useState<string>("");  
  const [schoolList, setSchoolList] = useState([]);
  const [course, setCourse] = useState<any>("");
  const [subjectList, setSubjectList] = useState([]);
  const [subject, setSubject] = useState("");
  // useEffect(() => {
  //   dispatch(getCountry(
  //     (data: any) => {
  //         setCountryData(data)
  //     },
  //     () => { })  );
  // }, []);

  useEffect(() => {
    dispatch(getCountry(
        (data: any) => {
          setCountryData(data)
            // const filterCountry=data?.filter((item:any)=>item?.phonecode==userCountryCallingCode?.substring(1)) 
            const postData = {
                countryId: 226,
              };

              dispatch(getCourseTypelist(
                postData,
                (res: any) => {    
                  console.log('res', res);                   
                  const filterCourse=res?.data?.filter((item:any)=>item?.name==="TEST PREPARATION");
                  console.log('filterCourse', filterCourse);
                  setCourse(filterCourse[0]?._id)
                  dispatch(
                    getGeneralExamList(
                      {
                        courseId: filterCourse[0]?._id,
                      },
                      (res: any) => {
                        console.log('getGeneralExamList', res);
                        // setExamList(res?.data);
                      },
                      () => {}
                    )
                  );
                   
                }, 
                () => { }) )          
        },
        () => { })
    );
    
}, [userCountryCallingCode]);
  const closePopup=()=> {
    setIsSubmit(false);
    clearData()
  }
 
  const handleFirstInputText = (e: any) => {
    if (validateFullName(e)) setfirstname(e);
    else {
      dispatch(showErrorSnackbar(validateFullNameErrMsg));
    }
  };
  
  const handleLastInputText = (e: any) => {
    if (validateFullName(e)) setlastName(e);
    else {
      dispatch(showErrorSnackbar(validateLastNameErrMsg));
    }
  };
  const setPhoneNumber = (e: any) => {
    if (!validateIsNumberOnly(e)) {
      setmob(e);
    } else {
      dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
    }
  }

  function submitForm(e: { preventDefault: () => void; }) {
    e.preventDefault();    
    const PostData: any = {
      firstName,
      lastName,
      email: email,
      mobile: mob,
      countryCode:"+"+coutryObj?.phonecode,
      // schoolId:school,
      sourceId:3,
      coursetypeId:course,
      examtypeId:67
    };
    dispatch(addLsatLandingData(
      PostData,
      (res: any) => {
        setIsSubmit(true);
        const payload: any = {
          firstName: firstName,
          lastName: lastName,
          mobile: mob,
          email: email,
          coursetypeId: course,
          countryCode:"+"+coutryObj?.phonecode,
        };
        dispatch(
          register(
            payload,
            (data: any) => {
              
            },
            () => {}
          )
        );
        // clearData()

      }, () => { }))
    // return (true)
  }

  function clearData() {
    setfirstname("");
    setlastName("")
    setemail("");
    setmob("");
 setSchool("")
  }

  function validation() {
    return ( (coutryCode!=="61" && mob?.length < 10) || (coutryCode==="61" && mob?.length < 9) || firstName === "" || lastName==="" || email === "");
  }
const onChangeCoutryCode=(e:any)=>{
  setcoutryCode(e.target.value);
  const jsonData=JSON.parse(e.target.value);
  setcoutryObj(jsonData);
  // setSchoolList([])
  // dispatch(
  //   getSchoolList(
  //     {countryId:jsonData?._id},
  //     (data: any) => {
  //       setSchoolList(data?.data);        
  //     },
  //     () => { }
  //   )
  // );
}
const changeSchool = (val: any) => {
  setSchool(val)
}
    const closesBooseat = () => {
        setopenBookSeat(false)
    }
    const {ACCESS_TOKEN,hasActivePaidPlan} = useSelector((state:any) => ({
        ACCESS_TOKEN: state?.AuthReducer?.ACCESS_TOKEN,
        hasActivePaidPlan: state?.AuthReducer?.hasActivePaidPlan,
      }));
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    
    const contactUS = (val:string) => {
        const element = document.getElementById(val);
        if (element) { 
          element.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        }
      };
      const classFeat=[
        "Personalized service","Featuring One-on-One Live Interactive Classes","Regular Assessments","Dedicated Doubt Clarification support","holistic approach provides personalized guidance for academic excellence and success"
      ]  
    return (
        <div>
            <HeaderLandingPage isHidden={isHidden} />
            <div className="d-block d-md-none">
            <Sidebar data={hasActivePaidPlan===true?SidebarDataPaidUser:SidebarDataFreeUser}/> 
            </div>
            {/*************Banner************* */}
            <div className='position-relative homeBannerContainer bgLightGrey' id='Home' ref={homebannerdiv}>
                <div className='row mx-0 '>
                    <div className="col-md-6 homeBannerLeftSide">
                    <div className="homeBannerLeftSide">
                        <h1 className='homeBannerHeading'>Unlock Your Potential with Lurnigo Scholarship!</h1>
                        
                        <div className='bannersubtext mt-3'>Embark on a journey of discovery and challenge yourself with the Lurnigo Scholarship Test. Designed for ambitious students, this comprehensive test aims to evaluate your skills across a range of subjects and reward outstanding talent with scholarships. Prepare to showcase your abilities and take a step closer to your educational goals.</div>
                       
                    </div>
                    </div>
                    <div className="col-md-6 position-relative pe-0 landingPageRigthDiv">
                        <div className='bookSession m-auto'>
                            <div className=" d-flex align-items-center justify-content-between HeadingBookSession">
                                {isSubmit ?
                                    <div className='text-success fw-bold font20'>Submitted Successfully</div> :
                                    <div className='font20 fw-bold'>Take Your First Step Toward Success</div>}
                                 {isSubmit ?<ClearOutlinedIcon className="colorblue cursor font28" onClick={closePopup} />:null}
                            </div>
                            <div className='bookSessionMiddleContainer'>
                                {isSubmit ?
                                    <div className='font16 p-3'>
                                        <img src={localImages?.thankYou} alt='thank You' />
                                        <div className='my-3'>
                                            Dear <span className='fw-bold'>{firstName}{" "} {lastName}</span>
                                        </div>
                                        <div>
                                            <div>Thank you for your interest in LurniGo!</div>
                                            <div> An Academic Consultant from our team will contact you within 24-48 hours.</div>
                                            <div>Meanwhile, feel free to explore the resources on our website to know more.</div>
                                            <div className='mt-3'>For any immediate queries, reach out at support@lurnigo.com.</div>
                                            <div className='mt-2'>Best,</div>
                                            <div className='fw-bold'>Team LurniGo</div>
                                        </div>
                                    </div> :
                                    <form className='w-100'>
                                        <div className='row mx-0'>
                                            <div className='my-2 col-md-6'>
                                                <label className='mb-2'>First Name</label>
                                                <div className='d-flex'>
                                                    <InputField
                                                        placeholder="Enter First Name"
                                                        value={firstName}
                                                        setValue={handleFirstInputText}
                                                    />
                                                    <span className="mendatory ms-2 fs-5">*</span>
                                                </div>
                                            </div>
                                            <div className='my-2 col-md-6'>
                                                <label className='mb-2'>Last Name</label>
                                                <div className='d-flex'>
                                                    <InputField
                                                        placeholder="Enter Last Name"
                                                        value={lastName}
                                                        setValue={handleLastInputText}
                                                    />
                                                    <span className="mendatory ms-2 fs-5">*</span>
                                                </div>
                                            </div>
                                            <div className='my-2'>
                                                <label className='mb-2'>Email</label>
                                                <div className='d-flex'>
                                                    <input
                                                        className='form-control p-2'
                                                        type='email'
                                                        value={email}
                                                        placeholder="Enter Your Email Address"
                                                        onChange={(e) => setemail(e.target.value)} />
                                                    <span className="mendatory ms-2 fs-5">*</span>
                                                </div>
                                            </div>
                                            <div className='my-2'>
                                                <label className='mb-2'>Phone Number</label>
                                                <div className='d-flex position-relative'>
                                                    <select
                                                        className="form-select cursor coutryCode  rounded-0 rounded-start"
                                                        aria-label="Default select example"
                                                        defaultValue={1}
                                                        value={coutryCode}
                                                        onChange={onChangeCoutryCode}
                                                    >
                                                        {countryData?.map((item: any, index) => {
                                                            return <option id={item?._id} value={JSON.stringify(item)}>{item?.isoCode} +{item?.phonecode}</option>;
                                                        })}
                                                    </select>
                                                    <PhoneInputField
                                                        placeholder="Enter Your Phone Number"
                                                        value={mob}
                                                        maxLength={"10"}
                                                        setValue={setPhoneNumber} />
                                                    <span className="mendatory ms-2 fs-5">*</span>
                                                </div>
                                            </div>
                                            {/* <div className='my-2'>
                                                <label className='mb-2'>School</label>
                                                <div className='d-flex'>
                                                    <Dropdown
                                                        placeholder='Select Your School'
                                                        setInputText={(value: any) => changeSchool(value)}
                                                        value={school}
                                                        options={schoolList} />
                                                    <span className="mendatory ms-2 fs-5">*</span>
                                                </div>
                                            </div> */}
                                        </div>
                                    </form>
                                }


                            </div>
                            <div className='p-3 px-4 border-top'>
                                {isSubmit ?
                                    <button onClick={closePopup} className={`signBtn w-100 rounded font16`}>Ok</button>
                                    : <button onClick={submitForm} className={`signBtn w-100 rounded font16 ${validation() ? 'bg-secondary' : ''}`} disabled={validation()}>Submit</button>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id='WhyUs'></div>
            <StatCard buttonName="Join Lurnigo Now"/>
            <div className='commonPad bgLightGrey' id='Testimonials'>
                <div className='width800 mx-auto my-5'>
                    <h1 className='headingFont text-center'>Don't just take our word for it</h1>
                    <div className='bannersubtext mb-3 text-center'>Hear what our students have to say</div>
                </div>
                <div>
                    <StudentSayCarousel Data={dataConstant?.studentSayData} id='carouselStudent' />
                </div>
            </div>
            <div id='FAQs'></div>
            <div className='commonPad '>
                <div className='row mx-0'>
                    <div className='col-md-3'>
                        <h1 className='headingFont mb-4'>FAQs</h1>
                        <div className='mb-4'>
                            <button className='contactUSbtn px-5'onClick={()=>contactUS('Footer')}>Contact Us</button>
                        </div>
                    </div>
                    <div className='col-md-9'>
                        <Faq faqData={dataConstant?.faqData} id={"HomeFAQ"} />
                    </div>
                </div>
            </div>

           
                    {/***************Footer*************** */}
                    <Footer id='Footer'/>
                    <BookSessionPopup open={openBookSeat} handleClose={closesBooseat} />
            </div>
            )
}
