import "../App.css";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../Assets/Css/dashboard.css";
import moment from "moment";
import {
  getUserPackageList,
  getupcomingLect,
} from "../Redux/Actions/AuthAction";
import { useAppDispatch } from "../hooks";
import live from "../Assets/Images/json/livesession.json";
import Lottie from "react-lottie";
import BbMeetingPopup from "../Module/bbMeetingPopup";
import NoRecord from "../Component/noRecord";
import localImages from "../Constant/localImagesNew";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  getLiveLecturev2,
  getRecordedLecture,
} from "../Redux/Actions/examActions";
import CountDownTimer from "../Module/countDownTimer";
import SlotPopUp from "../Module/slotPopUp";
import DropdownNew from "../Component/dropdownNew";
import ExtentLecture from "../Module/extentLecture";
import ReschedulePopup from "../Module/reschedulePopup";
import funFactOpen from "../Assets/Images/UserLecture/funFactOpen.png";
function Dashboard() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [openVideo, setopenVideo] = useState(false);
  const [selectedLectureData, setSelectedLectureData] = useState<any>();
  const [liveData, setLiveData] = useState<any>({});
  const [upcomingData, setupcomingData] = useState<any>();
  const [packageList, setPackageList] = useState([]);
  const [packageVal, setPackageVal] = useState<any>("");
  const [deafultexamid, setdeafultexamid] = useState("");
  const [recordedVideo, setRecordedVideo] = useState<any>("");
  const [istimeExpire, setIstimeExpire] = useState<boolean>(false);
  const [schedulePopUp, setSchedulePopup] = useState(false);
  const [openExtendPopUp, setOpenExtendPopUp] = useState(false);
  const [reSchedulePopUp, setreSchedulePopup] = useState(false);

  const [dateList, setdateList] = useState([]);
  const [upcomingLectList, setUpcomingLectList] = useState<any>([]);
  const { studentId } = useSelector((state: any) => ({
    studentId: state?.AuthReducer?.studentId,
  }));
  useEffect(() => {
    dispatch(
      getUserPackageList(
        studentId,
        (data: any) => {
          setPackageList(data);
          setPackageVal(data[0]);
          setdeafultexamid(data[0]?._id);
          dispatch(
            getupcomingLect(
              { packageId: data[0]?._id },
              (data: any) => {
                const filterData = data?.data.filter((item: any) => {
                  return item.status !== 9;
                });
                const groupedData: any = {};
                let labelArr: any = [];
                filterData?.forEach((entry: any) => {
                  const dateLabel = `${new Date(entry.scheduleFrom)}`;
                  if (!groupedData[dateLabel]) {
                    labelArr?.push(dateLabel);
                    groupedData[dateLabel] = [];
                  }
                  groupedData[dateLabel].push(entry);
                });
                setdateList(labelArr);
                setUpcomingLectList(groupedData);
              },
              () => {}
            )
          );
          if (data?.length === 1) {
            const requestData = {
              packageId: data[0]?._id,
            };
            dispatch(
              getLiveLecturev2(
                requestData,
                (data: any) => {
                  if (data?.data) {
                    const filterliveData = data?.data?.filter(
                      (item: any) =>
                        item.isLiveToday === true && item.statusId === 2
                    );
                    if (filterliveData?.length > 0) {
                      setLiveData(filterliveData[0]);
                      const filterUpcomingData = data?.data?.filter(
                        (item: any) => item.statusId === 1
                      );
                      if (filterUpcomingData?.length > 0) {
                        setupcomingData(filterUpcomingData[0]);
                      }
                    } else {
                      const filterUpcomingData = data?.data?.filter(
                        (item: any) => item.statusId === 1
                      );
                      if (filterUpcomingData?.length > 0) {
                        setLiveData(filterUpcomingData[0]);
                        setupcomingData(filterUpcomingData[1]);
                      }
                    }
                  }
                },
                () => {}
              )
            );

            dispatch(
              getRecordedLecture(
                requestData,
                (data: any) => {
                  setRecordedVideo(data?.data[0]);
                },
                () => {}
              )
            );
          }
        },
        () => {}
      )
    );
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: live,
    renderer: "svg",
  };
  const handleBbbVideoClose = () => {
    setopenVideo(false);
  };

  const joinLive = () => {
    window.open(
      liveData?.attendeeUrl,
      "_blank",
      "width=" +
        window.screen?.availWidth +
        ",height=" +
        window.screen?.availHeight
    );
  };
  const getPackageVal = (val: any) => {
    setLiveData({});
    const filterPackageData = packageList?.filter(
      (item: any) => item._id === parseInt(val)
    );
    setPackageVal(filterPackageData[0]);
    const requestData = {
      packageId: val,
    };
    dispatch(
      getLiveLecturev2(
        requestData,
        (data: any) => {
          if (data?.data) {
            const filterliveData = data?.data?.filter(
              (item: any) => item.isLiveToday === true
            );
            if (filterliveData?.length > 0) {
              setLiveData(filterliveData[0]);
              const filterUpcomingData = data?.data?.filter(
                (item: any) => item.statusId === 1
              );
              if (filterUpcomingData?.length > 0) {
                setupcomingData(filterUpcomingData[0]);
              }
            } else {
              const filterUpcomingData = data?.data?.filter(
                (item: any) => item.statusId === 1
              );
              if (filterUpcomingData?.length > 0) {
                setLiveData(filterUpcomingData[0]);
                setupcomingData(filterUpcomingData[1]);
              }
            }
          }
        },
        () => {}
      )
    );

    dispatch(
      getRecordedLecture(
        requestData,
        (data: any) => {
          setRecordedVideo(data?.data[0]);
        },
        () => {}
      )
    );

    dispatch(
      getupcomingLect(
        requestData,
        (data: any) => {
          const filterData = data?.data.filter((item: any) => {
            return item.status !== 9;
          });
          const groupedData: any = {};
          let labelArr: any = [];
          filterData?.forEach((entry: any) => {
            const dateLabel = `${new Date(entry.scheduleFrom)}`;
            if (!groupedData[dateLabel]) {
              labelArr?.push(dateLabel);
              groupedData[dateLabel] = [];
            }
            groupedData[dateLabel].push(entry);
          });
          setdateList(labelArr);
          setUpcomingLectList(groupedData);
        },
        () => {}
      )
    );
  };
  function gotoCourseDetail(data: any) {
    navigate("/VideoPlayer", {
      state: { lectureId: "", recordedData: data },
    });
  }
  const target =
    liveData?.scheduleFrom?.slice(0, 11) + "T" + liveData?.sessionTime;
  // console.log('target', target);
  //  const target="2024-05-20T:25:00"
    // console.log('target', target);

  const [showButton, setShowButton] = useState(true);
//  console.log('showButton', showButton);
  useEffect(() => {
    // Calculate the target time (current time minus 2 hours)
    if(target){
      const targetDate = new Date(target);
      const cutoffDate = new Date(targetDate?.getTime() - 3 * 60 * 60 * 1000); // 2 hours before
      const checkTime = () => {
        const currentTime = new Date();
        if (currentTime >= cutoffDate) {
          setShowButton(false);
        } else {
          setShowButton(true);
        }
      };
  
      checkTime(); // Initial check  
      const interval = setInterval(checkTime, 1000 * 60); // Check every minute  
      return () => clearInterval(interval); // Cleanup interval on component unmount  
  }
  }, [target]);

  const handleCloseSlotPopUp = () => {
    setSchedulePopup(false);
    getData()
  };
  const handleCloseReschedulePopUp = () => {
    setreSchedulePopup(false);
    getData()
  };

  const getData=()=>{
    const requestData = {
      packageId: packageVal?._id,
    };
    dispatch(
      getLiveLecturev2(
        requestData,
        (data: any) => {
          if (data?.data) {
            const filterliveData = data?.data?.filter(
              (item: any) => item.isLiveToday === true
            );
            if (filterliveData?.length > 0) {
              setLiveData(filterliveData[0]);
              const filterUpcomingData = data?.data?.filter(
                (item: any) => item.statusId === 1
              );
              if (filterUpcomingData?.length > 0) {
                setupcomingData(filterUpcomingData[0]);
              }
            } else {
              const filterUpcomingData = data?.data?.filter(
                (item: any) => item.statusId === 1
              );
              if (filterUpcomingData?.length > 0) {
                setLiveData(filterUpcomingData[0]);
                setupcomingData(filterUpcomingData[1]);
              }
            }
          }
        },
        () => {}
      )
    );

    dispatch(
      getRecordedLecture(
        requestData,
        (data: any) => {
          setRecordedVideo(data?.data[0]);
        },
        () => {}
      )
    );
    dispatch(
      getupcomingLect(
        requestData,
        (data: any) => {
          const filterData = data?.data.filter((item: any) => {
            return item.status !== 9;
          });
          const groupedData: any = {};
          let labelArr: any = [];
          filterData?.forEach((entry: any) => {
            const dateLabel = `${new Date(entry.scheduleFrom)}`;
            if (!groupedData[dateLabel]) {
              labelArr?.push(dateLabel);
              groupedData[dateLabel] = [];
            }
            groupedData[dateLabel].push(entry);
          });
          setdateList(labelArr);
          setUpcomingLectList(groupedData);
        },
        () => {}
      )
    );
  }

  function goToLink(val: string): void {
    navigate("/" + val);
  }
  const reschedule = () => {
    setreSchedulePopup(true);
  };
  let count = 0;
console.log('liveData', liveData);
  return (
    <div className="rightPanel">
      <div className="d-flex justify-content-between flex-wrap">
          {packageVal?.extendPackageOption === false ? (
          <div className="dashboardQoutes d-inline-block rounded-pill fw-bold mb-2 mb-md-0">
            Great day to learn something new{" "}
            <img
              src={localImages?.firelogo}
              alt="Demo Session"
              className="ms-3"
              style={{ width: "20px" }}
            />
          </div>
        ) : ( 
          <div className="dashboardQoutes d-inline-block rounded-pill fw-bold mb-2 mb-md-0">
            Remaining Lecture : <span className="me-5">{packageVal?.lectLeft}</span>
            <button
                          className="btn btnbg rounded-pill py-1 font12 pulse"
                          onClick={() => setOpenExtendPopUp(true)}
                        >
                          Extend
                        </button>
          </div>
        )}  
        {packageList?.length > 1 ? (
          <div className="dropdowncolor">
            <DropdownNew
              // placeholder="Select Package"
              options={packageList}
              value={packageVal?._id}
              defaultValue={deafultexamid}
              setInputText={(value: any) => getPackageVal(value)}
            />
          </div>
        ) : null}
      </div>
      {packageVal?.maincategoryName === "ONE ON ONE" &&
      Object.entries(liveData).length === 0 ? (
        <div className="mt-3 demoProcessBox px-5">
          <h4 className="fw-bold my-2">Schedule Your Regular Class</h4>
          <button
            className="btn btnbg rounded-pill mt-3"
            onClick={() => setSchedulePopup(true)}
          >
            Pick a slot
          </button>
        </div>
      ) : null}
      <div className="row mt-3">
        <div className="col-md-8">
          {Object.entries(liveData).length === 0 ? null : (
            <div className="ps-md-0 my-3">
              <div className="demoSessionCard row align-items-center">
                <div className="col-md-3">
                  <img
                    src={funFactOpen}
                    alt="Demo Session"
                    className="w-100"
                  />
                   
                </div>
                <div className="col-md-9">
                  <div className="d-flex justify-content-between">
                    <div className="">Live Details</div>
                   
                  </div>
                  <h5 className="fw-bold my-2">{liveData?.title}</h5>
                  <div className="d-flex mt-3 mb-2">
                    <CalendarMonthIcon />
                    <span className="ms-2">
                      {moment(liveData?.scheduleFrom).format("LL")}
                    </span>
                  </div>
                  <div className="d-flex ">
                    <AccessTimeIcon />
                    <span className="ms-2">{liveData?.sessionTime}</span>
                  </div>

                  {istimeExpire ? (
                    <div className="mt-4">
                      {liveData?.statusId === 2 ? (
                        <button
                          className="btn btnbg px-4 font14"
                          disabled={
                            liveData?.attendeeUrl !== "\n<!D" ? false : true
                          }
                          onClick={joinLive}
                        >
                          JOIN LIVE
                        </button>
                      ) : (
                        <div>
                          <button className="btn btnbg px-4 font14" disabled>
                            Upcoming
                          </button>
                          {showButton?
                              <div>
                                {liveData?.rescheduleStatus?
                                <button
                                  className="btn btnbg px-4 font14 ms-2"
                                  disabled
                                  // onClick={reschedule}
                                >
                                  Awaiting Confirmation
                                </button>:
                                <button
                                  className="btn btnbg px-4 font14 ms-2"
                                  onClick={reschedule}
                                >
                                  RESCHEDULE
                                </button>}
                              </div>
                          :null}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="mt-2">
                      {showButton?
                              <div>
                                {liveData?.rescheduleStatus?
                                <button
                                  className="btn btnbg px-4 font14 ms-2"
                                  // onClick={reschedule}
                                  disabled
                                >
                                  Awaiting Confirmation
                                </button>:
                                <button
                                  className="btn btnbg px-4 font14 ms-2"
                                  onClick={reschedule}
                                >
                                  RESCHEDULE
                                </button>}
                              </div>
                          :null}
                      <p className="mb-0 fw-bold font16">Your class start in</p>
                      <CountDownTimer
                        targetDate={target}
                        setIstimeExpire={setIstimeExpire}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {Object.entries(liveData).length === 0 ? null : (
            <div className="">
              <div className="dashboardcardDiv pe-0">
                <div className="d-flex row mx-0">
                  <div className="col-md-4 ps-0 mb-2 mb-md-0">
                    <div className="bgwhite dashboardcard">
                      <h6 className="text-start fw-bold mb-2 cardLebel">
                        All Lectures
                      </h6>
                      <img
                        src={localImages?.Lecture}
                        alt="dashboardcardImg"
                        className="dashboardcardImg"
                      />
                      <div className="text-center fw-bold my-2 cardtext">
                      Explore All Lectures
                      </div>
                      <button
                        className="btn btnbg px-4 font14 w-100 mt-3"
                        disabled={
                          recordedVideo === undefined ||
                          recordedVideo?.linkStatus === 1
                            ? true
                            : false
                        }
                        onClick={() => gotoCourseDetail(recordedVideo)}
                      >
                        View
                      </button>
                    </div>
                  </div>
                  <div className="col-md-4 ps-0 mb-2 mb-md-0">
                    <div className="bgwhite dashboardcard">
                      <h6 className="text-start fw-bold mb-2 cardLebel">Home Work</h6>
                      <img
                        src={localImages?.homeWork}
                        alt="dashboardcardImg"
                        className="dashboardcardImg"
                      />
                      <div className="text-center fw-bold my-2 cardtext">
                      Home Work & Analysis 
                      </div>
                      <button
                        className="btn btnbg px-4 font14 w-100 mt-3"
                        disabled
                      >
                        View
                      </button>
                    </div>
                  </div>
                  <div className="col-md-4 ps-0">
                    <div className="bgwhite dashboardcard">
                      <h6 className="text-start fw-bold mb-2 cardLebel">
                      Module Test 
                      </h6>
                      <img
                        src={localImages?.moduletest}
                        alt="dashboardcardImg"
                        className="dashboardcardImg"
                      />
                      <div className="text-center fw-bold my-2 cardtext">
                      Module Test & Analysis 
                      </div>
                      <button
                        className="btn btnbg px-4 font14 w-100 mt-3"
                        disabled
                      >
                        View
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {Object.entries(liveData).length === 0 ? null : (
          <div className="col-md-4">
            <h5 className="fw-bold my-2">Upcomming Live Lecture</h5>

            <div className="my-3 upcomingBox">
              {dateList?.length > 0 ? (
                dateList?.map((data, index) => {
                  if (index < 5) {
                    return (
                      <div>
                        {upcomingLectList[data]?.map((item: any, i: any) => {
                          // setCount(count+1)

                          count = count + 1;
                          return (
                            <div className="row mx-0 d-flex mb-2 align-items-center">
                              {item?.status === 2 ? (
                                <div
                                  className="row rounded p-2 align-items-center cursor bgwhite w-100"
                                  // onClick={() =>
                                  //   openBbmeeitng(item?.attendeeUrl)
                                  // }
                                >
                                  <div className="col-9">
                                    <p className="fw-bold mb-2 upcomingTitle">
                                      {item?.title}
                                    </p>
                                    <p className="fw-bold mb-1">
                                      {item?.teacherName}
                                    </p>
                                    <p className="mb-0 colorblue font12">
                                      Join Session Now
                                    </p>
                                  </div>
                                  <div className="col-3">
                                    <div
                                      className="alert alert-danger  p-1 text-center"
                                      role="alert"
                                    >
                                      Live
                                    </div>
                                    <Lottie
                                      options={defaultOptions}
                                      height={60}
                                      width={60}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="row rounded p-2 d-flex align-items-center cursor bgwhite w-100 scheduleCard"
                                  // onClick={() =>
                                  //   openPopup(item)
                                  // }
                                >
                                  <div
                                    className="border rounded-circle p-1"
                                    style={{ width: "38px", height: "38px" }}
                                  >
                                    <img
                                      src={localImages?.upcominIcon}
                                      alt="Icon"
                                      style={{ width: "27px" }}
                                      className=""
                                    />
                                  </div>
                                  <div className="col-9">
                                    <p className="fw-bold mb-1 bookSessionOrange upcomingTitle">
                                      {item?.title}
                                    </p>
                                    {/* <p className="fw-bold mb-1">{item?.teacherName}</p> */}
                                    <p className="mb-0 colorblue font12">
                                      {" "}
                                      {`${moment(item?.scheduleFrom).format(
                                        "LL"
                                      )} ${moment
                                        .utc(item?.sessionTime, "hh:mm:ss")
                                        .format("LT")}`}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    );
                  }
                })
              ) : (
                <NoRecord />
              )}
            </div>
            <div
              className="cursor viewAllBtn p-2 text-center"
              onClick={() => goToLink("User/ClassSchedule")}
            >
              View All
            </div>
          </div>
        )}
      </div>

      <BbMeetingPopup
        open={openVideo}
        handleClose={handleBbbVideoClose}
      />

      <Modal
        aria-labelledby="transition-modal-title "
        aria-describedby="transition-modal-description"
        className="model bbmodel"
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="modeldiv p-4 py-5">
            <div className="m-2  bgwhite">
              <div className="col mb-2 d-flex justify-content-end ">
                <ClearOutlinedIcon
                  className="colorblue cursor"
                  onClick={() => setOpen(false)}
                />
              </div>
              <div className="col justify-content-center  py-5 ">
                <Lottie
                  options={defaultOptions}
                  height={100}
                  width={100}
                ></Lottie>
                {moment(selectedLectureData?.scheduleFrom).format("LL") ===
                moment(new Date()).format("LL") ? (
                  <p className="text-center font16 lecturePopupText">
                    <b>Please wait for a while , lecture will start soon</b>
                  </p>
                ) : (
                  <p className="text-center font16 lecturePopupText">
                    <b>
                      This class is scheduled for{" "}
                      {moment(selectedLectureData?.scheduleFrom).format("LL")}{" "}
                      {moment
                        .utc(selectedLectureData?.sessionTime, "hh:mm:ss")
                        .format("LT")}{" "}
                      , Will update you prior to the scheduled time
                    </b>
                  </p>
                )}
              </div>
            </div>
          </div>
        </Fade>
      </Modal>

      {schedulePopUp ? (
        <SlotPopUp
          openPopup={schedulePopUp}
          handleClose={handleCloseSlotPopUp}
          packageData={packageVal}
        />
      ) : (
        ""
      )}

      <ExtentLecture
        openPopup={openExtendPopUp}
        handleClose={() => setOpenExtendPopUp(false)}
        packageData={packageVal}
        isNative={liveData?.isNative}
      />

      {reSchedulePopUp ? (
        <ReschedulePopup
          openPopup={reSchedulePopUp}
          handleClose={handleCloseReschedulePopUp}
          packageData={liveData}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default Dashboard;
